import React, { Component } from "react";
import "../../../assets/scss/subjectSetups.scss";
import "../../../assets/scss/form.scss";
import { connect } from "react-redux";
import { getSubjects, getSubjectAreas } from "../../../ducks/PrimaryARRDucks";
import ModalWindow from "../../UI/ModalWindow";
import SettingSubjects from "./SettingSubjects";
import { Spinner } from "reactstrap";
import TeacherSubjects from "./TeacherSubjects";
import UpdateSubjects from "./UpdateSubjects";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL, draggable } from "../../../utils/Constants";
import {
  subject,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import swal from "sweetalert";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import { MESSAGEHEADER, DISPLAYMESSAGE } from "../../../utils/DisplayMessage";
import NoteDisplay from "../../../utils/noteDisplay";
import { getGroups } from "../../../ducks/AcademicAdministrationDucks";
import CreatableSelect from "react-select/creatable";

class Subjects extends Component {
  state = {
    allYearLevels: [],
    assessmentModuleCode: "",
    assignedTeachers: [],
    assignModal: false,
    checkboxPracticalVal: false,
    checkboxTheoryVal: true,
    classTeachers: "",
    description: "",
    educationLevelDivisions: "",
    educationLevels: "",
    isAvailable: false,
    isSettingAvailable: false,
    name: null,
    nameError: false,
    permissionAssignTeacher: false,
    permissionDeleteSubject: false,
    permissionEditSubject: false,
    permissionSettingSubject: false,
    recruitments: [],
    render: false,
    selectedAssignedTeachers: [],
    selectedEduLevel: "",
    selectedYearLevels: "",
    settingModal: false,
    subjectArea: "",
    subjectAreaId: "",
    subjectAreaList: [],
    subjectId: "",
    subjectName: "",
    subjects: [],
    subjectSetting: "",
    yearLevels: [],
    thumbnail: null,
    thumbnailPreview: null,
    updatedThumbnailPath: "",
    subjectOptions: [],
  };

  assignForm = (subjectName) => {
    return (
      <TeacherSubjects
        assignedTeachers={this.state.modifiedYearLevels}
        //assignedTeachers={this.state.assignedTeachers}
        classTeachers={this.state.classTeachers}
        handleChangeAssignSelect={this.handleChangeAssignSelect}
        submitAssignTeachers={this.submitAssignTeachers}
        unAssignTeachers={this.unAssignTeachers}
        cancelUnAssignTeachers={this.cancelUnAssignTeachers}
        subjectName={subjectName}
        isSettingAvailable={this.state.isSettingAvailable}
      ></TeacherSubjects>
    );
  };

  assignFooter = () => {
    return (
      <>
        <button
          className="tt-button tt-button-primary"
          onClick={this.closeAssignModal}
        >
          Close
        </button>
      </>
    );
  };

  cancelUnAssignTeachers = (yearLevelIndex, eduLevelIndex, classRoomIndex) => {
    let newModifiedYearLevels = [...this.state.modifiedYearLevels];
    newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
      eduLevelIndex
    ].classrooms[classRoomIndex].unAssigningTeacher = false;
    this.setState({ modifiedYearLevels: newModifiedYearLevels });
  };

  cancelUpdateSubjectBtnClick = (index) => {
    let subjects = [...this.state.subjects];
    subjects[index].isOpen = false;
    this.setState({
      subjects: subjects,
      name: "",
      description: "",
      subjectAreaId: "",
    });
  };

  checkAvailability = () => {
    let params = {
      assessmentModuleCode: this.props.code,
    };
    axiosPost(
      URL.selectByKeyEducationLevelAssessmentModule,
      params,
      (res) => {
        if (res.data.data !== null) {
          this.setState({
            isAvailable: true,
            render: true,
          });
        } else {
          this.setState({ render: true });
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  checkPermissons = () => {
    this.setState({
      permissionEditSubject: checkRolePermissions(
        "edit-subject",
        "activity",
        this.props.name
      ),
      permissionDeleteSubject: checkRolePermissions(
        "delete-subject",
        "activity",
        this.props.name
      ),
      permissionSettingSubject: checkRolePermissions(
        "setting-subject",
        "activity",
        this.props.name
      ),
      permissionAssignTeacher: checkRolePermissions(
        "assign-teacher-subject",
        "activity",
        this.props.name
      ),
    });
  };

  closeAssignModal = () => {
    this.setState({
      assignModal: !this.state.assignModal,
    });
  };

  closeSettingModal = () => {
    this.setState({
      settingModal: !this.state.settingModal,
      subjectSetting: "",
      educationLevelDivisions: "",
      selectedYearLevels: "",
      selectedEduLevel: "",
    });
  };

  componentDidMount() {
    this.setState({ assessmentModuleCode: this.props.code }, function () {
      this.getEducationLevel();
    });
    this.getSubjectsCore();
    if (this.props.create === false) this.props.getSubjects(this.props.code);
    this.props.getSubjectAreas(this.props.code);
    this.getStaffList();
    this.checkAvailability();
    this.checkPermissons();
  }

  getSubjectsCore = () => {
    axiosPost(URL.getAllSubject, {}, (response) => {
      if (response.status == 200) {
        let subjectOptions = response.data.data;

        subjectOptions.forEach((sub) => {
          sub.value = sub.name;
          sub.label = sub.name;
        });

        this.setState({
          subjectOptions,
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.assignedTeachers !== this.state.assignedTeachers) {
      let assignedTeacherGrouped = this.state.assignedTeachers.reduce(
        (grouped, original) => {
          grouped[original.name] = [
            ...(grouped[original.name] || []),
            original,
          ];
          return grouped;
        },
        []
      );
      let yearLevelNameKeys = Object.keys(assignedTeacherGrouped);
      let yearLevels = [];
      yearLevelNameKeys.forEach((yearLevelName) => {
        let yearLevel = {
          name: yearLevelName,
          educationLevelDivisions: assignedTeacherGrouped[yearLevelName],
        };
        yearLevels.push(yearLevel);
      });
      this.setState({ yearLevels }, () => {
        this.getStaffList();
      });
    }
  }

  handleCreateNewSub = (inputValue) => {
    const newOption = {
      label: inputValue,
      value: inputValue,
    };

    this.setState({
      subjectOptions: [...this.state.subjectOptions, newOption],
      name: newOption,
    });
  };

  handleChangeSubject = (value) => {
    this.setState({
      name: value,
    });
  };

  createSubjectContent = (idx) => {
    let id = 0;
    let array = this.state.subjects;
    for (let x = 0; x < array.length; x++) {
      if (array[x]["id"] === idx) {
        id = x;
      }
    }
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <div
            className="tt-subjectAreaContent tt-form container"
            style={{ marginTop: 0 }}
          >
            <div className="row mb-2">
              <div className="col-md-2"></div>
              <div className="col-md-3 text-right">
                <label htmlFor="name">Name of subject : </label>
                <span className="tt-assessment-module-mandatory">*</span>
              </div>
              <div className="col-md-5">
                {this.props.create === false ? (
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={
                      this.state.nameError
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e, id)}
                    maxLength={40}
                  />
                ) : (
                  <CreatableSelect
                    isClearable
                    options={this.state.subjectOptions}
                    value={this.state.name}
                    onChange={(e) => this.handleChangeSubject(e, id)}
                    onCreateOption={this.handleCreateNewSub}
                  />
                )}
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mb-2">
              <div className="col-md-2"></div>
              <div className="col-md-3 text-right">
                <label htmlFor="name">Choose Practical/Theory : </label>
                <span className="tt-assessment-module-mandatory">*</span>
              </div>
              <div className="col-md-5">
                <strong>
                  Theory{" "}
                  <input
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                    type="checkbox"
                    onChange={(e) => this.handleSubjectChange(e)}
                    checked={this.state.checkboxTheoryVal}
                    name="Theory"
                  />
                </strong>

                <strong>
                  Practical
                  <input
                    style={{ marginLeft: "5px" }}
                    type="checkbox"
                    name="practical"
                    onChange={(e) => this.handleSubjectChange(e)}
                    checked={this.state.checkboxPracticalVal}
                  />
                </strong>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mb-2">
              <div className="col-md-2"></div>
              <div className="col-md-3 text-right">
                <label htmlFor="description" className="optional">
                  Description:
                </label>
              </div>
              <div className="col-md-5">
                <textarea
                  id="description"
                  //   placeholder="Write Something"
                  name="description"
                  className="form-control"
                  value={this.state.description}
                  onChange={(e) => this.handleChange(e, id)}
                  maxLength={256}
                />
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2"></div>
              <div className="col-md-3 text-right">
                <label htmlFor="name" className="optional">
                  Subject area:
                </label>
              </div>
              <div className="col-md-5">
                <select
                  name="subjectAreaId"
                  value={this.state.subjectAreaId}
                  onChange={(e) => this.handleChange(e, id)}
                  className="form-control"
                >
                  <option value="" disabled>
                    Choose one
                  </option>
                  {this.props.subjectAreas.map((subjectArea, idx) => {
                    return (
                      <option value={subjectArea.id} key={idx}>
                        {subjectArea.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2"></div>
              <div className="col-md-3 text-right">
                <label htmlFor="name" className="optional">
                  Subject Image:
                </label>
              </div>
              <div className="col-md-5">
                {this.state.thumbnailPreview ? (
                  <div className="row">
                    <div className="col-md-8 text-left">
                      {this.props.create ? (
                        <img
                          className="img-fluid"
                          src={window.URL.createObjectURL(
                            this.state.thumbnail[0]
                          )}
                          alt="subject thumbnail"
                        />
                      ) : this.state.updatedThumbnailPath === null ||
                        this.state.updatedThumbnailPath === "" ? (
                        <img
                          className="img-fluid"
                          src={window.URL.createObjectURL(
                            this.state.thumbnail[0]
                          )}
                          alt="subject thumbnail"
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={URL.imageSelectURL + this.state.thumbnailPreview}
                          alt="subject thumbnail"
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <button
                        className="tt-button tt-button-danger"
                        onClick={this.removeImage}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ) : (
                  <input
                    type="file"
                    name="thumbnail"
                    className="form-control"
                    onChange={this.handleImageChange}
                    // value={this.state.thumbnail}
                    multiple
                    style={{ padding: "2px" }}
                  />
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row form-bg-color mt-4">
              <div className="col-md-2"></div>
              <div className="col-md-3"></div>
              <div className="col-md-5">
                <div className="tt-subjectAreaButtons">
                  <button
                    className="tt-button tt-button-primary mr-4"
                    onClick={
                      this.props.create
                        ? this.submitSubject
                        : (e) => this.updateSubjects(e, idx)
                    }
                  >
                    {this.props.create ? "Create" : "Update"}
                  </button>
                  {this.props.create ? null : (
                    <button
                      className="tt-button tt-button-danger"
                      onClick={() => this.cancelUpdateSubjectBtnClick(id)}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        ) : (
          <div style={{ height: "70vh" }}>
            <NoteDisplay
              textContent={
                "Please assign education level to the " +
                this.props.name +
                " first.  "
              }
            ></NoteDisplay>
          </div>
        )}
      </>
    ) : null;
  };

  deleteList = ({ e, id }) => {
    e.preventDefault();
    axiosPost(URL.deleteSubjectList, { id: parseInt(id) }, (response) => {
      if (response.status === 200) {
        swal(MESSAGEHEADER.success, DISPLAYMESSAGE.deleteSubject, "");
        this.props.getSubjects(this.props.code);
      }
    });
  };

  getEducationLevel = () => {
    axiosPost(
      URL.getEducationLevelByAssessmentModule,
      { code: this.state.assessmentModuleCode },
      (response) => {
        if (response.status === 200) {
          this.setState({ educationLevels: response.data.data });
        }
        if (response.status === 204) {
          this.setState({ educationLevels: [] });
        }
      }
    );
  };

  getStaffCurrentList = (id) => {
    axiosPost(URL.getStaffList, { recruitmentStatusId: id }, (response) => {
      if (response.status === 200) {
        let classTeachers = [];
        let data = response.data.data;
        data.forEach((el) => {
          classTeachers.push({
            label: el.people.name,
            value: el.id,
          });
        });
        this.setState(
          {
            recruitments: response.data.data,
            classTeachers: classTeachers,
          },
          function () {
            if (this.state.recruitments.length > 0) {
              this.setState({ isSettingAvailable: true });
            }
          }
        );
      }
    });
  };

  getStaffList = () => {
    this.staffStatus();
  };

  getSubjectClassroomTeacher = (id, keepModalOpen) => {
    // added second arg on 20th feb 2020 by ishan. to have option not to close modal
    axiosPost(URL.getSubjectClassroomTeacher, { subjectId: id }, (response) => {
      let datas;
      if (response.status === 200) {
        datas = response.data.data;
      } else {
        datas = [];
      }
      this.setState({ assignedTeachers: datas }, () => {
        if (this.state.assignedTeachers.length > 0) {
          //
          let assignedTeacherGrouped = this.state.assignedTeachers.reduce(
            (grouped, original) => {
              grouped[original.name] = [
                ...(grouped[original.name] || []),
                original,
              ];
              return grouped;
            },
            []
          );
          let yearLevelNameKeys = Object.keys(assignedTeacherGrouped);
          let yearLevels = [];
          yearLevelNameKeys.forEach((yearLevelName) => {
            let yearLevel = {
              name: yearLevelName,
              educationLevelDivisions: assignedTeacherGrouped[yearLevelName],
            };
            yearLevels.push(yearLevel);
          });
          this.setState({ modifiedYearLevels: yearLevels }, () => {
            let newModifiedYearLevels = [...this.state.modifiedYearLevels];
            newModifiedYearLevels.forEach((yearLevel) => {
              yearLevel.educationLevelDivisions.forEach((eduDiv) => {
                eduDiv.classrooms.forEach((classroom) => {
                  if (
                    classroom &&
                    classroom.subjectClassroomTeachers &&
                    classroom.subjectClassroomTeachers.length > 0
                  ) {
                    classroom.subjectClassroomTeachers.forEach((teacher) => {
                      teacher.label = teacher.peopleName;
                      // teacher.value = teacher.peopleId;
                      teacher.value = teacher.subjectTeacherRecruitmentId;
                    });
                  }
                  classroom.unAssigningTeacher = false;
                  classroom.selectedsubjectClassroomTeachers = [];
                });
              });
            });
            this.setState({ modifiedYearLevels: newModifiedYearLevels });
          });
          //
          if (!keepModalOpen)
            this.setState({
              assignModal: !this.state.assignModal,
            });
        } else {
          swal("Prerequisite", "Please configure subject setting first.", "");
        }
      });
    });
  };

  handleChange = (e, id) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      if (name === "name") {
        if (this.state.name.length >= 40) {
          swal("Warning", "Maximum 40 characters are allowed!!!");
        }
        value.length > 0 && this.setState({ nameError: false });
      } else if (name === "description") {
        if (this.state.description.length >= 256) {
          swal("Warning", "Maximum 256 characters are allowed!!!");
        }
      }
    });
  };

  handleChangeAssignSelect = (
    selectedItem,
    yearLevelIndex,
    eduLevelIndex,
    classRoomIndex
  ) => {
    let newModifiedYearLevels = [...this.state.modifiedYearLevels];
    let difference = "";
    if (selectedItem == null) {
      difference =
        newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
          eduLevelIndex
        ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers;
    } else {
      if (
        newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
          eduLevelIndex
        ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers &&
        newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
          eduLevelIndex
        ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers.length > 0
      ) {
        difference = newModifiedYearLevels[
          yearLevelIndex
        ].educationLevelDivisions[eduLevelIndex].classrooms[
          classRoomIndex
        ].selectedsubjectClassroomTeachers.filter(
          (x) => !selectedItem.includes(x)
        );
      }
    }
    if (difference.length > 0) {
      var result = difference[0].deleteStatus === undefined;
      if (result !== undefined && !result) {
        displayConfirmDeleteAlert(
          {
            classroom:
              newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
                eduLevelIndex
              ].classrooms[classRoomIndex],
            teacher: difference,
            selectedItem: selectedItem,
            yearLevelIndex: yearLevelIndex,
            eduLevelIndex: eduLevelIndex,
            classRoomIndex: classRoomIndex,
          },
          this.unAssign
        );
      } else {
        newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
          eduLevelIndex
        ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers =
          selectedItem;
        this.setState({ modifiedYearLevels: newModifiedYearLevels });
      }
    } else {
      newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
        eduLevelIndex
      ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers =
        selectedItem;
      this.setState({ modifiedYearLevels: newModifiedYearLevels });
    }
  };

  handleDeleteChangeinSelectField = (
    selectedItem,
    yearLevelIndex,
    eduLevelIndex,
    classRoomIndex
  ) => {
    let newModifiedYearLevels = [...this.state.modifiedYearLevels];
    newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
      eduLevelIndex
    ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers =
      selectedItem;
    this.setState({ modifiedYearLevels: newModifiedYearLevels });
  };

  //   to update data
  handleUpdateSubjectBtnClick = (index, dBIndex) => {
    let subjects = [...this.state.subjects];
    subjects.forEach((subject) => {
      subject.isOpen = false;
    });
    subjects[index].isOpen = true;
    this.setState({
      subjects: subjects,
    });

    //fetch tuple to update
    let data = {
      id: parseInt(dBIndex),
      assessmentModuleCode: this.state.assessmentModuleCode,
    };
    axiosPost(URL.selectByKeySubjectList, data, (response) => {
      if (response.status === 200) {
        // let subname = {
        //   label: response.data.data.name,
        //   value: response.data.data.name,
        // };

        this.setState({
          name: response.data.data.name,
          description: response.data.data.description,
          subjectAreaId: response.data.data.subjectAreaId,
          checkboxPracticalVal: response.data.data.hasPractical
            ? response.data.data.hasPractical
            : false,
          checkboxTheoryVal: response.data.data.hasTheory
            ? response.data.data.hasTheory
            : false,
          thumbnailPreview: response.data.data.thumbnailPath
            ? response.data.data.thumbnailPath
            : null,
          updatedThumbnailPath: response.data.data.thumbnailPath
            ? response.data.data.thumbnailPath
            : "",
        });
      }
    });
  };

  handleImageChange = (e) => {
    let { name, value, files } = e.target;
    this.setState({
      [name]: files ? files : value,
      thumbnailPreview: files[0].name,
    });
  };

  // ASsigning subject as a Practical or Theory
  handleSubjectChange = (e) => {
    const { name, checked } = e.target;
    {
      name === "Theory"
        ? this.setState({ checkboxTheoryVal: checked })
        : this.setState({ checkboxPracticalVal: checked });
    }
  };

  insertSetting = (e) => {
    e.preventDefault();

    let Data = [];
    let eduLvls = [];
    let checkingValue = [];

    this.props.renderArray.forEach((el) => {
      el.educationLevelDivisionDtos.forEach((element) => {
        // if (element.doesExist) {
        eduLvls.push({
          educationLevelDivisionId: element.id,
          isCompulsory: element.isCompulsory,
          checked: element.doesExist,
        });
        //     }
      });
      if (el.yearLevelDto.checked) {
        let count = 0;
        el.educationLevelDivisionDtos.forEach((element) => {
          if (!element.doesExist) {
            count++;
          }
        });
        if (count === el.educationLevelDivisionDtos.length) {
          checkingValue.push(false);
        } else {
          checkingValue.push(true);
        }
      }

      if (eduLvls !== null && eduLvls !== "")
        Data.push({
          subjectId: this.state.subjectId,
          yearLevelId: el.yearLevelDto.id,
          educationLevelId: el.yearLevelDto.educationLevelId,
          subjectCoordinatorRecruitmentId: parseInt(
            el.yearLevelDto["subjectCoordinatorRecruitmentId"]
          ),
          educationLevelDivisions: eduLvls,
          checked: el.yearLevelDto.checked,
          creditHour: el.yearLevelDto.creditHour,
        });
      eduLvls = [];
    });
    let falseValueCount = 0;
    if (checkingValue.length > 0) {
      checkingValue.forEach((el) => {
        if (el === false) {
          falseValueCount++;
        }
      });
    }
    if (falseValueCount === 0) {
      axiosPost(URL.insertSubjectSetting, Data, (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, DISPLAYMESSAGE.insertSubjectSetting, "");
          this.setState(
            { selectedYearLevels: "", allYearLevels: "", yearLevels: "" },
            function () {
              // this.toggleSettingModal();
              this.setState({ settingModal: !this.state.settingModal });
            }
          );
        }
      });
    } else {
      swal({
        title: MESSAGEHEADER.error,
        text: `If ${
          localStorage.getItem("yearLevel") || "Class"
        } is selected then atleast one ${
          localStorage.getItem("educationLevelDivision") || "Division"
        } should be selected`,
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  removeImage = () => {
    this.setState({
      thumbnail: null,
      thumbnailPreview: null,
      updatedThumbnailPath: null,
    });
  };

  resetForm = (e, id) => {
    if (this.props.create) {
      this.setState({
        name: "",
        description: "",
        subjectAreaId: "",
        thumbnail: null,
        thumbnailPreview: null,
      });
    }
  };

  staffStatus = () => {
    axiosPost(URL.getRecruitmentStatus, {}, (response) => {
      if (response.status === 200) {
        let status = response.data.data;
        let id = "";
        status.forEach((el) => {
          if (el.name === "Current") {
            id = el.id;
          }
        });
        this.getStaffCurrentList(id);
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return { subjects: nextProps.subjectLists };
  }

  //Forms
  subjectSettingsForm = () => {
    return (
      <SettingSubjects
        id={this.state.subjectId}
        recruitments={this.state.recruitments}
        // isSettingAvailable={this.state.isSettingAvailable}
        isSettingAvailable={true}
        code={this.props.code}
      ></SettingSubjects>
    );
  };

  //Footers
  subjectSettingsFooter = () => {
    return (
      <div className="row">
        <div className="col">
          <button
            onClick={this.insertSetting}
            className="tt-button tt-button-primary mr-2"
          >
            Save
          </button>
          <button
            onClick={this.closeSettingModal}
            className="tt-button tt-button-danger"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  submitAssignTeachers = (
    classroom,
    idx,
    yearLevelId,
    eduLevelId,
    eduLevelDivisionId,
    yearLevelIndex,
    eduLevelIndex,
    classroomIndex
  ) => {
    let newModifiedYearLevels = [...this.state.yearLevels];
    let teachers =
      newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
        eduLevelIndex
      ].classrooms[classroomIndex].selectedsubjectClassroomTeachers;
    if (teachers && teachers.length > 0) {
      let assignedData = [];
      teachers.forEach((teacher) => {
        assignedData.push({
          subjectId: this.state.subjectId,
          yearLevelId: yearLevelId,
          classroomId: classroom.id,
          educationLevelDivisionId: eduLevelDivisionId,
          educationLevelId: eduLevelId,
          subjectTeacherRecruitmentId: teacher.value,
        });
      });
      axiosPost(URL.insertSubjectClassroomTeacher, assignedData, (response) => {
        if (response.status === 200) {
          this.getSubjectClassroomTeacher(this.state.subjectId, true);
        }
      });
    } else {
      swal(MESSAGEHEADER.error, "Please assign subject teacher first", "");
    }
  };

  submitSubject = (e) => {
    e.preventDefault();

    if (this.state.name === null) {
      swal({
        title: MESSAGEHEADER.error,
        text: "Name of subject cannot be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else if (
      this.state.checkboxTheoryVal === false &&
      (this.state.checkboxPracticalVal === "" ||
        this.state.checkboxPracticalVal === false)
    ) {
      swal({
        title: MESSAGEHEADER.error,
        text: "Either Practical or Theory must be checked/selected.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let data = {
        name: this.state.name.value,
        description: this.state.description,
        subjectAreaId: this.state.subjectAreaId,
        assessmentModuleCode: this.state.assessmentModuleCode,
        hasTheory: this.state.checkboxTheoryVal,
        hasPractical: this.state.checkboxPracticalVal,
      };

      let formData = new FormData();
      if (this.state.thumbnail !== null) {
        formData.append("file", this.state.thumbnail[0]);
      }

      formData.append("jsonData", JSON.stringify(data));

      axiosPost(URL.insertSubjectList, formData, (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, DISPLAYMESSAGE.insertSubject, "");
          this.resetForm();
          this.props.getSubjects(this.props.code);
          this.props.getGroups(this.props.profilePeopleId);
        }
      });
    }
  };

  toggleAssignModal = (name, id) => {
    if (id !== undefined) {
      this.getSubjectClassroomTeacher(id);
      this.setState({ subjectId: id, subjectName: name });
    }
  };

  //toggle Modals
  toggleSettingModal = (name, id) => {
    let params = {
      assessmentModuleCode: this.props.code,
    };
    axiosPost(
      URL.selectByKeyEducationLevelAssessmentModule,
      params,
      (response) => {
        if (response.data.data !== null) {
          this.setState(
            {
              settingModal: !this.state.settingModal,
              subjectId: id,
              subjectName: name,
            },
            function () {
              // this.getSubjectSetting();
            }
          );
        } else {
          swal(
            "Prerequisite",
            "Please assign education level to the " +
              this.props.name +
              " first  ",
            ""
          );
        }
      }
    );
  };

  unAssign = (data) => {
    let unAssignedData = [],
      classroom = data.classroom,
      teacher = data.teacher,
      selectedItem = data.selectedItem,
      yearLevelIndex = data.yearLevelIndex,
      eduLevelIndex = data.eduLevelIndex,
      classRoomIndex = data.classRoomIndex;
    unAssignedData.push({
      subjectId: this.state.subjectId,
      yearLevelId: classroom.yearLevelId,
      classroomId: classroom.id,
      subjectTeacherRecruitmentId: teacher[0].subjectTeacherRecruitmentId,
    });
    let newModifiedYearLevels = [...this.state.modifiedYearLevels];
    let difference = newModifiedYearLevels[
      yearLevelIndex
    ].educationLevelDivisions[eduLevelIndex].classrooms[
      classRoomIndex
    ].subjectClassroomTeachers.filter((x) => !teacher.includes(x));
    newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
      eduLevelIndex
    ].classrooms[classRoomIndex].subjectClassroomTeachers = difference;
    this.setState({ modifiedYearLevels: newModifiedYearLevels });
    axiosPost(URL.deleteSubjectClassroomTeacher, unAssignedData, (response) => {
      if (response.status === 200) {
        this.handleDeleteChangeinSelectField(
          selectedItem,
          yearLevelIndex,
          eduLevelIndex,
          classRoomIndex
        );
        // this.getSubjectClassroomTeacher(this.state.subjectId, true, true); //second parameter is to keep modal open
      }
    });
  };

  unAssignTeachers = (
    classroom,
    yearLevelIndex,
    eduLevelIndex,
    classRoomIndex
  ) => {
    let newModifiedYearLevels = [...this.state.modifiedYearLevels];
    newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
      eduLevelIndex
    ].classrooms[classRoomIndex].unAssigningTeacher = true;
    newModifiedYearLevels[yearLevelIndex].educationLevelDivisions[
      eduLevelIndex
    ].classrooms[classRoomIndex].selectedsubjectClassroomTeachers =
      classroom.subjectClassroomTeachers;
    this.setState({ modifiedYearLevels: newModifiedYearLevels });
  };

  updateSubjects = (e, id) => {
    if (this.state.name === null || this.state.name === "") {
      swal({
        title: MESSAGEHEADER.error,
        text: "Name of subject cannot be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else if (
      this.state.checkboxTheoryVal === "" &&
      this.state.checkboxPracticalVal === ""
    ) {
      swal({
        title: MESSAGEHEADER.error,
        text: "Either Practical or Theory must be checked/selected.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let data = {
        id: parseInt(id),
        name: this.state.name,
        description: this.state.description,
        subjectAreaId: this.state.subjectAreaId,
        hasPractical: this.state.checkboxPracticalVal,
        hasTheory: this.state.checkboxTheoryVal,
        thumbnailPath: this.state.updatedThumbnailPath,
      };

      let formData = new FormData();
      if (this.state.thumbnail !== null) {
        formData.append("file", this.state.thumbnail[0]);
      }

      formData.append("jsonData", JSON.stringify(data));

      axiosPost(subject + "/update", formData, (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, DISPLAYMESSAGE.updateSubject, "");
          this.props.getSubjects(this.props.code);
        }
      });
    }
  };

  render() {
    return (
      <>
        {this.props.create ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <div className="tt-group-header">
              Create Subject{" "}
              {checkWidgetAdminOrNot(this.props.name) === true ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            {this.createSubjectContent()}
          </div>
        ) : (
          <>
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.5vh" }}
            >
              <div className="tt-group-header">
                List Subjects{" "}
                {checkWidgetAdminOrNot(this.props.name) === true ? (
                  <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        permissionModal: !this.state.permissionModal,
                      });
                    }}
                  >
                    Permissions
                  </button>
                ) : null}
              </div>
              <div className="tt-newListBox container-fluid">
                <div className="row">
                  <div className="col-md-12 tt-listSubjectAreas">
                    <table className="table table-bordered text-center table-striped">
                      <thead className="tt-group-header">
                        <tr>
                          <th width="20%">Subjects</th>
                          <th width="20%">Subject Area</th>
                          <th width="50%">Description</th>
                          {this.state.permissionEditSubject ||
                          this.state.permissionDeleteSubject ||
                          this.state.permissionSettingSubject ||
                          this.state.permissionAssignTeacher ? (
                            <th>Options</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.subjectLists.length > 0 ? (
                          this.props.subjectLists.map((subject, idx) => {
                            return (
                              <UpdateSubjects
                                code={this.props.code}
                                key={idx}
                                subjectList={subject}
                                idx={idx}
                                toggleSettingModal={this.toggleSettingModal}
                                toggleAssignModal={this.toggleAssignModal}
                                createSubjectContent={this.createSubjectContent}
                                deleteList={this.deleteList}
                                handleUpdateSubjectBtnClick={
                                  this.handleUpdateSubjectBtnClick
                                }
                                name={this.props.name}
                                permissionEditSubject={
                                  this.state.permissionEditSubject
                                }
                                permissionDeleteSubject={
                                  this.state.permissionDeleteSubject
                                }
                                permissionSettingSubject={
                                  this.state.permissionSettingSubject
                                }
                                permissionAssignTeacher={
                                  this.state.permissionAssignTeacher
                                }
                              ></UpdateSubjects>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              className="text-center"
                              colSpan={
                                this.state.permissionEditSubject ||
                                this.state.permissionDeleteSubject ||
                                this.state.permissionSettingSubject ||
                                this.state.permissionAssignTeacher
                                  ? 4
                                  : 3
                              }
                            >
                              {this.props.subjectSpinner ? (
                                <>
                                  <Spinner color="primary" />
                                  <br />
                                  Loading Subjects ...
                                </>
                              ) : (
                                "No subjects available"
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ModalWindow
                fullWidth={true}
                modal={this.state.settingModal}
                toggleModal={this.closeSettingModal}
                // modalHeader={this.state.subjectName + " Settings"}
                modalHeader={"Subject Settings - " + this.state.subjectName}
                modalBody={this.subjectSettingsForm()}
                modalFooter={this.subjectSettingsFooter()}
              ></ModalWindow>
              <ModalWindow
                // fullWidth={true}
                size="xl"
                modal={this.state.assignModal}
                toggleModal={this.closeAssignModal}
                // modalHeader={"Assign " + this.state.subjectName + " Teachers"}
                modalHeader={"Assign Teacher - " + this.state.subjectName}
                modalBody={this.assignForm(this.state.subjectName)}
                modalFooter={this.assignFooter()}
              ></ModalWindow>
            </div>
          </>
        )}
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Subjects Setup"
              header={this.props.create ? "Create Subject" : "List Subject"}
              activityName={
                this.props.create ? "insert-subject" : "select-subject"
              }
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  subjectLists: state.primaryArr.subjects,
  subjectAreas: state.primaryArr.subjectAreas,
  renderArray: state.primaryArr.renderArray,
  subjectSpinner: state.primaryArr.subjectSpinner,
});

const mapActionsToProps = {
  getSubjects: getSubjects,
  getSubjectAreas: getSubjectAreas,
  getGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(Subjects);
