import React, { useEffect, useState } from 'react'
import {
  URL,
  checkWidgetAdminOrNot,
  subject,
  userRole,
  userRoleCode,
} from "../../utils/Constants";
import { axiosPost } from '../../utils/AxiosApi';
import AttendanceHeader from './AttendanceHeader';
import moment from 'moment';
import AttendanceList from './AttendanceList';
import swal from "sweetalert";
import { displayErrorAlert } from '../../utils/Utils';
import { Spinner } from 'reactstrap';
import ModalWindow from '../UI/ModalWindow';
import GranularPermissionModal from '../Permissions/GranularPermissionModal';

function Attendance() {
  const [yearLevel, setYearLevel] = useState();
  const [classrooms, setClassrooms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classroomId, setClassroomId] = useState(null);
  const [yearLevelId, setYearLevelId] = useState(null);
  const [subjectId, setSubjectId] = useState(0);
  const [listStudent, setListStudent] = useState([])
  const [listStudentUpdated, setListStudentUpdated] = useState([])
  const [studentAttendance, setStudentAttendance] = useState([])
  const [calendarDate, setCalendarDate] = useState(moment().format("YYYY-MM-DD"));
  const [checkedAllStudents, setCheckedAllStudents] = useState(false)
  const [isDayWiseAttendnace, setIsDayWiseAttendnace] = useState(true)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedClassroom, setSelectedClassroom] = useState(null)
  const [submitSpinner, setSubmitSpinner] = useState(false)
  const [attendanceLoading, setAttendanceLoading] = useState(false)
  const [academicYearStart, setAcademicYearStart] = useState(null)
  const [academicYearEnd, setAcademicYearEnd] = useState(null)
  const[isHoliday, setIsHoliday] = useState(false)
  const [educationLevelId, setEducationLevelid] = useState(null)
  const [weekDays, setWeekDays] = useState(null)
  const [permissionModal, setPermissionModal] = useState(false)

  useEffect(() => {
    if (userRole === userRoleCode.roleAdmin) {
      getClassrooms();
    } else if (userRole === userRoleCode.roleStaff) {
      getRecruitment();
    }
    evaluateCurrentAcademicYear();
  }, [])

  useEffect(() => {
    let studentList = listStudent
    let studentAttendanceList = studentAttendance
    let studentListUpdated = []
    // if (studentList.length > 0 && studentAttendanceList.length > 0) {
      studentListUpdated = studentList.map(ls => {
        let existingAttendance = studentAttendanceList.find((sa) => ls.admissionId == sa.admissionId)
        if(!!existingAttendance){
          return { ...ls, isPresent: existingAttendance?.isPresent, attendanceId: existingAttendance?.id }
        }return {...ls,isPresent: false}

      });
    // }
    setListStudentUpdated(studentListUpdated);
  }, [studentAttendance, listStudent])

  useEffect(() => {
    if (!!classroomId) {
      getClassroomSubjects(classroomId);
      getAttendanceData(classroomId)
    }
  }, [classroomId])

  useEffect(() => {
    if (!!selectedSubject || (!selectedSubject && isDayWiseAttendnace && listStudentUpdated.length >0)) {
      getAttendanceData(classroomId)
    }
  }, [selectedSubject])

  useEffect(() =>{
    if(!!selectedClassroom && calendarDate != ''){
      getClassroomSubjects(classroomId);
      getAttendanceData(classroomId)
    }
    checkisHoliday()
  },[calendarDate])

  const checkisHoliday = async () =>{
    //set holiday true if not a weekday of education level
    const weekDays = await getWeekDays();
    const weekDay = new Date(calendarDate).getUTCDay();
    const isHoliday = weekDays.findIndex((day) => day === weekDay);
    setIsHoliday(isHoliday === -1)
    if (isHoliday !== -1) {
      const eventHoliday = await checkForCalendarEvents();
      setIsHoliday(!!eventHoliday)
    }
  }

  const getWeekDays = async() =>{
    const weekDayPromise = new Promise((res, rej) => {
      axiosPost(
        URL.getWeekDaysByEduDiv,
        {
          educationLevelId: educationLevelId,
        },
        (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        }
      );
    });
    const weekDays = await weekDayPromise;
    weekDays &&
    setWeekDays(weekDays.map(({ id }) => id))
    return weekDays.map(({ id }) => id);

  }

  const checkForCalendarEvents = async () =>{
    const calendarEventsPromise = new Promise((res, rej) => {
      axiosPost(
        URL.getEventsByEducationLevel,
        {
          educationLevelId: educationLevelId,
        },
        (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        }
      );
    });
    const calendarEvents = await calendarEventsPromise;
    if (calendarEvents) {
      //return true if selected day is holiday event
      for (let i = 0; i < calendarEvents.length; i++) {
        if (calendarEvents[i].isHoliday) {
          let range = moment(calendarDate).isBetween(
            calendarEvents[i].start,
            calendarEvents[i].end,
            "days",
            "[]"
          );
          if (range) return true;
        }
      }
    }
    return false;
  }


  const getClassrooms = () => {
    let data = {
      yearLevelId: yearLevel,
    };
    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.status === 200) {
        let classroomData = response.data.data;
        setClassrooms(classroomData);
      }
    });
  };
  const getRecruitment = () => {

  }
  const evaluateCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          setAcademicYearStart(response?.data?.data?.startDate?.substring(
            0,
            10
          ))
          setAcademicYearEnd(response?.data?.data?.endDate?.substring(0, 10))
        }
      }
    });
  }

  const handleClassroomSelect = (e) => {
    const target = e.target;
    const value = target.value.split(",");
    const name = target.name;
    const cid = value[0];
    const yid = value[1];
    const classroom = classrooms.find((c) => c.id === parseInt(cid));
    setSelectedClassroom(classroom)
    setClassroomId(parseInt(cid))
    setYearLevelId(parseInt(yid))
    setEducationLevelid(classroom.educationLevelId)
  }

  const handleSubjectSelect = (e) => {
    let { name, value} = e.target;
    let idx = e.target.selectedIndex -1;
    let selectedSubject = subjects.find(item => item.id == value)
    setSelectedSubject(selectedSubject);
    setCheckedAllStudents(false)


  }
  const handleCalandarDateChange = (e) => {
    const target = e.target;
      const value = target.value;
      setCalendarDate(value)
  }

  const studentAttendanceChange = (e, admissionId) => {
    const {name,type }= e.target
    const value = type === "checkbox" ? e.target.checked : e.target.value;
    let studentAttendanceList = listStudentUpdated.map((item) =>(
        {...item,
          [name]:item.admissionId == admissionId ? value:item[name]
        }
    ))
    // const name = target.name;
    setCheckedAllStudents(false)
    setListStudentUpdated(studentAttendanceList)

  }


  const getClassroomSubjects = (classroomId = classroomId) => {
    var data = {
      classroomId: classroomId,
      day: moment(calendarDate).format("dddd"),
    };
    axiosPost(URL.getSubjectByClassroomForAttendance, data, (response) => {
      if (response.data.status === 200) {
        setSubjects(response.data.data)
      }
    })
  }
  const getStudentData = (classroomId) => {
    setAttendanceLoading(true)
    const params = {
      classroomId: classroomId,
      subjectId: selectedSubject?.id,
      date: calendarDate,
    };
    axiosPost(
      URL.getAssignedStudents,
      params,
      (response) => {
        if (response.status === 200) {
          let students = response.data.data;
          setListStudent(students)
        }
        setAttendanceLoading(false)
      })
  }
  const getAttendanceData = (classroomId) => {
    const params = {
      classroomId: classroomId,
      subjectId: selectedSubject?.id,
      date: calendarDate,
    };
    axiosPost(
      URL.getStudentsAttendance,
      params,
      (response) => {
        if (response.status === 200) {
          let attendanceData = response.data.data
          let studentAttendances = { studentAttendance };
          setStudentAttendance(attendanceData)
          getStudentData(classroomId)
        }

      })
  }

  const attendanceConfirmation = () =>{
    if (!selectedSubject && !isDayWiseAttendnace) {
      swal("Warning", "Please select atleast one subject.");
      this.setSubmitSpinner(false);
    }else if(calendarDate == ''){
      swal("Warning", "Date is not selected");
      return;
    }
    else if (!classroomId) {
      swal("Warning", "First select the classroom for attendance.");
      return;
    } else {
      swal({
        title: "Are you sure?",
        text: "Do you really want to continue the attendance!",
        closeOnClickOutside: false,
        allowOutsideClick: false,
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          doAttendance();
        }
      });
    }
  }

  const doAttendance = () => {
    setSubmitSpinner(true);
    const diff = moment(calendarDate).diff(
      moment().format("YYYY-MM-DD"),
      "days"
    );
    if (diff < 1) {
      let attendancePayload = {
        yearLevelId: yearLevelId,
        classroomId: classroomId,
        date: calendarDate,
        studentAttendanceWrappers: [],
      };
      let studentAttendanceWrappers = {
        subjectId: selectedSubject?.id,
        studentAttendanceStudentDataWrappers: [],
      }
      listStudentUpdated.forEach((ls) => {
        let data = {
          admissionId: ls.admissionId,
          isPresent: ls.isPresent,
          remarks: ls.remarks,
        }
        studentAttendanceWrappers.studentAttendanceStudentDataWrappers.push(data)
      })
      attendancePayload.studentAttendanceWrappers.push(studentAttendanceWrappers);
      axiosPost(
        URL.insertStudentAttendance,
        attendancePayload,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Attendance Recorded");
            setSubmitSpinner(false);
          }
        },
        (err) => {
          displayErrorAlert(err);
          setSubmitSpinner(false);
        }
      )
    } else {
        swal("Warning", "You are trying to insert attendance in the future.");
      setSubmitSpinner(false);
    }
  }

  const checkAllStudents = (e) => {
    let attendanceList = listStudentUpdated
    attendanceList.forEach((attendent) => {
      attendent.isPresent = e.target.checked;
    });
    setListStudentUpdated(attendanceList)
    setCheckedAllStudents(e.target.checked)
  }
  const checkIsDayWiseAttendnace = (e) => {
    if(e.target.checked) {
      setSelectedSubject(null)
      getAttendanceData(classroomId)
    }
    setIsDayWiseAttendnace(e.target.checked)
  }
  return (
    <>
    {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Attendance record is being stored
            </div>
          </div>
        ) : (
          ""
        )}
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      >
        <div className="tt-group-header">
            Attendance
            {checkWidgetAdminOrNot("Attendance") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  setPermissionModal(!permissionModal)
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
        <div className="tt-newListBox container-fluid">
          <AttendanceHeader
            academicYearEnd={academicYearEnd}
            academicYearStart={academicYearStart}
            calendarDate={calendarDate}
            selectedClassroom={selectedClassroom}
            classrooms={classrooms}
            handleCalandarDateChange={handleCalandarDateChange}
            handleClassroomSelect={handleClassroomSelect}
            handleSubjectSelect={handleSubjectSelect}
            isHoliday={isHoliday}
            subjects={subjects}
            selectAllSubjects={isDayWiseAttendnace}
            checkAllSubjects={checkIsDayWiseAttendnace}
            selectedSubject={selectedSubject}
          />
          <div className="row mt-2">
            <div
              className="col"
              style={{
                height: "52.5vh",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {attendanceLoading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : <AttendanceList
              checkAllStudents={checkAllStudents}
              checkedAllStudents={checkedAllStudents}
              studentAttendanceChange={studentAttendanceChange}
              studentAttendances={null}
              students={listStudentUpdated}
              selectedAttendance={null}
            />}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-8"></div>
            <div className="col-md-4 text-right">
              <button
                className="tt-button tt-button-primary attendance-button"
                onClick={attendanceConfirmation}
                disabled={!selectedClassroom}
              >
                {listStudentUpdated.some((s) => s.attendanceId) ? 'Update Attendance' : 'Do Attendance'}
              </button>
            </div>
          </div>
        </div>
        <ModalWindow
            modal={permissionModal}
            size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              setPermissionModal(!permissionModal)
            }}
            modalHeader={"Assign permission to user"}
            modalBody={
              <GranularPermissionModal
                widgetName="Attendance"
                moduleName="Student Attendance"
                header="Student Attendance"
                activityName="insert-attendance"
              />
            }
          ></ModalWindow>
      </div>
    </>
  )
}

export default Attendance
