/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, ListYearLevel, render the list of year levels, extracting from the database
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getEducationLevels,
  getDetailYearLevels,
  getGroups,
} from "../../../../ducks/AcademicAdministrationDucks";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import swal from "sweetalert";
import CustomCollapse from "../../../UI/CustomCollapse";
import {
  compareByName,
  displayConfirmDeleteAlert,
} from "../../../../utils/Utils";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";

class ListYearLevel extends Component {
  state = {
    yearData: [],
    yearLevel: "",
    detailYearLevels: [],
    selectedYearLevel: "",
    selectedYearLevelId: "",
    reAssignYearLevelId: "",
    educationLevels: [],
    selectedEducationLevel: "",
    isLoading: true,
    selectedYearLevelError: false,
    updateDescription: "",
    permissionEditGrade: false,
    permissionDeleteGrade: false,
    permissionReaasignGrade: false,
  };
  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the list from the year
   * level table from the database.
   *
   */
  componentDidMount() {
    // this.props.getYearLevels()
    this.props.getDetailYearLevels();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditGrade: checkRolePermissions("edit-grade", "activity"),
      permissionDeleteGrade: checkRolePermissions("delete-grade", "activity"),
      permissionReaasignGrade: checkRolePermissions(
        "reassign-grade",
        "activity"
      ),
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      detailYearLevels: nextProps.detailYearLevels,
      educationLevels: nextProps.educationLevels,
      yearLevels: nextProps.yearLevels,
    };
  }

  editYearLevel = (e, yearLevelIdx, idx) => {
    e.preventDefault();
    let yearLevels = [...this.state.detailYearLevels];
    yearLevels.forEach((el) => {
      el.yearLevels.forEach((year) => {
        year.editable = false;
      });
    });
    yearLevels[yearLevelIdx].yearLevels[idx].editable = true;
    this.setState({
      detailYearLevels: yearLevels,
      selectedYearLevel: yearLevels[yearLevelIdx].yearLevels[idx].name,
      updateDescription: yearLevels[yearLevelIdx].yearLevels[idx].description,
      selectedYearLevelId: yearLevels[yearLevelIdx].yearLevels[idx].id,
    });
  };

  reAssgignCollapsible = (e, yearLevelIdx, idx) => {
    e.preventDefault();
    let yearLevels = [...this.state.detailYearLevels];
    yearLevels.forEach((el) => {
      el.yearLevels.forEach((year) => {
        year.collapsible = false;
      });
    });
    yearLevels[yearLevelIdx].yearLevels[idx].collapsible = true;
    this.setState({
      detailYearLevels: yearLevels,
      selectedEducationLevel: yearLevels[yearLevelIdx].id,
      reAssignYearLevelId: yearLevels[yearLevelIdx].yearLevels[idx].id,
    });
  };

  reAssignYearLevel = (e) => {
    e.preventDefault();
    let data = {
      educationLevelId: this.state.selectedEducationLevel,
      yearLevelId: this.state.reAssignYearLevelId,
    };
    axiosPost(URL.reAssignYearLevel, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: `${localStorage.getItem("yearLevel")} successfully re-assigned`,
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        let yearLevels = [...this.state.detailYearLevels];
        yearLevels.forEach((el) => {
          el.yearLevels.forEach((year) => {
            year.collapsible = false;
          });
        });
        this.setState({
          detailYearLevels: yearLevels,
          selectedEducationLevel: "",
          reAssignYearLevelId: "",
        });
        this.props.getDetailYearLevels();
      }
    });
  };

  closeCollapsible = () => {
    let yearLevels = [...this.state.detailYearLevels];
    yearLevels.forEach((el) => {
      el.yearLevels.forEach((year) => {
        year.collapsible = false;
      });
    });
    this.setState({
      detailYearLevels: yearLevels,
      selectedEducationLevel: "",
      reAssignYearLevelId: "",
    });
  };

  collapsibleYearLevel = () => {
    return (
      <div className="card">
        {" "}
        <div className="container mt-2 mb-2">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-3 mt-1">
              <label>
                <strong>Education Level</strong>
              </label>
            </div>
            <div className="col-md-4">
              <select
                className="form-control"
                name="selectedEducationLevel"
                value={this.state.selectedEducationLevel}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Choose new Education level
                </option>
                {this.state.educationLevels
                  ? this.state.educationLevels.map((el, idx) => {
                      return (
                        <option value={el.id} key={idx}>
                          {el.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-4">
            <div className="col-md-2"></div>
            <div className="col-md-3"></div>
            <div className="col-md-4 text-right">
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.reAssignYearLevel}
              >
                Re assign
              </button>
              <button
                className="tt-button tt-button-primary"
                onClick={this.closeCollapsible}
              >
                Cancel
              </button>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    );
  };

  closeYearLevel = () => {
    let yearLevels = [...this.state.detailYearLevels];
    yearLevels.forEach((el) => {
      el.yearLevels.forEach((year) => {
        year.editable = false;
      });
    });
    this.setState({
      detailYearLevels: yearLevels,
      selectedYearLevel: "",
      selectedYearLevelId: "",
    });
  };

  /**
   * This method handles the input on Change
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    name === "selectedYearLevel " &&
      name.length > 0 &&
      this.setState({ selectedYearLevelError: false });
    this.setState({
      [name]: value,
    });
  };

  /**
   * This method sends put/update request with the modified data
   */

  updateYearLevel = (e) => {
    e.preventDefault();
    let data = {
      id: this.state.selectedYearLevelId,
      name: this.state.selectedYearLevel,
      description: this.state.updateDescription,
    };
    if (data.name === "") {
      swal({
        title: "Error",
        text: "Name cannot be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      this.setState({
        selectedYearLevelError:
          this.state.selectedYearLevel === "" ? true : false,
      });
    } else {
      axiosPost(URL.updateYearLevel, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: `${localStorage.getItem(
              "yearLevel"
            )} has been successfully updated`,
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.props.getDetailYearLevels();
          this.props.getGroups(this.props.profilePeopleId);
        }
      });
    }
  };

  deleteYearLevel = (e, eduLevelId, yearLevelId) => {
    e.preventDefault();
    let data = {
      educationLevelId: eduLevelId,
      yearLevelId: yearLevelId,
    };
    displayConfirmDeleteAlert(data, this.confirmDeleteYearLevel);
  };

  confirmDeleteYearLevel = (data) => {
    axiosPost(URL.deleteDetailYearLevel, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: `${localStorage.getItem("yearLevel")} deleted Successfully`,
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.props.getDetailYearLevels();
      }
    });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            {`List ${
              localStorage.getItem("yearLevel")
                ? localStorage.getItem("yearLevel")
                : "Grade"
            }`}
            {checkWidgetAdminOrNot("Academic Administration") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div className="tt-newListBox">
            {this.state.detailYearLevels ? (
              this.state.detailYearLevels.map((yearLevels, yearLevelIdx) => {
                return (
                  <table
                    className="table text-center table-bordered table-striped mb-5"
                    key={yearLevelIdx}
                  >
                    <thead className="tt-group-header text-left">
                      <tr>
                        <th colSpan="5">{yearLevels.name}</th>
                      </tr>
                    </thead>
                    <thead className="tt-group-header-dark">
                      <tr>
                        <th width="10%">{localStorage.getItem("yearLevel")}</th>
                        <th width="35%">Description</th>
                        <th width="130px">
                          {localStorage.getItem("classroom")}
                        </th>
                        <th>{localStorage.getItem("classroom")} Teachers</th>
                        {this.state.permissionEditGrade ||
                        this.state.permissionDeleteGrade ||
                        this.state.permissionReaasignGrade ? (
                          <th width="10%">Options</th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {yearLevels.yearLevels.length > 0 ? (
                        yearLevels.yearLevels
                          // .sort(compareByName)
                          .map((el, idx) => {
                            return (
                              <React.Fragment>
                                <tr key={idx}>
                                  <td
                                    rowSpan={
                                      el.classrooms.length > 0
                                        ? el.classrooms.length
                                        : 1
                                    }
                                    style={
                                      idx % 2 === 0
                                        ? { backgroundColor: "white" }
                                        : {
                                            backgroundColor:
                                              "rgba(119, 152, 237, 0.3)",
                                          }
                                    }
                                  >
                                    {el.editable ? (
                                      <input
                                        type="text"
                                        name="selectedYearLevel"
                                        className={
                                          this.state.selectedYearLevelError
                                            ? "form-control indicate-error"
                                            : "form-control"
                                        }
                                        value={this.state.selectedYearLevel}
                                        onChange={this.handleChange}
                                      ></input>
                                    ) : (
                                      el.name
                                    )}
                                  </td>
                                  <td
                                    className="text-left"
                                    rowSpan={
                                      el.classrooms.length > 0
                                        ? el.classrooms.length
                                        : 1
                                    }
                                  >
                                    {el.editable ? (
                                      <textarea
                                        className="form-control"
                                        name="updateDescription"
                                        value={this.state.updateDescription}
                                        onChange={this.handleChange}
                                      ></textarea>
                                    ) : el.description !== "" ? (
                                      el.description
                                    ) : (
                                      "No description"
                                    )}
                                  </td>
                                  <td className="text-left" rowSpan={1}>
                                    {el.classrooms.length > 0
                                      ? el.classrooms.map(
                                          (section, sectionIdx) => {
                                            if (sectionIdx === 0) {
                                              return (
                                                <p key={sectionIdx}>
                                                  <strong>
                                                    {section.name}
                                                  </strong>
                                                  <br></br>(
                                                  {section.homeRoomTeacher})
                                                </p>
                                              );
                                            }
                                          }
                                        )
                                      : `No ${localStorage.getItem(
                                          "classroom"
                                        )} assigned`}
                                  </td>
                                  <td className="text-left" rowSpan={1}>
                                    {el.classrooms.length > 0
                                      ? el.classrooms.map(
                                          (section, sectionIdx1) => {
                                            if (sectionIdx1 === 0) {
                                              return (
                                                <React.Fragment
                                                  key={sectionIdx1}
                                                >
                                                  <p className="listedTeachers">
                                                    {section
                                                      .subjectClassroomTeachers
                                                      .length > 0
                                                      ? section.subjectClassroomTeachers.map(
                                                          (
                                                            teacher,
                                                            teacherIdx
                                                          ) => {
                                                            return (
                                                              <span
                                                                key={teacherIdx}
                                                              >
                                                                <span>
                                                                  {teacher.name}
                                                                </span>
                                                                <span>
                                                                  {", "}
                                                                </span>
                                                              </span>
                                                            );
                                                          }
                                                        )
                                                      : `No ${localStorage.getItem(
                                                          "classroom"
                                                        )} teachers assigned`}
                                                  </p>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )
                                      : `No ${localStorage.getItem(
                                          "classroom"
                                        )} assigned`}
                                  </td>
                                  {this.state.permissionEditGrade ||
                                  this.state.permissionDeleteGrade ||
                                  this.state.permissionReaasignGrade ? (
                                    <td
                                      rowSpan={
                                        el.classrooms.length > 0
                                          ? el.classrooms.length
                                          : 1
                                      }
                                      style={
                                        idx % 2 === 0
                                          ? { backgroundColor: "white" }
                                          : {
                                              backgroundColor:
                                                "rgba(119, 152, 237, 0.3)",
                                            }
                                      }
                                    >
                                      {el.editable ? (
                                        <>
                                          <button
                                            className="tt-button tt-button-primary mr-2 tt-button-fullWidth"
                                            onClick={this.updateYearLevel}
                                          >
                                            Save
                                          </button>
                                          <button
                                            className="tt-button tt-button-primary tt-button-fullWidth"
                                            onClick={(e) =>
                                              this.closeYearLevel()
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {this.state.permissionEditGrade ? (
                                            <button
                                              className="tt-button tt-button-primary mr-2 tt-button-fullWidth"
                                              onClick={(e) =>
                                                this.editYearLevel(
                                                  e,
                                                  yearLevelIdx,
                                                  idx
                                                )
                                              }
                                              disabled={el.collapsible}
                                            >
                                              Edit
                                            </button>
                                          ) : null}
                                          {this.state
                                            .permissionReaasignGrade ? (
                                            <button
                                              className="tt-button tt-button-primary mr-2 tt-button-fullWidth"
                                              onClick={(e) =>
                                                this.reAssgignCollapsible(
                                                  e,
                                                  yearLevelIdx,
                                                  idx
                                                )
                                              }
                                              disabled={el.collapsible}
                                            >
                                              Reassign
                                            </button>
                                          ) : null}
                                          {this.state.permissionDeleteGrade ? (
                                            <button
                                              className="tt-button tt-button-primary tt-button-fullWidth"
                                              onClick={(e) =>
                                                this.deleteYearLevel(
                                                  e,
                                                  yearLevels.id,
                                                  el.id
                                                )
                                              }
                                              disabled={el.collapsible}
                                            >
                                              Delete
                                            </button>
                                          ) : null}
                                        </>
                                      )}
                                    </td>
                                  ) : null}
                                </tr>
                                {el.classrooms.length > 0
                                  ? el.classrooms.map((section, idx) => {
                                      if (idx > 0) {
                                        return (
                                          <tr>
                                            <td
                                              className="text-left"
                                              rowSpan={1}
                                            >
                                              <strong>{section.name}</strong> (
                                              {section.homeRoomTeacher})
                                            </td>
                                            <td
                                              className="text-left"
                                              rowSpan={1}
                                            >
                                              <p className="listedTeachers">
                                                {section
                                                  .subjectClassroomTeachers
                                                  .length > 0
                                                  ? section.subjectClassroomTeachers.map(
                                                      (teacher, teacherIdx) => {
                                                        return (
                                                          <span
                                                            key={teacherIdx}
                                                          >
                                                            <span>
                                                              {teacher.name}
                                                            </span>
                                                            <span>{", "}</span>
                                                          </span>
                                                        );
                                                      }
                                                    )
                                                  : `No ${localStorage.getItem(
                                                      "classroom"
                                                    )} teachers assigned`}
                                              </p>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })
                                  : null}
                                <div className="tt-divRow">
                                  <td colSpan="4">
                                    <CustomCollapse
                                      isOpen={el.collapsible}
                                      collapsibleBody={this.collapsibleYearLevel()}
                                    ></CustomCollapse>
                                  </td>
                                </div>
                              </React.Fragment>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan="4">
                            No {localStorage.getItem("yearLevel")} assigned
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                );
              })
            ) : (
              <div className="text-center" style={{ marginTop: "200px" }}>
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School Groupings"
              header={`List ${localStorage.getItem("yearLevel")}`}
              activityName="select-grade"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  detailYearLevels: state.academicAdministration.detailYearLevels,
  educationLevels: state.academicAdministration.educationLevels,
});

const mapActionsToProps = {
  getDetailYearLevels,
  getEducationLevels,
  getGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(ListYearLevel);
