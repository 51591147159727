//rebuilt this component with formik

import moment from "moment";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import fallbackSrc from "../../assets/images/fallbackimg.png";
import DragDropFile from "../../UI/DragDropFile";

class SchoolCreationModal extends Component {
  state = {
    accessKey: "",
    imagePreview: "",
    joinDate: "",
    name: "",
    photo: "",
    photoFile: "",
    spinner: false,
    url: null,
    serverUrl: "",
    code: "",
    provinceId: "",
    districtId: "",
    cityId: "",
    address: "",
    establishedOn: "",
    emailAddress: "",
    website: "",
    contactName: "",
    contactDOB: "",
    contactEmail: "",
    panVatNo: "",
    postalCode: "",
    contactNumber1: "",
    contactNumber2: "",
    provinces: [],
    districts: [],
    cities: [],
    acronym: "",
    serverUrl: null,
    countryId: 147,
    fieldErr: false,
    isTrail: false,
    trailPeriod: "",
    gender: "",
    username: "",
    contactMobileNumber: "",
  };

  componentDidMount() {
    this.getProvinces();
    if (this.props.edit) {
      this.config();
    }
  }

  getProvinces() {
    const Params = {
      countryId: 147,
    };
    axiosPost(URL.getCountryFirstLevel, Params, (response) => {
      if (response.status == 200) {
        this.setState({ provinces: response.data.data });
      }
    });
  }

  config = () => {
    if (this.props.selectedData) {
      let selectedData = this.props.selectedData;

      if (selectedData.countryFirstLevelId) {
        this.handleProvinceChange(null, selectedData.countryFirstLevelId);
      }

      if (selectedData.countrySecondLevelId) {
        this.handleDistrictChange(null, selectedData.countrySecondLevelId);
      }
      this.setState({
        code: selectedData.iemisCode,
        acronym: selectedData.acronym,
        imagePreview: URL.imageSelectURL + selectedData.imageName,
        accessKey: selectedData.accessKey,
        joinDate: selectedData.joinDate
          ? moment(selectedData.joinDate).format("YYYY-MM-DD")
          : "",
        name: selectedData.name,
        url: selectedData.url,
        provinceId: selectedData.countryFirstLevelId,
        emailAddress: selectedData.emailAddress,
        address: selectedData.address,
        establishedOn: selectedData.dateOfEstablishment
          ? moment(selectedData.dateOfEstablishment).format("YYYY-MM-DD")
          : "",
        website: selectedData.website,
        panVatNo: selectedData.panVatNo,
        postalCode: selectedData.postalCode,
        contactNumber1: selectedData.contactMobile1,
        contactNumber2: selectedData.contactMobile2,
        isSchoolEnabled: selectedData.isEnabled,
        contactEmail: selectedData.contactPeople?.emailAddress,
        contactName: selectedData.contactPeople?.name,
        gender: selectedData.contactPeople?.gender,
        contactMobileNumber: selectedData.contactPeople?.mobileNumber,
      });
    }
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleProvinceChange = (e, id) => {
    let param = {
      countryId: 147,
      countryFirstLevelId: (e && e.target.value) || id,
    };
    axiosPost(URL.getCountrySecondLevel, param, (response) => {
      if (response.status == 200) {
        this.setState({ districts: response.data.data }, () => {
          if (this.props.selectedData) {
            this.setState({
              districtId: this.props.selectedData.countrySecondLevelId,
            });
          }
        });
      }
    });
    this.setState({ provinceId: param.countryFirstLevelId });
  };
  handleDistrictChange = (e, id) => {
    let param = {
      countryId: 147,
      countryFirstLevelId:
        this.state.provinceId || this.props.selectedData.countryFirstLevelId,
      countrySecondLevelId: (e && e.target.value) || id,
    };
    axiosPost(URL.getCountryThirdLevel, param, (response) => {
      if (response.status === 200) {
        this.setState({ cities: response.data.data }, () => {
          if (this.props.selectedData) {
            this.setState({
              cityId: this.props.selectedData.countryThirdLevelId,
            });
          }
        });
      }
    });
    this.setState({ districtId: param.countrySecondLevelId });
  };

  handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          photo: e.target.value,
          photoFile: e.target.files[0],
          files: e.target.files,
        },
        function () {
          let image = window.URL.createObjectURL(this.state.photoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState(
        {
          photo: name,
          photoFile: files[0],
          files: files,
        },
        function () {
          let image = window.URL.createObjectURL(this.state.photoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSubmit = () => {
    let param = {
      name: this.state.name,
      url: this.state.url,
      joinDate: this.state.joinDate,
      // accessKey: this.state.accessKey,
      iemisCode: this.state.code,
      countryId: this.state.countryId,
      countryFirstLevelId: this.state.provinceId,
      countrySecondLevelId: this.state.districtId,
      countryThirdLevelId: this.state.cityId,
      address: this.state.address,
      dateOfEstablishment: this.state.establishedOn,
      website: this.state.website,
      emailAddress: this.state.emailAddress,
      panVatNo: this.state.panVatNo,
      postalCode: this.state.postalCode,
      contactMobile1: this.state.contactNumber1,
      contactMobile2: this.state.contactNumber2,
      acronym: this.state.acronym,
      serverUrl: this.state.serverUrl,
      // contactDOB:this.state.contactDOB,
      contactPeople: {
        name: this.state.contactName,
        emailAddress: this.state.contactEmail,
        gender: this.state.gender,
        mobileNumber: this.state.contactMobileNumber,
      },
    };

    // if (this.state.isTrail) {
    //   param.isTrail = this.state.isTrail;
    //   param.trailPeriod = this.state.trailPeriod;
    // }

    if (!this.validate(param)) {
      swal("Warning", "Fill all the mandatory fields");
      return false;
    }

    let formData = new FormData();
    if (this.state.photoFile !== "") {
      formData.append("file", this.state.photoFile);
    } else {
      // formData.append("file", null);
    }
    formData.append("school", JSON.stringify(param));
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.insertSchool,
        formData,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            swal("Success", "School data successfully stored");
            this.props.getAllSchools();
            this.props.toggleSchoolModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };
  validate(param) {
    if (
      param.name === "" ||
      param.iemisCode === "" ||
      param.emailAddress === "" ||
      // param.contactMobile1 === "" ||
      // param.countryThirdLevelId === "" ||
      // param.countrySecondLevelId === "" ||
      // param.countryFirstLevelId === "" ||
      // param.dateOfEstablishment === "" ||
      // param.website === "" ||
      param.joinDate === "" ||
      // param.address === "" ||
      // this.state.imagePreview === "" ||
      param.contactPeople.contactName === "" ||
      param.contactPeople.contactEmail === "" ||
      param.contactPeople.gender === "" ||
      param.contactPeople.contactMobileNumber === ""
    ) {
      this.setState({
        fieldErr: true,
      });
      return false;
    }
    // if (!this.props.edit && this.state.photoFile == "") {
    //   return false;
    // }
    return true;
  }
  handleUpdate = () => {
    let formData = new FormData();

    let param = {
      id: this.props.selectedData.id,
      name: this.state.name,
      url: this.state.url,
      joinDate: this.state.joinDate,
      // accessKey: this.state.accessKey,
      iemisCode: this.state.code,
      countryId: this.state.countryId,
      countryFirstLevelId: this.state.provinceId,
      countrySecondLevelId: this.state.districtId,
      countryThirdLevelId: this.state.cityId,
      address: this.state.address,
      dateOfEstablishment: this.state.establishedOn,
      website: this.state.website,
      emailAddress: this.state.emailAddress,
      panVatNo: this.state.panVatNo,
      postalCode: this.state.postalCode,
      contactMobile1: this.state.contactNumber1,
      contactMobile2: this.state.contactNumber2,
      acronym: this.state.acronym,
      serverUrl: this.state.serverUrl,
      isEnabled: this.state.isSchoolEnabled,
      contactPeople: {
        name: this.state.contactName,
        emailAddress: this.state.contactEmail,
        gender: this.state.gender,
        mobileNumber: this.state.contactMobileNumber,
      },
    };

    formData.append("jsonData", JSON.stringify(param));

    if (this.state.photoFile !== "") {
      formData.append("file", this.state.photoFile);
    }

    if (!this.validate(param)) {
      swal("Warning", "Fill all the mandatory fields");
      return false;
    }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.updateSchool,
        formData,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            swal("Success", "School data successfully updated");
            this.props.getAllSchools();
            this.props.toggleSchoolModal("");
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  removeImage = () => {
    this.setState({ photo: "", imagePreview: "", photoFile: "", files: [] });
  };

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: fallbackSrc,
        errored: true,
      });
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomSpinner text="Please Wait. Procesing ..."></CustomSpinner>
        ) : (
          ""
        )}

        <div className="row m-2">
          <div className="col-md-8">
            <p className="formTitle">
              School Logo{" "}
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <DragDropFile
              handleChange={this.handleImageChange}
              name="files"
              handleFileDrop={this.handleFileDrop}
              files={this.state.files}
              multiple={false}
              accept="image/png, image/jpeg, image/jpg"
            />
            {/* <input
              accept="image/png, image/jpeg, image/jpg"
              className="form-control"
              name="photo"
              onChange={this.handleImageChange}
              type="file"
              // value={this.state.photo}
            /> */}
          </div>
          {this.state.imagePreview && this.state.imagePreview !== "" ? (
            <div
              className="col-md-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="imageHolder"
                style={{ width: "90px", height: "90px" }}
              >
                <img
                  src={this.state.imagePreview}
                  alt="No preview available"
                  onError={this.onError}
                />
              </div>
              <div className="mt-2 text-center">
                <button
                  className="btn btn-danger cusBtnDanger cusBtnFontSize"
                  onClick={this.removeImage}
                >
                  Remove
                </button>
              </div>
            </div>
          ) : null}
        </div>

        <div className="row mt-2">
          <div className="col-md-4">
            <p className="formTitle">
              School Name{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              className={`${
                this.state.fieldErr && this.state.name == ""
                  ? "form-control border border-danger"
                  : "form-control"
              }`}
              placeholder="Enter School Name"
            />
          </div>
          <div className="col-md-4">
            <p className="formTitle">Acronym</p>

            <input
              type="text"
              name="acronym"
              value={this.state.acronym}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter School Acronym"
            />
          </div>
          <div className="col-md-4">
            <p className="formTitle">
              School Code{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="text"
              name="code"
              value={this.state.code}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter School Code"
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-4">
            <p className="formTitle">
              PAN/VAT No.{" "}
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>
            <input
              type="text"
              name="panVatNo"
              value={this.state.panVatNo}
              onChange={this.handleChange}
              placeholder="Enter PAN/VAT No."
              className={`${
                // this.state.fieldErr && this.state.panVatNo == ""
                //   ? "form-control border border-danger"
                //   :
                   "form-control"
              }`}
            />
          </div>
          <div className="col-md-4">
            <p className="formTitle">
              Website 
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>
            <input
              type="text"
              name="website"
              value={this.state.website}
              onChange={this.handleChange}
              placeholder="Enter School Website"
              className={`${
                // this.state.fieldErr && this.state.website == ""
                //   ? "form-control border border-danger"
                //   :
                   "form-control"
              }`}
            />
          </div>

          <div className="col-md-4">
            <p className="formTitle">
              Email Address{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="text"
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.handleChange}
              placeholder="Enter school email address"
              className={`${
                this.state.fieldErr && this.state.emailAddress == ""
                  ? "form-control border border-danger"
                  : 
                  "form-control"
              }`}
            />
          </div>
        </div>

        {/* <div className="row mt-2">
          <div className="col-md-4">
            <p className="formTitle">App URL *</p>
          </div>
          <div className="col">
            <input
              type="text"
              name="url"
              value={this.state.url}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter url for Imagine Sys"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <p className="formTitle">Server URL *</p>
          </div>
          <div className="col">
            <input
              type="text"
              name="serverUrl"
              value={this.state.serverUrl}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter url for backend instance"
            />
          </div>
        </div> */}
        {/* <div className="row mt-2">
          <div className="col-md-4">
            <p className="formTitle">Access Key</p>
          </div>
          <div className="col">
            <input
              type="text"
              name="accessKey"
              value={this.state.accessKey}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter access key for Imagine Sys"
            />
          </div>
        </div> */}
        <div className="row mt-2">
          <div className="col-md-4">
            <p className="formTitle">
              Province
               {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <select
              name="provinceId"
              value={this.state.provinceId}
              onChange={this.handleProvinceChange}
              className={`${
                // this.state.fieldErr && this.state.provinceId == ""
                //   ? "form-control border border-danger"
                //   : 
                  "form-control"
              }`}
            >
              <option value="" selected disabled>
                Choose a Province
              </option>
              {this.state.provinces
                ? this.state.provinces.map((province, idx) => {
                    return (
                      <option key={idx} value={province.id}>
                        {province.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col-md-4">
            <p className="formTitle">
              District 
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <select
              name="districtId"
              value={this.state.districtId}
              onChange={this.handleDistrictChange}
              className={`${
                // this.state.fieldErr && this.state.districtId == ""
                //   ? "form-control border border-danger"
                //   : 
                  "form-control"
              }`}
            >
              <option value="" selected disabled>
                Choose a District
              </option>
              {this.state.districts
                ? this.state.districts.map((district, idx) => {
                    return (
                      <option key={idx} value={district.id}>
                        {district.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>

          <div className="col-md-4">
            <p className="formTitle">
              City 
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <select
              name="cityId"
              value={this.state.cityId}
              onChange={this.handleChange}
              className={`${
                // this.state.fieldErr && this.state.cityId == ""
                //   ? "form-control border border-danger"
                //   : 
                  "form-control"
              }`}
            >
              <option value="" selected disabled>
                Choose a City
              </option>
              {this.state.cities
                ? this.state.cities.map((city, idx) => {
                    return (
                      <option key={idx} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-md-4">
            <p className="formTitle">
              Address
               {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <input
              type="text"
              name="address"
              value={this.state.address}
              onChange={this.handleChange}
              placeholder="Enter Address"
              className={`${
                // this.state.fieldErr && this.state.address == ""
                //   ? "form-control border border-danger"
                //   :
                   "form-control"
              }`}
            />
          </div>

          <div className="col-md-4">
            <p className="formTitle">
              Phone/Mobile{" "}
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <input
              type="text"
              name="contactNumber1"
              value={this.state.contactNumber1}
              onChange={this.handleChange}
              className={`${
                // this.state.fieldErr && this.state.contactNumber1 == ""
                //   ? "form-control border border-danger"
                //   :
                   "form-control"
              }`}
              placeholder="Enter Contact Phone / Mobile Number"
            />
          </div>
          {/* <div className="col-md-4">
            <p className="formTitle">Phone/Mobile</p>
            <input
              type="text"
              name="contactNumber2"
              value={this.state.contactNumber2}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter Contact Phone / Mobile Number"
            />
          </div> */}

          <div className="col-md-4">
            <p className="formTitle">
              Established on{" "}
              {/* <span className="tt-assessment-module-mandatory">*</span> */}
            </p>

            <input
              type="date"
              name="establishedOn"
              value={this.state.establishedOn}
              onChange={this.handleChange}
              placeholder="Enter date of establishment"
              className={`${
                // this.state.fieldErr && this.state.establishedOn == ""
                //   ? "form-control border border-danger"
                //   :
                   "form-control"
              }`}
            />
          </div>

          <div className="col-md-4">
            <p className="formTitle">Postal Code</p>
            <input
              type="text"
              name="postalCode"
              value={this.state.postalCode}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter Postal Code"
            />
          </div>
          <div className="col-md-4">
            <p className="formTitle">
              Join Date{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="date"
              name="joinDate"
              value={this.state.joinDate}
              onChange={this.handleChange}
              placeholder="Enter Joining Date"
              className={`${
                this.state.fieldErr && this.state.joinDate == ""
                  ? "form-control border border-danger"
                  : "form-control"
              }`}
            />
          </div>
        </div>

        <div className="mt-4">
          <p className="text-uppercase font-weight-bold">
            contact person details
          </p>
          <hr />
        </div>

        <div className="row">
          <div className="col-md-4">
            <p className="formTitle">
              Name <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="text"
              name="contactName"
              value={this.state.contactName}
              onChange={this.handleChange}
              className={`${
                this.state.fieldErr && this.state.contactName == ""
                  ? "form-control  border border-danger"
                  : "form-control "
              }`}
              placeholder="Enter Contact Name"
            />
          </div>

          <div className="col-md-4">
            <p className="formTitle">
              Email <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="text"
              name="contactEmail"
              value={this.state.contactEmail}
              onChange={this.handleChange}
              className={`${
                this.state.fieldErr && this.state.contactEmail == ""
                  ? "form-control  border border-danger"
                  : "form-control "
              }`}
              placeholder="Enter Contact Email"
            />
          </div>

          <div className="col-md-4">
            <p className="formTitle">
              Gender <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <select
              className={`${
                this.state.fieldErr && this.state.gender == ""
                  ? "form-control border border-danger"
                  : "form-control"
              }`}
              name="gender"
              value={this.state.gender}
              onChange={this.handleChange}
            >
              <option value="" selected disabled>
                Select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div className="col-md-4">
            <p className="formTitle">
              Phone/Mobile{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </p>

            <input
              type="text"
              name="contactMobileNumber"
              value={this.state.contactMobileNumber}
              onChange={this.handleChange}
              className={`${
                this.state.fieldErr && this.state.contactMobileNumber == ""
                  ? "form-control  border border-danger"
                  : "form-control "
              }`}
              placeholder="Enter contact number"
            />
          </div>

          {/* <div className="col-md-4">
            <p className="formTitle">Date of birth</p>
            <input
              type="date"
              name="contactDOB"
              value={this.state.contactDOB}
              onChange={this.handleChange}
              className="form-control "
            />
          </div> */}

          {/* {this.state.username !== "" && (
            <div className="col-md-4">
              <p className="formTitle">Username</p>
              <input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
                className="form-control "
                placeholder="Enter username"
              />
            </div>
          )} */}
        </div>
        {/* <div className="row">
          <div className="col-md-4">
            <p className="formTitle">Is Trail</p>
            <input
              type="checkbox"
              checked={this.state.isTrail}
              name="isTrail"
              onChange={this.handleChange}
            />
            <p></p>
          </div>
          {this.state.isTrail && (
            <div className="col-md-3">
              <p className="formTitle">Trail Period (in days)</p>
              <input
                type="number"
                name="trailPeriod"
                value={this.state.trailPeriod}
                onChange={this.handleChange}
                placeholder="Enter period in days"
                className={`${
                  this.state.fieldErr && this.state.trailPeriod == ""
                    ? "form-control border border-danger"
                    : "form-control"
                }`}
              />
            </div>
          )}
        </div> */}

        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.edit ? this.handleUpdate : this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolCreationModal;
