import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ProfilePage from "./components/Profile/ProfilePage";
import NotFound from "./components/Error Page/NotFound";
import LoginPage from "./components/Login/LoginPage";
import AcademicYears from "./components/AcademicAdministration/AcademicYears/AcademicYears";
import SystemSetup from "./components/SystemSetup/SystemSetup";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PrivateRouteMaster from "./components/PrivateRoute/PrivateRouteMaster";
import GroupPage from "./components/GroupPage/GroupPage";
import PermissionConfiguration from "./components/Permissions/PermissionConfiguration";
import FamilyPage from "./components/FamilyPage/FamilyPage";
import ArticleTemplate from "./components/Profile/Templates/ArticleTemplate";
import MediaTemplate from "./components/Profile/Templates/MediaTemplate";
import UserManager from "./components/Permissions/UserManager";
import { axiosPost } from "./utils/AxiosApi";
import { checkUserRole, URL } from "./utils/Constants";
import ARRManager from "./components/Permissions/ARRManager";
import ModerationManager from "./components/Permissions/ModerationManager";
import ChangePassword from "./components/Login/ChangePassword";
import ReceiptTemplate from "./components/Accounting/ReceiptTemplate";
import DiscussionTemplate from "./components/Profile/Templates/DiscussionTemplate";
import OTPpage from "./components/Login/OTPpage";
import SchoolLMS from "./components/SchoolLMS/SchoolLMS";
import Template3 from "./components/CardsAndCertificates/CardTemplates/Template3";
import AuthorizedPage from "./components/Login/authorizedPage";
import DefaultSettings from "./components/Permissions/DefaultSettings";
import FrontPage from "./components/FrontPage/FrontPage";
import LoginCentral from "./components/Login/LoginCentral";
import RegisterPage from "./components/Register/RegisterPage";
import DashboardTemplate from "./components/Dashboard/DashboardTemplate";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewGradePage from "./components/NewGradePage/NewGradePage";
import NewTeacherPage from "./components/NewTeacherPage/NewTeacherPage";
import history from "./utils/History";
import OtherActivities from "./components/OtherActivities/OtherActivities";
import SearchPage from "./components/SearchModule/SearchPage";
import Agriculture from "./components/TechnicalSchools/Agriculture";
import Civil from "./components/TechnicalSchools/Civil";
import Electrical from "./components/TechnicalSchools/Electrical";
import TechnicalSchool from "./components/TechnicalSchools/TechnicalSchool";
import AllNotices from "./pages/Notices/AllNotices";
import ProfileNotices from "./pages/Notices/ProfileNotices";
import CentralProfile from "./pages/Profile/CentralProfile";
import Dictionary from "./components/Dictionary/Dictionary";
import CustomCalculator from "./components/Calculator/CustomCalculator";
import CentralDiscussion from "./components/CentralDiscussion/CentralDiscussion";
import RelativeLinksPage from "./pages/RelativeLinks/RelativeLinksPage";
import privateLogin from "./components/Login/privateLogin";
import PrivateLogin from "./components/Login/privateLogin";
import SchoolManager from "./components/SchoolManager/SchoolManager";

class App extends Component {
  constructor() {
    super();
    let token = localStorage.getItem("token");

    let twoFAEnabled = localStorage.getItem("is2FAEnabled");

    if (twoFAEnabled === "true") {
      let otpVerified = localStorage.getItem("otpVerified");

      if (token) {
        this.state = {
          isLoggedIn: otpVerified && otpVerified == "true" ? true : false,
          systemSetup:
            localStorage.getItem("schoolSetup") === "true" ? true : false,
          academicYears:
            localStorage.getItem("academicYears") === "true" ? true : false,
          toggleOtp:
            localStorage.getItem("is2FAEnabled") === "true" ? true : false,
        };
      } else {
        this.state = {
          isLoggedIn: false,
          systemSetup:
            localStorage.getItem("schoolSetup") === "true" ? true : false,
          academicYears:
            localStorage.getItem("academicYears") === "true" ? true : false,
        };
      }
      return;
    } else {
      if (token) {
        this.state = {
          isLoggedIn: true,
          systemSetup:
            localStorage.getItem("schoolSetup") === "true" ? true : false,
          academicYears:
            localStorage.getItem("academicYears") === "true" ? true : false,
          toggleOtp:
            localStorage.getItem("is2FAEnabled") === "true" ? true : false,
        };
      } else {
        this.state = {
          isLoggedIn: false,
          systemSetup:
            localStorage.getItem("schoolSetup") === "true" ? true : false,
          academicYears:
            localStorage.getItem("academicYears") === "true" ? true : false,
        };
      }
    }
  }

  componentDidMount() {
    // this.state.isLoggedIn && this.checkSystemAcademicSetup();
    // this.getWebsiteConfig();
    this.setupBeforeUnloadListener();
  }

  // Setup the `beforeunload` event listener JS
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      // ev.preventDefault();
      return this.doSomethingBeforeUnload();
    });
  };

  doSomethingBeforeUnload = () => {
    if (localStorage.hasOwnProperty("webUserType")) {
      localStorage.removeItem("webUserType");
    }
  };

  getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          let favIcon = document.getElementById("favicon");
          let touchIcon = document.getElementById("touchIcon");
          favIcon.href = URL.imageSelectURL + response.data.data[0].faviconPath;
          touchIcon = URL.imageSelectURL + response.data.data[0].faviconPath;
        } else {
        }
      }
    });
  };

  checkSystemAcademicSetup = async () => {
    axiosPost(URL.checkSystemAcademicSetup, {}, (response) => {
      if (response.status === 200) {
        let systemSetup = response.data.data.systemSetup,
          academicYears = response.data.data.academicYearSetup;
        localStorage.setItem("schoolSetup", true);
        localStorage.setItem("academicYears", true);
        this.setState({ systemSetup, academicYears });
        // if (systemSetup && academicYear) {
        // } else if (systemSetup && !academicYear) {
        //   history.push(`${process.env.PUBLIC_URL}/academicYears`);
        // } else {
        //   history.push(`${process.env.PUBLIC_URL}/systemSetup`);
        // }
      }
    });
  };

  render() {
    return (
      <Switch>
        <PrivateRoute
          authed={this.state.isLoggedIn}
          path="/profile/:username"
          component={ProfilePage}
        />
        <PrivateRoute
          authed={this.state.isLoggedIn}
          path="/systemSetup"
          component={SystemSetup}
        />
        {/* <PrivateRoute
          authed={this.state.isLoggedIn}
          path={`/academicYears`}
          component={AcademicYears}
        /> */}
        <PrivateRoute
          authed={localStorage.getItem("token") ? true : false}
          path="/otp"
          component={this.state.isLoggedIn ? ProfilePage : OTPpage}
        />
        <Route
          path="/profile"
          component={
            this.state.isLoggedIn &&
            this.state.systemSetup &&
            this.state.academicYears
              ? ProfilePage
              : this.state.isLoggedIn && !this.state.systemSetup
              ? SystemSetup
              : this.state.isLoggedIn &&
                this.state.systemSetup &&
                !this.state.academicYears
              ? AcademicYears
              : PrivateLogin
          }
        />
        <Route
          path="/login"
          render={() => {
            if (
              this.state.isLoggedIn &&
              (localStorage.getItem("mainUserId") === "1" || checkUserRole())
            ) {
              history.push("/dashboard/main");
            } else if (this.state.isLoggedIn) {
              history.push("/profile");
            } else {
              return <PrivateLogin />;
            }
          }}
        />
        <Route
          path="/school-login"
          render={() => {
            if (
              this.state.isLoggedIn &&
              (localStorage.getItem("mainUserId") === "1" || checkUserRole())
            ) {
              history.push("/dashboard/main");
            } else if (this.state.isLoggedIn) {
              history.push("/profile");
            } else {
              return <PrivateLogin />;
            }
          }}
        />
        {/* <PrivateRoute
          path="/dashboard"
          authed={this.state.isLoggedIn}
          component={DashboardTemplate}
        /> */}
        <Route
          path="/"
          exact
          component={
            this.state.isLoggedIn &&
            this.state.systemSetup &&
            this.state.academicYears
              ? ProfilePage
              : this.state.isLoggedIn && !this.state.systemSetup
              ? SystemSetup
              : this.state.isLoggedIn &&
                this.state.systemSetup &&
                !this.state.academicYears
              ? AcademicYears
              : PrivateLogin
          }
        />
        <Route
          path="/home"
          exact
          component={
            this.state.isLoggedIn &&
            this.state.systemSetup &&
            this.state.academicYears
              ? ProfilePage
              : this.state.isLoggedIn && !this.state.systemSetup
              ? SystemSetup
              : this.state.isLoggedIn &&
                this.state.systemSetup &&
                !this.state.academicYears
              ? AcademicYears
              : PrivateLogin
          }
        />
        <PrivateRoute
          path="/permissionConfiguration"
          component={PermissionConfiguration}
          authed={this.state.isLoggedIn}
        />

        <PrivateRoute
          path="/school-manager"
          component={SchoolManager}
          authed={this.state.isLoggedIn}
        />

        <PrivateRoute
          path="/usermanager"
          component={UserManager}
          authed={this.state.isLoggedIn}
        />
        <PrivateRoute
          path="/arrmanager"
          component={ARRManager}
          authed={this.state.isLoggedIn}
        />
        <PrivateRoute
          path="/moderation_manager"
          component={ModerationManager}
          authed={this.state.isLoggedIn}
        />
        <PrivateRoute
          path="/default_settings"
          component={DefaultSettings}
          authed={this.state.isLoggedIn}
        />
        <PrivateRoute
          path="/moderation_manager"
          component={ModerationManager}
          authed={this.state.isLoggedIn}
        />
        <PrivateRoute
          path="/groupPage/:groupName"
          component={GroupPage}
          authed={this.state.isLoggedIn}
        />
        <PrivateRoute
          path="/search-imagine"
          component={SearchPage}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/dictionary"
          component={Dictionary}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/myprofile"
          component={CentralProfile}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/post/article/:referenceId"
          component={ArticleTemplate}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/post/discussion/:referenceId"
          component={DiscussionTemplate}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/post/media/:referenceId"
          component={MediaTemplate}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <Route path="/technical-schools" component={TechnicalSchool} />
        <PrivateRoute
          path="/familyPage/:familyId"
          component={FamilyPage}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        {/* <Route
          path="/reportingConfig"
          component={ReportingConfiguration}
        ></Route> */}
        {/* <Route path="/exam" component={SystemExamConfiguration}></Route> */}
        {/* <Route path="/takeexam" component={ExamForm}></Route> */}
        {/* <Route path="/assessexam" component={SystemExamAssessment}></Route> */}
        {/* <Route path="/attendance" component={Attendance}></Route> */}
        <Route path="/resetPassword/:userId" component={ChangePassword}></Route>
        <Route path="/slms" component={SchoolLMS}></Route>
        <Route path="/template1" component={ReceiptTemplate}></Route>
        <Route path="/signup" component={RegisterPage} />
        <Route path="/cardTemplate" component={Template3}></Route>
        {/* website routes student and teacher page  */}
        {/* <Route
          path="/newGradePage"
          render={() => <NewGradePage homePage={true} />}
        ></Route>
        <Route
          path="/newTeacherPage"
          render={() => <NewTeacherPage homePage={true} />}
        ></Route> */}
        {/* profile routes student and teacher */}
        {/* <PrivateRoute
          path="/student"
          component={NewGradePage}
          authed={true}
        ></PrivateRoute>
        <PrivateRoute
          path="/teacher"
          component={NewTeacherPage}
          authed={true}
        ></PrivateRoute>
        <PrivateRoute
          path="/otheractivities"
          component={OtherActivities}
          authed={true}
        ></PrivateRoute>
        <PrivateRoute
          path="/all-notices"
          component={AllNotices}
          authed={true}
        ></PrivateRoute>
        <PrivateRoute
          path="/discussion"
          component={CentralDiscussion}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/notices"
          component={ProfileNotices}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/customCalculator"
          component={CustomCalculator}
          authed={this.state.isLoggedIn}
        ></PrivateRoute>
        <PrivateRoute
          path="/relative-links"
          component={RelativeLinksPage}
          authed={this.state.isLoggedIn}
        ></PrivateRoute> */}
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    );
  }
}

export default withRouter(App);
