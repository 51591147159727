import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { URL, checkDevAdmin } from "../../utils/Constants";
import swal from "sweetalert";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../utils/Utils";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import CustomButton from "../common/CustomButton";
import CustomEditButton from "../common/CustomEditButton";

import SchoolCreationModal from "../../pages/SchoolManagement/SchoolCreationModal";
import ImportFile from "../../UI/ImportFile";
import SchoolEnableDisable from "../Dashboard/SchoolEnableDisable";
import Navbar from "../Profile/Navbar";
import Pagination from "../UI/Pagination";
import ModalWindow from "../UI/ModalWindow";

class SchoolManager extends Component {
  state = {
    pageSize: 7,
    currentPage: 1,
    totalRecords: 0,
    deletePermission: false,
    editPermission: false,
    editModal: false,
    getSpinner: false,
    modal: false,
    schools: [],
    selectedData: "",
    importModal: false,
    importFile: null,
    remarkModal: false,
    schoolHistoryData: [],
    dropdownOpen: false,
    credentialModal: false,
    schoolStatus: "",
    provinceId: "",
    cityId: "",
    searchName: "",
    provinces: [],
    districts: [],
    districtId: "",
    cities: [],
    credentialDetails: "",
    searchQuery: false,
  };

  componentDidMount() {
    this.getAllSchools(this.state.currentPage);
    this.getProvinces();
  }

  getProvinces() {
    const Params = {
      countryId: 147,
    };
    axiosPost(URL.getCountryFirstLevel, Params, (response) => {
      if (response.status == 200) {
        this.setState({ provinces: response.data.data });
      }
    });
  }

  handleProvinceChange = (e, id) => {
    let param = {
      countryId: 147,
      countryFirstLevelId: (e && e.target.value) || id,
    };
    axiosPost(URL.getCountrySecondLevel, param, (response) => {
      if (response.status == 200) {
        this.setState({ districts: response.data.data }, () => {
          if (this.props.selectedData) {
            this.setState({
              districtId: this.props.selectedData.countrySecondLevelId,
            });
          }
        });
      }
    });
    this.setState({ provinceId: param.countryFirstLevelId });
  };
  handleDistrictChange = (e, id) => {
    let param = {
      countryId: 147,
      countryFirstLevelId:
        this.state.provinceId || this.props.selectedData.countryFirstLevelId,
      countrySecondLevelId: (e && e.target.value) || id,
    };
    axiosPost(URL.getCountryThirdLevel, param, (response) => {
      if (response.status === 200) {
        this.setState({ cities: response.data.data }, () => {
          if (this.props.selectedData) {
            this.setState({
              cityId: this.props.selectedData.countryThirdLevelId,
            });
          }
        });
      }
    });
    this.setState({ districtId: param.countrySecondLevelId });
  };

  confirmDelete = (data) => {
    let param = {
      id: data.id,
    };

    axiosPost(URL.deleteSchool, param, (response) => {
      if (response.status === 200) {
        swal("Success", "Data successfully deleted");
        this.getAllSchools();
      }
    });
  };

  getAllSchools = (page = 1) => {
    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);

    let params = {
      offset: offset,
      rowCountLimit: this.state.pageSize,
      isAllowed: false,
    };
    this.setState({ getSpinner: true, schools: [] }, function () {
      axiosPost(
        URL.getAllSchools,
        params,
        (response) => {
          this.setState({ getSpinner: false });
          if (response.status === 200) {
            this.setState({
              getSpinner: false,
              currentPage: page,
              totalRecords: response.data.totalRecordsCount,
              schools: response.data.data,
              // credentialDetails: "",
            });
          }
        },
        (err) => {
          this.setState({ getSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleSchoolDetele = (id) => {
    displayConfirmDeleteAlert({ id }, this.confirmDelete);
  };

  toggleSchoolModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleSchoolEditModal = (data) => {
    this.setState({ editModal: !this.state.editModal, selectedData: data });
  };

  handleImportClick = () => {
    this.setState({
      importModal: true,
    });
  };

  toggleImportModal = () => {
    this.setState({
      importModal: !this.state.importModal,
      importFile: null,
    });
  };

  handleImport = () => {
    let formData = new FormData();
    if (this.state.importFile !== null) {
      formData.append("file", this.state.importFile[0]);

      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.importSchool,
            formData,
            (response) => {
              if (response.status === 200) {
                swal("Success", response.data.message);
                this.toggleImportModal();
                this.setState({
                  spinner: false,
                });

                this.getAllSchools(this.state.currentPage);
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    } else {
      swal("Error", "Cannot import empty file");
    }
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      isEnabled: checked,
    };
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.udpateStatus,
          data,
          (response) => {
            if (response.status === 200) {
              this.setState({
                spinner: false,
              });
              if (checked) {
                this.setState(
                  {
                    credentialDetails: response.data.message || "",
                  },
                  () => {
                    this.toggleCredentialModal();
                  }
                );
              }

              if (this.state.searchQuery) {
                this.handleSearchData(this.state.currentPage);
              } else {
                this.getAllSchools(this.state.currentPage);
              }
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleChangeImport = (e) => {
    this.setState({
      importFile: e.target.files[0],
    });
  };

  handleChangeSearch = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleGenerateFormat = () => {
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosGet(
          URL.generateSchoolImportFormat,
          (response) => {
            if (response.status === 200) {
              swal("Success", "School import format generated successfully.");
              this.setState({
                spinner: false,
              });
              window.open(
                URL.fileDownloadURL + response.data.data.path,
                "_blank"
              );
              // this.getAllSchools();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleHistory = (data) => {
    if (data) {
      let param = {
        schoolId: data.id,
      };
      axiosPost(
        URL.getSchoolHistory,
        param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              schoolHistoryData: response.data.data,
              remarkModal: !this.state.remarkModal,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    }
  };

  closeRemarkModal = () => {
    this.setState({ remarkModal: false });
  };

  toggleDisableDisplay = (id) => {
    let param = {
      schoolId: id,
    };
    axiosPost(
      URL.getSchoolHistory,
      param,
      (response) => {
        if (response.status === 200) {
          let datum = response.data.data;
          if (datum.length > 0 && !datum[datum.length - 1].status) {
            let text =
              "This school has been disabled by " +
              datum[datum.length - 1].peopleName +
              " on " +
              moment(datum[datum.length - 1].createdAt).format("LL") +
              ".";
            swal("Info", text);
          } else {
            swal("Info", "This school has been disabled");
          }
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };
  handleImageChange = (e, idx) => {
    let { name, value, files } = e.target;
    this.setState({
      importFile: name === "importFile" ? files : value,
    });
  };
  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState({
        [name]: files,
      });
    }
  };

  //for nav bar
  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  toggleCredentialModal = () => {
    this.setState({
      credentialModal: !this.state.credentialModal,
    });
  };

  handlePageChange = (page) => {
    if (this.state.searchQuery) {
      this.handleSearchData(page);
    } else {
      this.getAllSchools(page);
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      if (this.state.searchQuery) {
        this.handleSearchData(nextPage);
      } else {
        this.getAllSchools(nextPage);
      }
    }
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1) {
      let prevPage = this.state.currentPage - 1;

      if (this.state.searchQuery) {
        this.handleSearchData(prevPage);
      } else {
        this.getAllSchools(prevPage);
      }
    }
  };

  handleSearchData = (page = 1) => {
    let searchParam = {
      name: this.state.searchName,
      countryFirstLevelId: this.state.provinceId,
      countrySecondLevelId: this.state.districtId,
      countryThirdLevelId: this.state.cityId,
      isEnabled:
        this.state.schoolStatus === "enabled"
          ? true
          : this.state.schoolStatus === "disabled"
          ? false
          : "",
    };

    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);

    searchParam.offset = offset;
    searchParam.rowCountLimit = this.state.pageSize;
    searchParam.isAllowed = false;

    this.setState({ getSpinner: true, searchQuery: true }, function () {
      axiosPost(
        URL.searchSchool,
        searchParam,
        (response) => {
          this.setState({
            getSpinner: false,
            currentPage: page,
            totalRecords: response.data.totalRecordsCount,
          });
          if (response.status === 200) {
            this.setState({ schools: response.data.data });
          }
        },
        (err) => {
          this.setState({ getSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleResetClick = (page) => {
    this.setState({
      cityId: "",
      provinceId: "",
      searchName: "",
      schoolStatus: "",

      districts: [],
      districtId: "",
      cities: [],
      cityId: "",
      searchQuery: false,
    });

    this.getAllSchools(page);
  };

  render() {
    const startingSerialNumber =
      (this.state.currentPage - 1) * this.state.pageSize + 1;

    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait.
            </div>
          </div>
        ) : null}
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        />
        <div
          className="container-fluid tt-postTabs tt-inner-content tt-userSetting mt-3"
          style={{ height: "88vh" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className="text-start mb-3">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.toggleSchoolModal}
                  >
                    Add new school
                  </button>
                  <button
                    onClick={this.handleImportClick}
                    className="tt-button tt-button-primary"
                  >
                    Import
                  </button>
                </div>

                <div className="px-3 py-2 mb-2 search-box-school">
                  <div className="row">
                    <div className="col-md-3">
                      <p className="formTitle">School Name or Code</p>
                      <input
                        type="text"
                        name="searchName"
                        value={this.state.searchName}
                        className="form-control "
                        onChange={this.handleChangeSearch}
                        placeholder="Enter School name or Code"
                      />
                    </div>

                    <div className={checkDevAdmin() ? "col-md-2" : "col-md-3"}>
                      <p className="formTitle">Province</p>
                      <select
                        name="provinceId"
                        value={this.state.provinceId}
                        onChange={this.handleProvinceChange}
                        className="form-control "
                      >
                        <option value="" selected disabled>
                          Choose a Province
                        </option>
                        {this.state.provinces
                          ? this.state.provinces.map((province, idx) => {
                              return (
                                <option key={idx} value={province.id}>
                                  {province.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className={checkDevAdmin() ? "col-md-2" : "col-md-3"}>
                      <p className="formTitle">District</p>
                      <select
                        name="districtId"
                        value={this.state.districtId}
                        onChange={this.handleDistrictChange}
                        className="form-control "
                      >
                        <option value="" selected disabled>
                          Choose a District
                        </option>
                        {this.state.districts
                          ? this.state.districts.map((district, idx) => {
                              return (
                                <option key={idx} value={district.id}>
                                  {district.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className={checkDevAdmin() ? "col-md-2" : "col-md-3"}>
                      <p className="formTitle">City</p>
                      <select
                        name="cityId"
                        value={this.state.cityId}
                        onChange={this.handleChangeSearch}
                        className="form-control "
                      >
                        <option value="" selected disabled>
                          Choose a City
                        </option>
                        {this.state.cities
                          ? this.state.cities.map((city, idx) => {
                              return (
                                <option key={idx} value={city.id}>
                                  {city.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>

                    {checkDevAdmin() && (
                      <div className="col-md-3">
                        <p className="formTitle">Status</p>
                        <div className="d-flex align-items-center h-50">
                          <input
                            type="radio"
                            name="schoolStatus"
                            checked={this.state.schoolStatus === "enabled"}
                            value="enabled"
                            onChange={this.handleChangeSearch}
                          />
                          <label htmlFor="enabled" className="mr-2">
                            Enabled
                          </label>

                          <input
                            type="radio"
                            name="schoolStatus"
                            checked={this.state.schoolStatus === "disabled"}
                            value="disabled"
                            onChange={this.handleChangeSearch}
                          />
                          <label htmlFor="disabled" className="mr-2">
                            Disabled
                          </label>

                          <input
                            type="radio"
                            name="schoolStatus"
                            checked={this.state.schoolStatus === ""}
                            value=""
                            onChange={this.handleChangeSearch}
                          />
                          <label htmlFor="both">Any</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <button
                      onClick={() => this.handleSearchData()}
                      className="tt-button tt-button-primary"
                    >
                      Search
                    </button>
                    <button
                      onClick={() => this.handleResetClick(1)}
                      className="tt-button tt-button-primary"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <table className="table table-bordered text-center table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <th>S.N.</th>
                      <th>Logo</th>
                      <th>School Code</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Join Date</th>
                      <th>Website</th>
                      <th>Status</th>
                      <th width="80px">History</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.schools.length > 0 ? (
                      this.state.schools.map((school, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{startingSerialNumber + idx}.</td>
                            <td className="text-center">
                              {school.imageName ? (
                                <img
                                  className="userImage"
                                  src={URL.imageSelectURL + school.imageName}
                                  alt={school.name}
                                />
                              ) : null}
                            </td>
                            <td>{school.iemisCode}</td>

                            <td>{school.name}</td>
                            <td>
                            {school.address !== "" && school.address !== undefined ?
                            school.address 
                            + ",":
                            ""
                            }
                            {school.countryThirdLevelName !== undefined ?
                            school.countryThirdLevelName 
                            + ",":
                            ""
                            }
                             {school.countrySecondLevelName !== undefined ?
                            school.countrySecondLevelName 
                            + ",":
                            ""
                            }
                             {school.countryFirstLevelName !== undefined ?
                            school.countryFirstLevelName 
                            + ",":
                            ""
                            }
                             {school.countryName !== undefined ?
                            school.countryName 
                            :
                            ""
                            }

                            </td>
                            <td>{moment(school.joinDate).format("ll")}</td>
                            <td>{school.website}</td>

                            <td>
                              <label className="switch mt-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.handleChangeToggle(e, school)
                                  }
                                  checked={
                                    school.isEnabled ? school.isEnabled : false
                                  }
                                  name="enabled"
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>

                            <td>
                              <button
                                className="btn btn-primary cusBtn mt-1"
                                onClick={() => this.handleHistory(school)}
                                style={{ fontSize: "12px" }}
                              >
                                View
                              </button>
                            </td>

                            <td>
                              {/* {this.state.editPermission ? ( */}
                              <>
                                <CustomEditButton
                                  onClick={() =>
                                    this.toggleSchoolEditModal(school)
                                  }
                                />
                                {/* |{" "} */}
                              </>
                              {/* ) : null} */}
                              {school.isEnabled ? null : (
                                <span
                                  className="material-icons-round actionIcon primaryColor"
                                  // onClick={props.onClick}
                                  onClick={() =>
                                    this.toggleDisableDisplay(school.id)
                                  }
                                >
                                  info
                                </span>
                              )}
                              {/* {this.state.deletePermission ? (
                                <CustomDeleteButton
                                  onClick={() =>
                                    this.handleSchoolDetele(school.id)
                                  }
                                />
                              ) : null} */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            this.state.deletePermission ||
                            this.state.editPermission
                              ? 7
                              : 8
                          }
                          className="text-center"
                        >
                          {this.state.getSpinner ? (
                            <Spinner></Spinner>
                          ) : (
                            "No schools added"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Pagination
                    itemsCount={this.state.totalRecords}
                    pageSize={this.state.pageSize}
                    currentPage={this.state.currentPage}
                    onPageChange={this.handlePageChange}
                    nextPage={this.handleNextPage}
                    previousPage={this.handlePreviousPage}
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>
          <ModalWindow
            size="xl"
            modal={this.state.modal}
            modalHeader="Add New School"
            backdrop="static"
            keyboard={false}
            modalBody={
              <SchoolCreationModal
                getAllSchools={this.getAllSchools}
                toggleSchoolModal={this.toggleSchoolModal}
              />
            }
            toggleModal={this.toggleSchoolModal}
          />
          <ModalWindow
            modal={this.state.editModal}
            size="xl"
            modalHeader="Edit School Details"
            modalBody={
              <SchoolCreationModal
                edit={true}
                getAllSchools={this.getAllSchools}
                selectedData={this.state.selectedData}
                toggleSchoolModal={this.toggleSchoolEditModal}
              />
            }
            toggleModal={this.toggleSchoolEditModal}
          />

          <ModalWindow
            modal={this.state.importModal}
            size="lg"
            modalHeader="Import School Data"
            modalBody={
              <ImportFile
                handleChange={this.handleImageChange}
                handleGenerateFormat={this.handleGenerateFormat}
                handleImport={this.handleImport}
                handleFileDrop={this.handleFileDrop}
                importFile={this.state.importFile}
              />
            }
            toggleModal={this.toggleImportModal}
          />
          <ModalWindow
            modal={this.state.remarkModal}
            size="lg"
            modalHeader="School Enable/Disable History"
            toggleModal={this.closeRemarkModal}
            modalBody={
              <SchoolEnableDisable
                schoolHistoryData={this.state.schoolHistoryData}
              />
            }
          />

          <ModalWindow
            modal={this.state.credentialModal}
            size="lg"
            modalHeader="School status changed."
            toggleModal={this.toggleCredentialModal}
            modalBody={
              <div>
                <p>The school has been enabled successfully.</p>
                {this.state.credentialDetails}
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default SchoolManager;
