import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../assets/scss/coverPicture.scss";
import "../../assets/scss/ResponsiveScss/responsiveNavbarPage.scss";
import {
  faImage,
  faMinus,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadCoverForm from "../Upload/UploadCoverForm";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  userRoleCode,
  userRole,
  mainUserId,
  draggable,
  checkRolePermissions,
  postType,
  modules,
} from "../../utils/Constants";
import loginImage from "../../assets/images/loginImage.png";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";
import FullImage from "./FullImage";
import { displayConfirmDeleteAlert, showErroMessage } from "../../utils/Utils";
import UserDetail from "./UserDetail";
import { connect } from "react-redux";
import {
  getNotices,
  getGroupNotices,
  getFamilyNotices,
  getNoticeSpinner,
} from "../../ducks/PostsDucks";

class CoverPicture extends Component {
  state = {
    modal: false,
    id: "",
    tempImages: [],
    coverPictures: [],
    imageModal: false,
    fullImageCaption: "",
    selectedImage: "",
    imagePreviewModal: "",
    editCaption: "",
    updateIndex: "",
    systemDefault: false,
    initial: 0,
    highlightPersonalButton: false,
    highlightSystemButton: false,
    imageClicked: false,
    updateButtonDisplay: true,
    activeSliderIndex: 0,
    showSpinner: false,
    spinner: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      if (this.props.groupPage) {
        if (this.props.familyPage) {
          this.getFamilyCoverPictures();
        } else {
          this.getGroupCoverPictures();
        }
      } else {
        this.getCoverPictures();
      }
    }
  }
  componentDidMount() {
    if (this.props.groupPage) {
      if (this.props.familyPage) {
        this.getFamilyCoverPictures();
      } else {
        this.getGroupCoverPictures();
      }
    } else {
      this.getCoverPictures();
    }
  }

  getGroupCoverPictures = () => {
    let data = {
      id: localStorage.getItem("groupId"),
    };
    this.setState(
      { spinner: true, coverPictures: [{ src: "", caption: "", image: "" }] },
      function () {
        axiosPost(
          URL.getGroupCoverPictures,
          data,
          (response) => {
            if (response.status === 200 && response.data.data.length > 0) {
              let images = response.data.data;
              let coverPictures = [];
              if (images.length > 0) {
                images.forEach((image) => {
                  coverPictures.push({
                    id: image.id,
                    caption: image.caption !== null ? image.caption : "",
                    src: `${URL.imageSelectURL + image?.name}`,
                    image: `${URL.imageSelectURL + image?.name}`,
                    isNew: false,
                  });
                });
              }
              this.setState(
                {
                  coverPictures,
                  systemDefault: false,
                  highlightPersonalButton: true,
                  highlightSystemButton: false,
                  activeSliderIndex: 0,
                  spinner: false,
                },
                function () {
                  if (this.props.minimize === false) {
                    let images = document.getElementsByClassName(
                      "tt-slider-content"
                    );
                    if (images.length > 0) {
                      for (let i = 0; i < images.length; i++) {
                        images[i].classList.remove(
                          "tt-slider-content-minimize"
                        );
                      }
                    }
                  }
                }
              );
            } else {
              this.systemDefaultImageLoader();
            }
          },
          (failure) => {
            this.setState({ spinner: false });
          }
        );
      }
    );
  };

  getFamilyCoverPictures = () => {
    let data = {
      id: this.props.familyId,
    };
    this.setState(
      { spinner: true, coverPictures: [{ src: "", caption: "", image: "" }] },
      function () {
        axiosPost(
          URL.getFamilyCoverPictures,
          data,
          (response) => {
            if (response.status === 200 && response.data.data.length > 0) {
              let images = response.data.data;
              let coverPictures = [];
              images.forEach((image) => {
                coverPictures.push({
                  id: image.id,
                  caption: image.caption !== null ? image.caption : "",
                  src: `${URL.imageSelectURL + image?.name}`,
                  image: `${URL.imageSelectURL + image?.name}`,
                  isNew: false,
                });
              });
              this.setState(
                {
                  coverPictures,
                  systemDefault: false,
                  highlightPersonalButton: true,
                  highlightSystemButton: false,
                  activeSliderIndex: 0,
                  spinner: false,
                },
                function () {
                  if (this.props.minimize === false) {
                    let images = document.getElementsByClassName(
                      "tt-slider-content"
                    );
                    if (images.length > 0) {
                      for (let i = 0; i < images.length; i++) {
                        images[i].classList.remove(
                          "tt-slider-content-minimize"
                        );
                      }
                    }
                  }
                }
              );
            } else {
              this.systemDefaultImageLoader();
            }
          },
          (failure) => {
            this.setState({ spinner: false });
          }
        );
      }
    );
  };

  scrollEnd = () => {
    var elmnt = document.getElementById("addImage");
    elmnt.scrollIntoView();
  };

  //Getting Cover Pictures from User

  getCoverPictures = () => {
    let data = {
      id: this.props.userId,
    };
    this.setState(
      { spinner: true, coverPictures: [{ src: "", caption: "", image: "" }] },
      function () {
        axiosPost(
          URL.getCoverPictures,
          data,
          (response) => {
            if (response.status === 200 && response.data.data.length > 0) {
              let images = response.data.data;
              let coverPictures = [];
              images.forEach((image) => {
                coverPictures.push({
                  id: image.id,
                  caption: image.caption !== null ? image.caption : "",
                  src: `${URL.imageSelectURL + image?.name}`,
                  image: `${URL.imageSelectURL + image?.name}`,
                  isNew: false,
                });
              });
              this.setState(
                {
                  coverPictures,
                  systemDefault: false,
                  highlightPersonalButton: true,
                  highlightSystemButton: false,
                  activeSliderIndex: 0,
                  spinner: false,
                },
                function () {
                  if (this.props.minimize === false) {
                    let images = document.getElementsByClassName(
                      "tt-slider-content"
                    );
                    if (images.length > 0) {
                      for (let i = 0; i < images.length; i++) {
                        images[i].classList.remove(
                          "tt-slider-content-minimize"
                        );
                      }
                    }
                  }
                }
              );
            } else {
              this.systemDefaultImageLoader();
            }
          },
          (failure) => {
            this.setState({ spinner: false });
          }
        );
      }
    );
  };

  getPersonalCoverPicture = (action) => {
    let data = {
      id: this.props.userId,
    };
    this.setState(
      { spinner: true, coverPictures: [{ src: "", caption: "", image: "" }] },
      function () {
        axiosPost(
          URL.getCoverPictures,
          data,
          (response) => {
            if (response.status === 200) {
              let images = response.data.data;
              let coverPictures = [];
              if (images.length > 0) {
                images.forEach((image) => {
                  coverPictures.push({
                    id: image.id,
                    caption: image.caption !== null ? image.caption : "",
                    src: `${URL.imageSelectURL + image?.name}`,
                    image: `${URL.imageSelectURL + image?.name}`,
                    isNew: false,
                  });
                });
              } else {
                coverPictures.push({
                  id: "",
                  caption: "I am default caption",
                  src: loginImage,
                  image: loginImage,
                  isNew: false,
                });
              }

              this.setState(
                {
                  coverPictures,
                  systemDefault: false,
                  highlightPersonalButton: true,
                  highlightSystemButton: false,
                  spinner: false,
                  activeSliderIndex: 0,
                },
                () => {
                  if (action === "ChangeImage") {
                    let images = document.getElementsByClassName(
                      "tt-slider-content"
                    );
                    if (images.length > 0) {
                      for (let i = 0; i < images.length; i++) {
                        images[i].classList.remove(
                          "tt-slider-content-minimize"
                        );
                      }
                    }
                  }
                }
              );
            }
          },
          (failure) => {
            this.setState({ spinner: false });
          }
        );
      }
    );
  };

  systemDefaultImageLoader = (action) => {
    this.setState(
      { spinner: true, coverPictures: [{ src: "", caption: "", image: "" }] },
      function () {
        axiosPost(
          URL.getSystemDefaultImage,
          {},
          (response) => {
            if (response.status === 200 && response.data.data !== null) {
              let images = response.data.data;
              let coverPictures = [];
              if (images.length > 0) {
                images.forEach((image) => {
                  coverPictures.push({
                    id: image.id,
                    caption: image.caption !== null ? image.caption : "",
                    src: `${URL.imageSelectURL + image?.name}`,
                    image: `${URL.imageSelectURL + image?.name}`,
                    isNew: false,
                  });
                });
              } else {
                coverPictures.push({
                  id: "",
                  caption: "I am default caption",
                  src: loginImage,
                  image: loginImage,
                  isNew: false,
                });
              }

              this.setState(
                {
                  coverPictures,
                  systemDefault:
                    userRole === userRoleCode.roleAdmin ? true : false,
                  highlightPersonalButton: false,
                  highlightSystemButton: true,
                  activeSliderIndex: 0,
                  spinner: false,
                },
                () => {
                  if (action === "ChangeImage") {
                    let images = document.getElementsByClassName(
                      "tt-slider-content"
                    );
                    if (images.length > 0) {
                      for (let i = 0; i < images.length; i++) {
                        images[i].classList.remove(
                          "tt-slider-content-minimize"
                        );
                      }
                    }
                  }
                  if (this.props.minimize === false) {
                    let images = document.getElementsByClassName(
                      "tt-slider-content"
                    );
                    if (images.length > 0) {
                      for (let i = 0; i < images.length; i++) {
                        images[i].classList.remove(
                          "tt-slider-content-minimize"
                        );
                      }
                    }
                  }
                }
              );
            }
          },
          (failure) => {
            this.setState({ spinner: false });
          }
        );
      }
    );
  };

  //Cover Picture Editor
  coverModal = () => {
    let role = userRole;
    if (this.props.groupPage) {
      this.getCoverPictureForUpdate();
    } else {
      let peopleId = localStorage.getItem("peopleId");
      let profilePeopleId = this.props.profilePeopleId;
      if (role === userRoleCode.roleAdmin && peopleId === profilePeopleId) {
        let coverPictures = [...this.state.coverPictures];
        if (coverPictures.length > 0) {
          this.setState({
            imagePreviewModal: coverPictures[this.state.activeSliderIndex],
            editCaption: coverPictures[this.state.activeSliderIndex].caption,
            updateButtonDisplay:
              coverPictures[this.state.activeSliderIndex].isNew,
            imageClicked: true,
            updateIndex: this.state.activeSliderIndex,
          });
        } else {
          this.setState({ imageClicked: false });
        }
        this.setState({
          modal: !this.state.modal,
          tempImages: coverPictures,
        });
      } else {
        this.getCoverPictureForUpdate();
      }
    }
  };

  getCoverPictureForUpdate = () => {
    let data = {};
    let url = "";
    if (this.props.groupPage) {
      if (this.props.familyPage) {
        data.id = this.props.familyId;
        url = URL.getFamilyCoverPictures;
      } else {
        data.id = localStorage.getItem("groupId");
        url = URL.getGroupCoverPictures;
      }
    } else {
      data.id = this.props.userId;
      url = URL.getCoverPictures;
    }
    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        let images = response.data.data;
        let coverPictures = [];
        if (images.length > 0) {
          images.forEach((image) => {
            coverPictures.push({
              id: image.id,
              caption: image.caption !== null ? image.caption : "",
              src: `${URL.imageSelectURL + image?.name}`,
              image: `${URL.imageSelectURL + image?.name}`,
              isNew: false,
            });
          });
        }
        if (coverPictures.length > 0) {
          this.setState({
            imagePreviewModal: coverPictures[this.state.activeSliderIndex],
            editCaption: coverPictures[this.state.activeSliderIndex].caption,
            imageClicked: true,
            updateButtonDisplay:
              coverPictures[this.state.activeSliderIndex].isNew,
            updateIndex: this.state.activeSliderIndex,
          });
        } else {
          this.setState({ imageClicked: false });
        }
        this.setState({
          tempImages: coverPictures,
          systemDefault: false,
          modal: !this.state.modal,
        });
      }
    });
  };

  closeCoverPictureModal = () => {
    let coverPics = this.state.tempImages;
    let count = 0;
    if (coverPics.length > 0) {
      coverPics.forEach((el) => {
        if (el.isNew) {
          count++;
        }
      });
    }

    if (count === 0) {
      this.setState({
        modal: false,
        tempImages: [],
        editCaption: "",
        imagePreviewModal: "",
        imageClicked: false,
      });
    } else {
      swal({
        title: "Warning",
        text:
          "There are unsaved changes in this modal, do you want to close anyway?",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.setState({
            modal: false,
            tempImages: [],
            editCaption: "",
            imagePreviewModal: "",
            imageClicked: false,
            activeSliderIndex: 0,
          });
          if (this.props.groupPage) {
            if (this.props.familyPage) {
              this.getFamilyCoverPictures();
            } else {
              this.getGroupCoverPictures();
            }
          } else {
            this.getCoverPictures();
          }
        }
      });
    }
  };

  handleChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      let coverPic = [...this.state.tempImages];
      coverPic[idx].caption = value;
      this.setState({ tempImages: coverPic });
    });
  };

  handleImageChange = (e) => {
    debugger;
    e.preventDefault();
    let data = e.target.files;
    let tempImages = [...this.state.tempImages];
    let totalFileSize = 0;
    for (let i = 0; i < data.length; i++) {
      totalFileSize += data[i].size;
    }
    if (totalFileSize > 25 * 1024 * 1024) {
      swal({
        title: "Error",
        text: "Uploaded files should be less than 25 MB.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return;
    }
    if (tempImages.length >= 5 || data.length >= 5) {
      swal({
        title: "Error",
        text: "Cover Picture exceeds its limit",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return;
    }

    for (let i = 0; i < data.length; i++) {
      tempImages.push({
        src: window.URL.createObjectURL(data[i]),
        caption: "",
        image: data[i],
        isNew: true,
      });
    }
    this.setState({ tempImages: tempImages }, function () {
      let selectedImage = tempImages[tempImages.length - 1];
      let thumbnailId = `coverPicture-` + (tempImages.length - 1);
      let selectedThumbnail = document.getElementById(thumbnailId);
      let allThumbnail = document.getElementsByClassName("coverThumbnail");
      if (allThumbnail.length > 0) {
        for (let i = 0; i < allThumbnail.length; i++) {
          allThumbnail[i].classList.remove("selectedThumbnail");
        }
      }
      selectedThumbnail.classList.add("selectedThumbnail");
      this.setState({
        imagePreviewModal: selectedImage,
        editCaption: selectedImage.caption,
        updateButtonDisplay: selectedImage.isNew,
        imageClicked: true,
        updateIndex: tempImages.length - 1,
      });
      this.scrollEnd();
    });
  };

  imagePreview = (e, image, idx) => {
    let thumbnailId = `coverPicture-` + idx;
    let selectedThumbnail = document.getElementById(thumbnailId);
    let allThumbnail = document.getElementsByClassName("coverThumbnail");
    if (allThumbnail.length > 0) {
      for (let i = 0; i < allThumbnail.length; i++) {
        allThumbnail[i].classList.remove("selectedThumbnail");
      }
    }
    selectedThumbnail.classList.add("selectedThumbnail");
    this.setState({
      imagePreviewModal: image,
      editCaption: image.caption,
      updateButtonDisplay: image.isNew,
      updateIndex: idx,
      imageClicked: true,
    });
  };

  deleteImageContent = (e, idx) => {
    e.preventDefault();
    let tempImages = [...this.state.tempImages];
    if (tempImages[idx].id && tempImages[idx] !== null) {
      displayConfirmDeleteAlert(idx, this.deleteImageFromDatabase);
    } else {
      this.setState(
        {
          imagePreviewModal: "",
          editCaption: "",
          updateIndex: "",
          imageClicked: false,
          updateButtonDisplay: true,
        },
        function () {
          tempImages.splice(idx, 1);
          this.setState({
            tempImages,
          });
        }
      );
    }
  };

  deleteImageFromDatabase = (idx) => {
    let tempImages = [...this.state.tempImages];
    let data = {
      id: tempImages[idx].id,
    };
    let url = "";
    if (this.props.groupPage) {
      if (this.props.familyPage) {
        url = URL.deleteFamilyCoverPictures;
      } else {
        url = URL.deleteGroupCoverPictures;
      }
    } else {
      url = this.state.systemDefault
        ? URL.deleteSystemDefaultImage
        : URL.deleteCoverPicture;
    }
    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        if (tempImages[idx].id === this.state.imagePreviewModal.id) {
          this.setState({ imagePreviewModal: "", editCaption: "" });
        }
        tempImages.splice(idx, 1);
        this.setState({ tempImages });
        swal({
          title: `Success`,
          text: "Deleted Succesfully",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        if (this.props.groupPage) {
          if (this.props.familyPage) {
            this.getFamilyCoverPictures();
          } else {
            this.getGroupCoverPictures();
          }
        } else {
          this.getCoverPictures();
        }
      } else {
        swal({
          title: `Error - ${response.status}`,
          text: response.message,
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      }
    });
  };

  uploadCoverPicture = (e) => {
    e.preventDefault();
    let coverPics = this.state.tempImages;
    let count = 0;

    if (coverPics.length > 0 && coverPics.length <= 5) {
      var formData = new FormData();
      var captions = [];
      for (const key of Object.keys(this.state.tempImages)) {
        if (this.props.groupPage) {
          if (this.props.familyPage) {
            if (coverPics[key].isNew) {
              count++;
              formData.append("files", coverPics[key].image);
              captions.push({
                caption: coverPics[key].caption,
                familyId: this.props.family.id,
              });
            }
          } else {
            if (coverPics[key].isNew) {
              count++;
              formData.append("files", coverPics[key].image);
              captions.push({
                caption: coverPics[key].caption,
                groupId: localStorage.getItem("groupId"),
              });
            }
          }
        } else {
          if (coverPics[key].isNew) {
            count++;
            formData.append("files", coverPics[key].image);
            captions.push({
              caption: coverPics[key].caption,
            });
          }
        }
      }
      formData.append("captions", JSON.stringify(captions));

      let url = "";
      if (this.props.groupPage) {
        if (this.props.familyPage) {
          url = URL.insertFamilyCoverPictures;
        } else {
          url = URL.insertGroupCoverPictures;
        }
      } else {
        // let peopleId = localStorage.getItem("profilePeopleId");
        let peopleId = this.props.profilePeopleId;
        formData.append("peopleId", peopleId);
        if (userRole === userRoleCode.roleAdmin) {
          url = this.state.systemDefault
            ? URL.insertSystemDefaultPictures
            : URL.insertCoverPictures;
        } else {
          url = URL.insertCoverPictures;
        }
      }

      // if (coverPics.length > 5) {
      //   swal({
      //     title: "Error",
      //     text: "Cover Picture exceeds its limit",
      //     closeOnClickOutside: false,
      //     allowOutsideClick: false,
      //   });
      //   draggable();
      // }

      if (count === 0) {
        swal({
          title: "Error",
          text:
            "No new picture is added. Please add a cover picture and click upload",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        //add module code for moderation
        formData.append("moduleCode", modules["Cover Picture"]);
        this.setState({ showSpinner: true }, function () {
          axiosPost(
            url,
            formData,
            (response) => {
              if (response.status === 200) {
                swal("Success", "Cover Picture added successfully", "");
                this.setState({
                  modal: !this.state.modal,
                  imageClicked: false,
                  showSpinner: false,
                  editCaption: "",
                  imagePreviewModal: "",
                  activeSliderIndex: 0,
                });
                if (this.props.groupPage) {
                  if (this.props.familyPage) {
                    this.getFamilyCoverPictures();
                  } else {
                    this.getGroupCoverPictures();
                  }
                } else {
                  this.getCoverPictures();
                }
                this.callNoticeFunctions();
                //   this.props.minimizeCoverToggle();
              }
            },
            (failure) => {
              swal({
                title: "Error - " + failure.response.data.status,
                text: failure.response.data.message,
              });
              this.setState({ showSpinner: false });
            }
          );
        });
      }
    } else {
      swal("Error", "No pictures selected", "");
    }
  };

  //For Notice Reload
  callNoticeFunctions = () => {
    if (this.props.familyPage) {
      this.familyNoticeConfig();
    } else if (this.props.groupPage) {
      this.groupNoticeConfig();
    } else {
      this.personalNoticeConfig();
    }
  };

  groupNoticeConfig = () => {
    let initialData = this.props.groupName;
    let groupName = initialData.replace(/_/g, " ");
    let param = {
      groupName: groupName,
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    this.props.getGroupNotices(param);
  };

  familyNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    let familyId = this.props.familyId;
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      param.familyId = familyId;
      this.props.getFamilyNotices(param);
    } else {
      this.getFamilyDetails(param, familyId);
    }
  };

  getFamilyDetails = (param, familyId) => {
    let data = {
      familyId: familyId,
    };
    let count = 0;
    axiosPost(URL.selectFamilyMembers, data, (response) => {
      if (response.status === 200) {
        let peopleId = localStorage.getItem("peopleId");
        let datas = response.data.data;
        if (datas.length > 0) {
          datas.forEach((el) => {
            if (el.peopleId === parseInt(peopleId)) {
              count++;
            }
          });
        }
        if (count === 0) {
          param.peopleId = localStorage.getItem("peopleId");
          this.props.getNotices(param);
        } else {
          param.familyId = familyId;
          this.props.getFamilyNotices(param);
        }
      }
    });
  };

  personalNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      if (!this.props.username) {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      } else {
        let data = {
          username: this.props.username,
        };
        axiosPost(URL.getLoggedInUser, data, (response) => {
          if (response.status === 200) {
            param.peopleId = response.data.data.peopleId;
            this.props.getNotices(param);
          }
        });
      }
    } else {
      if (userRole === userRoleCode.roleStudentContact) {
        axiosPost(
          URL.selectFamilyMembers,
          { familyId: localStorage.getItem("familyId") },
          (response) => {
            if (response.status === 200) {
              let profilePeopleId = this.props.profilePeopleId;
              let count = 0;
              if (response.data.data.length > 0) {
                response.data.data.forEach((el) => {
                  if (el.peopleId === parseInt(profilePeopleId)) {
                    count++;
                  }
                });
              }
              if (count > 0) {
                param.peopleId = profilePeopleId;
                this.props.getNotices(param);
              }
            }
          }
        );
      } else {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      }
    }
  };

  handleCoverPictureUpdate = (e) => {
    e.preventDefault();
    let url = "";
    if (this.props.groupPage) {
      if (this.props.familyPage) {
        url = URL.updateMultipleFamilyCoverPictures;
      } else {
        url = URL.updateMultipleGroupCoverPictures;
      }
    } else {
      url = URL.coverPictureMultipleUpdate;
    }
    axiosPost(url, this.state.tempImages, (response) => {
      if (response.status === 200) {
        swal("Success", "Cover picture successfully updated");
      } else {
        showErroMessage(response);
      }
    });
  };

  //Modal for full view of Image
  toggleImageModal = (e, coverPic) => {
    if (coverPic !== undefined) {
      this.setState({
        selectedImage: coverPic.src,
        fullImageCaption: coverPic.caption,
      });
    }
    this.setState({ imageModal: !this.state.imageModal });
  };

  sliderIndexValue = (idx) => {
    this.setState({ activeSliderIndex: idx });
  };

  //Cover Picture Edit Modal Body and Footer
  modalFooter = () => {
    return (
      <div className="row text-right">
        <button
          onClick={this.uploadCoverPicture}
          className="tt-button tt-button-primary mr-3"
        >
          Upload
        </button>
        <button
          onClick={this.closeCoverPictureModal}
          className="tt-button tt-button-primary"
        >
          Close
        </button>
      </div>
    );
  };

  editCoverModalBody = (e) => {
    return (
      <UploadCoverForm
        tempImages={this.state.tempImages}
        handleImageChange={this.handleImageChange}
        deleteImageContent={this.deleteImageContent}
        handleChange={this.handleChange}
        editCaption={this.state.editCaption}
        imagePreview={this.imagePreview}
        imagePreviewModal={this.state.imagePreviewModal}
        updateIndex={this.state.updateIndex}
        scrollEnd={this.scrollEnd}
        handleCoverPictureUpdate={this.handleCoverPictureUpdate}
        imageClicked={this.state.imageClicked}
        updateButtonDisplay={this.state.updateButtonDisplay}
        activeSliderIndex={this.state.activeSliderIndex}
        showSpinner={this.state.showSpinner}
      ></UploadCoverForm>
    );
  };

  handleError = (e, id) => {
    e.preventDefault();
    let newCoverPics = [...this.state.coverPictures];
    if (newCoverPics.length > 0) {
      const index = newCoverPics.findIndex((coverPic) => coverPic.id === id);
      if (newCoverPics.length === 1) {
        if (index !== -1) {
          newCoverPics[index].src = loginImage;
          newCoverPics[index].caption = "";
        }
      } else {
        index !== -1 && newCoverPics.splice(index, 1);
      }
      this.setState({ coverPictures: newCoverPics });
    }
  };

  render() {
    const settings = {
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      afterChange: (index) => this.sliderIndexValue(index),
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    return (
      <>
        <div className="container" id="widget-cover-picture">
          <div
            className="tt-minimize expand-coverPic"
            onClick={this.props.minimizeCoverToggle}
          >
            <FontAwesomeIcon
              icon={this.props.minimize ? faPlus : faMinus}
            ></FontAwesomeIcon>
          </div>
          <div
            id="tt-coverImage"
            className="tt-cover-image"
            onMouseEnter={() => this.props.toggleHover("cover", "show")}
            onMouseLeave={() => this.props.toggleHover("cover", "hide")}
          >
            <Slider {...settings}>
              {this.state.coverPictures.length >= 1 && !this.state.spinner ? (
                this.state.coverPictures.map((coverPic, idx) => {
                  return (
                    <div
                      key={idx}
                      className="tt-slider-content tt-slider-content-minimize"
                    >
                      {/* eslint-disable-next-line */}
                      <img
                        alt="cover-picture"
                        src={coverPic.src}
                        onError={(e) => this.handleError(e, coverPic.id)}
                        onClick={(e) => this.toggleImageModal(e, coverPic)}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="tt-slider-content tt-slider-content-minimize">
                  {this.state.spinner ? (
                    <div style={{ fontSize: "14px", marginTop: "40px" }}>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Cover Photo...
                    </div>
                  ) : null}
                </div>
              )}
            </Slider>

            {parseInt(this.props.userId) !== parseInt(mainUserId) ? (
              checkRolePermissions("can-update-personal-cp", "activity") ||
              this.props.isLegalGuardian ? (
                <div
                  className="tt-editButton"
                  id="tt-editButton"
                  onClick={this.coverModal}
                  style={{ top: "10%" }}
                >
                  <FontAwesomeIcon icon={faImage}></FontAwesomeIcon> Cover
                  Picture Editor
                </div>
              ) : null
            ) : (
              <>
                {userRole !== userRoleCode.roleStudent &&
                userRole !== userRoleCode.roleStaff &&
                userRole !== userRoleCode.roleStudentContact ? (
                  <>
                    <div
                      className={
                        this.state.highlightSystemButton
                          ? "tt-systemDefault tt-highlight-button"
                          : "tt-systemDefault"
                      }
                      id="tt-systemDefault"
                      onClick={() => {
                        this.systemDefaultImageLoader("ChangeImage");
                        // this.props.minimizeCoverToggle();
                      }}
                    >
                      System default
                    </div>
                    <div
                      className={
                        this.state.highlightPersonalButton
                          ? "tt-personalChange tt-highlight-button"
                          : "tt-personalChange"
                      }
                      id="tt-personalChange"
                      onClick={() => {
                        this.getPersonalCoverPicture("ChangeImage");
                        // this.props.minimizeCoverToggle();
                      }}
                    >
                      Personal change
                    </div>
                    <div
                      className="tt-editButton"
                      id="tt-editButton"
                      onClick={this.coverModal}
                    >
                      <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                      &nbsp;Cover Picture Editor
                    </div>
                  </>
                ) : (
                  <div
                    className="tt-editButton"
                    id="tt-editButton"
                    onClick={this.coverModal}
                    style={{ top: "10%" }}
                  >
                    <FontAwesomeIcon icon={faImage}></FontAwesomeIcon> Cover
                    Picture Editor
                  </div>
                )}
              </>
            )}

            {this.props.groupPage ? (
              this.props.familyPage ? (
                this.props.isLegal ||
                checkRolePermissions("can-update-family-cp", "activity") ? (
                  <div
                    className="tt-editButton"
                    id="tt-editButton"
                    onClick={this.coverModal}
                    style={{ top: "10%" }}
                  >
                    <FontAwesomeIcon icon={faImage}></FontAwesomeIcon> Cover
                    Picture Editor
                  </div>
                ) : null
              ) : this.props.isLegal ||
                checkRolePermissions("can-update-group-cp", "activity") ? (
                <div
                  className="tt-editButton"
                  id="tt-editButton"
                  onClick={this.coverModal}
                  style={{ top: "10%" }}
                >
                  <FontAwesomeIcon icon={faImage}></FontAwesomeIcon> Cover
                  Picture Editor
                </div>
              ) : null
            ) : null}

            <Modal
              isOpen={this.state.modal}
              toggle={this.closeCoverPictureModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="md"
              className="coverModalPrev"
            >
              <ModalHeader
                className="tt-group-header"
                toggle={this.closeCoverPictureModal}
              >
                <div>Cover Picture Editor</div>
              </ModalHeader>
              <ModalBody>{this.editCoverModalBody()}</ModalBody>
              <ModalFooter>{this.modalFooter()}</ModalFooter>
            </Modal>
            <Modal
              id="fullProfileImage"
              isOpen={this.state.imageModal}
              toggle={this.toggleImageModal}
              centered
              className="tt-profileFullImage"
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="tt-ImageCloseIcon"
                onClick={this.toggleImageModal}
              ></FontAwesomeIcon>
              <ModalBody>
                <FullImage
                  image={this.state.selectedImage}
                  caption={this.state.fullImageCaption}
                ></FullImage>
              </ModalBody>
            </Modal>

            {this.props.familyPage ? (
              <UserDetail
                familyPage={this.props.familyPage}
                family={this.props.family}
                minimize={this.props.minimize}
              ></UserDetail>
            ) : this.props.groupPage ? (
              <UserDetail
                groupData={this.props.groupData}
                minimize={this.props.minimize}
                groupPage={this.props.groupPage}
              ></UserDetail>
            ) : (
              <UserDetail
                userRole={this.props.userRole}
                userData={this.props.userData}
                minimize={this.props.minimize}
                profilePeopleId={this.props.profilePeopleId}
              ></UserDetail>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => ({});

const mapActionToProps = {
  getNotices,
  getGroupNotices,
  getFamilyNotices,
  getNoticeSpinner,
};
export default connect(mapStateToProps, mapActionToProps)(CoverPicture);
