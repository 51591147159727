import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../utils/Constants";
import ClassRoutineFormat from "./ClassRoutineFormat";
import ModalWindow from "../UI/ModalWindow";
import swal from "sweetalert";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";
import { compareByName } from "../../utils/Utils";
export default class CreateClassRoutine extends Component {
  state = {
    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: null,

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    teachers: [],

    addCourseModal: "",
    insertedRoutine: null,
    permissionModal: false,
  };

  componentDidMount = () => {
    this.getEducationLevels();
    this.getTeachers();
    // this.getSubjects();
    this.checkInsertedRoutine();
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
            selectedEducationLevelId: data[0].id,
          });
      }
    });
  };

  getTeachers = () => {
    axiosPost(URL.getSubjectTeachers, {}, (response) => {
      if (response.status == 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            teachers: data,
          });
      }
    });
  };

  getSubjects = () => {
    let data = this.state.selectedClassrooms;
    axiosPost(URL.getSubjectByClassroom, { data }, (response) => {
      if (response.status == 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
          });
      }
    });
  };

  handleEducationLevelChange = (e) => {
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: "",

          classrooms: [],
          selectedClassrooms: "",

          subjects: [],
          selectedSubjects: "",
          selectedEducationLevelId: e.target.value,
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState({ selectedYearLevel: e.target.value });
    var data = {
      yearLevelId: e.target.value,
    };
    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.data.status === 200) {
        const datas = response.data.data;
        this.setState({
          classrooms: datas,
          selectedClassrooms: "",
          selectedSubjects: "",
        });
      }
    });

    axiosPost(URL.getSubjectForPlanner, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
            selectedSubjects: data[0].id,
          });
      }
    });
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value });
  };

  handleSubjects = (e) => {
    this.setState({
      selectedSubjects: e.target.value,
    });
  };

  checkInsertedRoutine = () => {
    axiosPost(URL.getClassRoutine, {}, (response) => {
      if (response.data.status === 200) {
        let data = [...response.data.data];
        this.setState({ insertedRoutine: data });
      }
    });
  };

  handleAddCourse = () => {
    const checkEduYearLevel = this.state.insertedRoutine.find(
      (iR) =>
        iR.educationLevelId === parseInt(this.state.selectedEducationLevelId) &&
        iR.yearlevelId === parseInt(this.state.selectedYearLevel)
    );

    const checkEduYearClass = this.state.insertedRoutine.find(
      (iR) =>
        iR.educationLevelId === parseInt(this.state.selectedEducationLevelId) &&
        iR.yearlevelId === parseInt(this.state.selectedYearLevel) &&
        iR.classroomId === parseInt(this.state.selectedClassrooms)
    );

    if (
      this.state.selectedEducationLevelId &&
      this.state.selectedYearLevel &&
      this.state.selectedClassrooms === ""
    ) {
      if (this.state.insertedRoutine && checkEduYearLevel) {
        swal(
          "Message",
          "Class Routine already exist-You can view it on List Class Routine",
          ""
        );
      } else {
        this.setState({ addCourseModal: !this.state.addCourseModal });
      }
    } else if (
      this.state.selectedEducationLevelId &&
      this.state.selectedYearLevel &&
      this.state.selectedClassrooms
    ) {
      if (
        this.state.insertedRoutine &&
        checkEduYearLevel &&
        checkEduYearClass
      ) {
        swal(
          "Message",
          "Class Routine already exist-You can view it on List Class Routine",
          ""
        );
      } else {
        this.setState({ addCourseModal: !this.state.addCourseModal });
      }
    }
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Create Class Routine
            {checkWidgetAdminOrNot("Class Routine") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-3 col-md">
                  <strong>Education Level</strong>
                  <select
                    className="form-control"
                    name="educationLevel"
                    // value={this.state.gradeId}
                    onChange={this.handleEducationLevelChange}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select education level
                    </option>
                    {this.state.educationLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Class</strong>
                  <select
                    className="form-control"
                    name="yearLevel"
                    value={
                      this.state.selectedYearLevel
                        ? this.state.selectedYearLevel
                        : ""
                    }
                    onChange={this.handleYearLevel}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select class
                    </option>
                    {this.state.yearLevels
                      // .sort(compareByName)
                      .map((sl, idx) => {
                        return <option value={sl.id}>Class {sl.name}</option>;
                      })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Section</strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    value={this.state.selectedClassrooms}
                    onChange={this.handleSectionChange}
                  >
                    <option value="" selected disabled>
                      Select section
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md-2">
                  <button
                    className="mt-4 mx-auto d-block tt-button tt-button-primary"
                    onClick={() => this.handleAddCourse("routine")}
                    disabled={
                      this.state.selectedClassrooms === "" ? true : false
                    }
                  >
                    Add Routine
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          <ModalWindow
            modal={this.state.addCourseModal}
            toggleModal={this.toggleAddCourseModal}
            fullWidth={true}
            modalHeader="Add Class Routine"
            modalBody={
              <ClassRoutineFormat
                selectedEducationLevelId={this.state.selectedEducationLevelId}
                condition="create"
                selectedYearLevel={this.state.selectedYearLevel}
                selectedClassrooms={this.state.selectedClassrooms}
                selectedSubjects={this.state.selectedSubjects}
                subjects={this.state.subjects}
                teachers={this.state.teachers}
                academicRelated={true}
                toggleAddCourseModal={this.toggleAddCourseModal}
              ></ClassRoutineFormat>
            }
          ></ModalWindow>
        }

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Class Routine"
              moduleName="Class Routine"
              header="Add Class Routine configuration"
              activityName="insert-class-routine"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}
