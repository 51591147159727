import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { compareByName } from "../../utils/Utils";

const animatedComponents = makeAnimated();
class CreateMCQHeader extends Component {
  state = {};
  render() {
    // let classes = this.props.classes.sort(compareByName);
    let classes = this.props.classes;

    let subjectList = this.props.subjectList;
    const objectiveQuestion = this.props.objectiveQuestion;
    return (
      <>
        <div className="">
          <div className="row">
            <div
              className="col-md-2 text-right mt-1"
              style={{ maxWidth: "12%" }}
            >
              <strong>
                {objectiveQuestion ? "Question title" : "MCQ Title:"}{" "}
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                placeholder="Enter Topic"
                name="topic"
                value={this.props.topic}
                onChange={this.props.handleChange}
                className={
                  this.props.central ? "form-control cusInput" : "form-control"
                }
              ></input>
            </div>
            <div className="col-md-1 text-right mt-1">
              <strong>
                Class: <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-2">
              <select
                className={
                  this.props.central
                    ? "form-control cusInputSelect"
                    : "form-control"
                }
                name="classId"
                value={this.props.classId}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select Class
                </option>
                {classes.map((el, idx) => {
                  return (
                    <option value={el.id} key={idx}>
                      Class {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>
                Subject:{" "}
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-3">
              <select
                className={
                  this.props.central
                    ? "form-control cusInputSelect"
                    : "form-control"
                }
                name="subjectId"
                value={this.props.subjectId}
                onChange={this.props.handleChange}
                disabled={this.props.classId === "" ? true : false}
              >
                <option value="" disabled>
                  Select Subject
                </option>
                {subjectList.map((sl, idx) => {
                  return <option value={sl.id}>{sl.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div
              className="col-md-2 text-right mt-1"
              style={{ maxWidth: "12%" }}
            >
              <strong>
                Select Target:{" "}
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-4">
              <Select
                closeMenuOnSelect={false}
                isClearable={true}
                value={this.props.selectedGroups}
                components={animatedComponents}
                isMulti
                options={this.props.groupsArray}
                placeholder="Choose Target Groups"
                name="colors"
                onChange={this.props.handleChangeSelect}
                menuPlacement="auto"
              />
            </div>
            <div className="col-md-4">
              <Select
                closeMenuOnSelect={false}
                isClearable={true}
                value={this.props.selectedIndividual}
                components={animatedComponents}
                isMulti
                options={this.props.peopleArray}
                placeholder="Choose Individual People"
                name="people"
                onChange={this.props.handleIndividualSelect}
                menuPlacement="auto"
                isLoading={
                  this.props.peopleArray.length > 0 || !this.props.peopleSpinner
                    ? false
                    : true
                }
              />
            </div>
            <div className="col-md-2">
              <input
                type="number"
                placeholder="Full Marks"
                name="fullMarks"
                value={this.props.fullMarks}
                onChange={this.props.handleChange}
                className={
                  this.props.central ? "form-control cusInput" : "form-control"
                }
              ></input>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateMCQHeader;
