import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import "../../assets/scss/newGradeCss.scss";
import LibraryContent from "./LibraryContent";
import defaultSubjectImage from "../../assets/images/defaultSubject.svg";
import { compareByName } from "../../utils/Utils";

class ELibrary extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeEduLevel: null,
    classes: [],
    courseList: [],
    educationLevel: [],
    grade: null,
    selectedSubject: null,
    subjectList: [],
    coursePlanSelected: false,
    resources: null,
    fetching: false,
    gamesToggle: false,
    readingMaterials: false,
    mcqBox: false,
    flashCardBox: false,
    memoryGameBox: false,
  };

  componentDidMount() {
    this.getAllEducationList();
  }

  getAllEducationList = () => {
    axiosPost(URL.getAllEducationLevel, {}, (response) => {
      if (response.status === 200) {
        const educationLevels = response.data.data;
        let yearLevels = [];
        if (educationLevels[0] && educationLevels[0].yearLevels) {
          educationLevels.forEach((el) => {
            if (el.yearLevels.length > 0) {
              el.yearLevels.forEach((c) => {
                yearLevels.push(c);
              });
            }
          });
          this.setState(
            {
              grade: educationLevels[0].yearLevels[0],
              activeEduLevel: educationLevels[0].name,
              classes: yearLevels,
            },
            function () {
              this.getSubjectList();
            }
          );
        }
        this.setState({ educationLevel: response.data.data });
      }
    });
  };

  getSubjectList = () => {
    let datum = this.state.grade;
    let param = {
      yearLevelId: datum?.id,
    };
    const api = this.props.schoolProfile
      ? URL.selectDistinctSubjectsByYearLevelCoreForAcademic
      : URL.selectDistinctSubjectsByYearLevelCore;
    axiosPost(api, param, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
      }
    });
  };

  goBack = () => {
    if (this.state.selectedSubject && this.state.coursePlanSelected) {
      this.setState({ coursePlanSelected: false });
    } else if (this.state.selectedSubject && this.state.lmsToggled) {
      this.setState({ lmsToggled: false });
    } else if (
      this.state.selectedSubject &&
      this.state.gamesToggle &&
      this.state.mcqBox
    ) {
      this.setState({ mcqBox: false });
    } else if (
      this.state.selectedSubject &&
      this.state.gamesToggle &&
      this.state.flashCardBox
    ) {
      this.setState({ flashCardBox: false });
    } else if (
      this.state.selectedSubject &&
      this.state.gamesToggle &&
      this.state.memoryGameBox
    ) {
      this.setState({ memoryGameBox: false });
    } else if (this.state.selectedSubject && this.state.gamesToggle) {
      this.setState({ gamesToggle: false });
    } else if (this.state.selectedSubject && this.state.readingMaterials) {
      this.setState({
        readingMaterials: false,
      });
    } else {
      this.setState({
        resources: null,
        selectedSubject: null,
        readingMaterials: false,
        gamesToggle: false,
        flashCardBox: false,
        mcqBox: false,
        memoryGameBox: false,
        coursePlanSelected: false,
      });
    }
  };

  selectGrade = (data) => {
    this.setState(
      {
        selectedSubject: null,
        resources: null,
        coursePlanSelected: false,
        readingMaterials: false,
        gamesToggle: false,
        flashCardBox: false,
        memoryGameBox: false,
        mcqBox: false,
      },
      function () {
        this.setState({ grade: data }, function () {
          this.getSubjectList();
        });
      }
    );
  };

  selectSubject = (data) => {
    this.setState({
      selectedSubject: data,
      resources: null,
      readingMaterials: false,
      gamesToggle: false,
      flashCardBox: false,
      memoryGameBox: false,
      mcqBox: false,
      coursePlanSelected: false,
    });
  };

  toggleLMS = (type) => {
    const typeMappings = {
      curriculum: 1,
      textbooks: 2,
      "question-bank": 3,
      "av-resource": 4,
      "sign-language": 5,
      "audio-resources": 6,
    };

    const schoolLmsTypeId = typeMappings[type] || "";
    if (schoolLmsTypeId) {
      this.getLMSResources(schoolLmsTypeId);
    }
  };

  getLMSResources = (schoolLmsTypeId) => {
    const { grade, selectedSubject } = this.state;
    const param = {
      schoolLmsTypeId,
      yearlevelId: grade.id,
      subjectId: selectedSubject.id,
    };

    this.setState({ fetching: true, lmsToggled: true }, () => {
      axiosPost(
        URL.getSchoolLMS,
        param,
        (response) => {
          if (response.status === 200) {
            this.setState({ fetching: false, resources: response.data.data });
          } else {
            this.setState({ fetching: false });
          }
        },
        (err) => {
          this.setState({ fetching: false });
        }
      );
    });
  };

  //This is a toggle function that sends a POST request to URL.getCoursePlanCore with parameters present in the object "param".
  // It takes the response object, extract data from it, and applies a filter on it to obtain class list named as "cl".
  // Once the class-list is obtained, it sets the react state objects "courseList" and "coursePlanSelected" respectively.
  toggleFeatureSelect = () => {
    let param = {
      yearlevelId: this.state.grade.id,
      subjectId: this.state.selectedSubject.id,
    };
    axiosPost(URL.getCoursePlanCore, param, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        let cl = data.filter(
          (d) =>
            d.subjectId === this.state.selectedSubject.id &&
            d.classId === this.state.grade.id
        );

        this.setState({
          courseList: cl,
          coursePlanSelected: true,
        });
      }
    });
  };

  toggleMemoryGame = () => {
    this.setState({ memoryGameBox: !this.state.memoryGameBox });
  };

  toggleGames = () => {
    this.setState({
      gamesToggle: true,
    });
  };

  toggleReadingMaterials = () => {
    this.setState({
      readingMaterials: true,
    });
  };

  toggleMCQ = () => {
    this.setState({ mcqBox: !this.state.mcqBox });
  };

  toggleFlashCards = () => {
    this.setState({ flashCardBox: !this.state.flashCardBox });
  };

  render() {
    const { subjectList } = this.state;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "84.5vh" }}>
        <section>
          <div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="main-heading py-2">
                    <div className="d-flex justify-content-center">
                      {/* <h2 className="heading-06">Classes:</h2> */}
                      {!!this.state.educationLevel?.length ? (
                        <h2 className="heading-06" style={{ fontWeight: 550 }}>
                          &nbsp;
                          {this.state.educationLevel.map((edu, idx) => {
                            return (
                              <span key={idx}>
                                {edu.name}{" "}
                                {idx === this.state.educationLevel.length - 1
                                  ? ""
                                  : "| "}
                              </span>
                            );
                          })}
                        </h2>
                      ) : null}
                    </div>
                    {/* <div
                      className="horizontal-line"
                      style={{ width: "100px" }}
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="my-2">
          <div className="gradeClassList">
            {this.state.classes.length > 0
              ? this.state.classes
              // .sort(compareByName)
              .map((cls, idx) => {
                  return (
                    <div
                      key={idx}
                      className={
                        cls.name === this.state.grade.name
                          ? "activeGradeClass"
                          : ""
                      }
                      onClick={() => this.selectGrade(cls)}
                    >
                      <span>{cls.name.replace("Class ", "")}</span>
                    </div>
                  );
                })
              : null}
          </div>
        </section>
        <section className="my-2">
          <hr></hr>
          {this.state.selectedSubject ? (
            <LibraryContent
              goBack={this.goBack}
              selectedSubject={this.state.selectedSubject}
              resources={this.state.resources}
              coursePlanSelected={this.state.coursePlanSelected}
              toggleLMS={this.toggleLMS}
              subjectList={this.state.subjectList}
              selectSubject={this.selectSubject}
              toggleGames={this.toggleGames}
              gamesToggle={this.state.gamesToggle}
              toggleReadingMaterials={this.toggleReadingMaterials}
              readingMaterials={this.state.readingMaterials}
              toggleMCQ={this.toggleMCQ}
              toggleFlashCards={this.toggleFlashCards}
              mcqBox={this.state.mcqBox}
              flashCardBox={this.state.flashCardBox}
              grade={this.state.grade}
              toggleFeatureSelect={this.toggleFeatureSelect}
              courseList={this.state.courseList}
              toggleMemoryGame={this.toggleMemoryGame}
              memoryGameBox={this.state.memoryGameBox}
              lmsToggled={this.state.lmsToggled}
            />
          ) : this.state.educationLevel.length > 0 &&
            this.state.classes.length > 0 ? (
            <div className="subjectClassList">
              {subjectList.length ? (
                subjectList.map((sub, idx) => {
                  const { thumbnailPath, name } = sub;
                  return (
                    <div
                      key={idx}
                      className="subjectBoxDiv"
                      onClick={() => this.selectSubject(sub)}
                    >
                      {thumbnailPath && thumbnailPath !== "" ? (
                        <div className="gradeImgHolder">
                          <img
                            src={URL.imageSelectURL + thumbnailPath}
                            onError={(e) =>
                              (e.target.src = defaultSubjectImage)
                            }
                          />
                        </div>
                      ) : null}
                      <span>{name}</span>
                    </div>
                  );
                })
              ) : (
                <div>No Subjects</div>
              )}
            </div>
          ) : (
            <div className="text-center">
              <p>
                First configure education level and class to view the resources
              </p>
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default ELibrary;
