import GranularPermissionModal from "../../../Permissions/GranularPermissionModal";
import ModalWindow from "../../../UI/ModalWindow";
import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  scaleTypeCode,
  draggable,
  checkWidgetAdminOrNot,
  checkRolePermissions,
} from "../../../../utils/Constants";
import {
  showErroMessage,
  displayConfirmDeleteAlert,
  displaySuccessAlert,
} from "../../../../utils/Utils";
import swal from "sweetalert";

import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { connect } from "react-redux";
import {
  getAssessmentScales,
  updateAssessmentScaleList,
} from "../../../../ducks/PrimaryARRDucks";
import { MESSAGEHEADER } from "../../../../utils/DisplayMessage";

/**
 * @author Ishan Shrestha
 */
class ListAssessmentScale extends Component {
  state = {
    assessmentScales: null, //array
    assessmentScale: null, // single item
    levelMarkGradeLayout: null,
    modal: false,
    updatedScaleName: "",
    updatedScoreName: "",
    updatedId: null,
    nameError: false,
    permissionDeleteAssessmentScale: false,
    permissionEditAssessmentScale: false,
  };
  componentDidMount() {
    // this.getAssessmentScales();
    this.props.getAssessmentScales(this.props.code);
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionDeleteAssessmentScale: checkRolePermissions(
        "delete-assessment-scale",
        "activity",
        this.props.name
      ),
      permissionEditAssessmentScale: checkRolePermissions(
        "edit-assessment-scale",
        "activity",
        this.props.name
      ),
    });
  };

  handleAssessmentScaleDelete = (assessmentScaleId) => {
    displayConfirmDeleteAlert(
      assessmentScaleId,
      this.handleRemoveAssessmentScale
    );
  };

  handleRemoveAssessmentScale = (assessmentScaleId) => {
    let param = {
      id: assessmentScaleId,
    };

    axiosPost(URL.deleteAssessmentScale, param, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.getAssessmentScales(this.props.code);
      } else {
        swal({
          title: "Warning",
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.getAssessmentScales(this.props.code);
      }
    });
  };

  handleUpdateAssessmentScale = (e, index) => {
    let assessmentScales = [...this.props.assessmentScales];
    assessmentScales.forEach((scale, i) => {
      if (index === i) {
        scale.editable = true;
        this.setState({
          updatedScaleName: scale.scaleName,
          updatedScoreName: scale.scoreName,
          updatedId: scale.id,
        });
      } else {
        scale.editable = false;
      }
    });
    // this.setState({ assessmentScales }, () => {
    //   document.getElementById("scale" + index).focus();
    // });
    this.props.updateAssessmentScaleList(assessmentScales);
    // document.getElementById("scale" + index).focus();
  };

  getAssessmentScales = () => {
    let param = {
      assessmentModuleCode: this.props.code ? this.props.code : null,
    };
    axiosPost(URL.selectAssessmentScale, param, (response) => {
      if (response.status === 200) {
        this.setState({ assessmentScales: response.data.data }, () => {
          let assessmentScales = [...this.state.assessmentScales];
          assessmentScales.forEach((scale) => {
            scale.editable = false;
          });
          this.setState({ assessmentScales });
        });
      } else {
        showErroMessage(response);
      }
    });
  };
  handleViewAssessmentDetailBtnClick = (e, i) => {
    let assessmentScales = [...this.props.assessmentScales];
    let assessmentScale = assessmentScales[i];
    let levelMarkGradeLayout = this.getLevelMarkGradeLayout(assessmentScale);
    this.setState(
      {
        assessmentScale,
        levelMarkGradeLayout,
      },
      () => {
        this.toggleModal();
      }
    );
  };
  getLevelMarkGradeLayout = (assessmentScale) => {
    let levelMarkGradeLayout = null;
    if (assessmentScale) {
      if (assessmentScale.assessmentScaleTypeCode === scaleTypeCode.mark) {
        if (assessmentScale.assessmentScaleMark)
          levelMarkGradeLayout = (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <p>
                    <strong>Minimum Range:</strong>{" "}
                    {assessmentScale.assessmentScaleMark.rangeMin}
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                    <strong>Maximum Range:</strong>{" "}
                    {assessmentScale.assessmentScaleMark.rangeMax}
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                    <strong>Minimum Achievement Level:</strong>{" "}
                    {assessmentScale.assessmentScaleMark.minAchievementLevel}
                  </p>
                </div>
              </div>
              <table className="table table-striped table-bordered">
                <thead className="tt-group-header">
                  {assessmentScale.assessmentScaleMark.assessmentScaleMarkLevels
                    .length > 0 ? (
                    <tr className="text-center">
                      <th>Minimum Range</th>
                      <th>Maximum Range</th>
                      <th>Level</th>
                    </tr>
                  ) : null}
                </thead>
                <tbody>
                  {assessmentScale.assessmentScaleMark.assessmentScaleMarkLevels
                    ? assessmentScale.assessmentScaleMark.assessmentScaleMarkLevels.map(
                        (markLevel, i) => (
                          <tr className="text-center">
                            <td>{markLevel.rangeMin}</td>
                            <td>{markLevel.rangeMax}</td>
                            <td>{markLevel.level}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          );
      } else if (
        assessmentScale.assessmentScaleTypeCode === scaleTypeCode.level
      ) {
        levelMarkGradeLayout = (
          <table className="table table-striped table-bordered">
            <thead className="tt-group-header">
              <tr className="text-center">
                <th>Level</th>
                <th>Descriptor</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {assessmentScale.assessmentScaleLevels
                ? assessmentScale.assessmentScaleLevels.map((level, i) => (
                    <tr className="text-center">
                      {level.levelType === "colourStickers" ? (
                        <td>
                          <div
                            style={{
                              backgroundColor: level.level,
                              height: "30px",
                            }}
                          ></div>
                        </td>
                      ) : level.levelType === "image" ? (
                        <td className="tt-grade-tableRow">
                          <div className="tt-grade-image-div">
                            <img
                              src={URL.imageSelectURL + level.level}
                              alt="listAssessment"
                              className="img-responsive"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </td>
                      ) : (
                        <td>{level.level}</td>
                      )}

                      <td>{level.descriptor}</td>
                      <td>{level.description}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        );
      } else if (
        assessmentScale.assessmentScaleTypeCode === scaleTypeCode.gradePoint
      ) {
        levelMarkGradeLayout = (
          <table className="table table-striped table-bordered">
            <thead className="tt-group-header">
              <tr className="text-center">
                <th>Grade</th>
                <th>Minimum Percentage</th>
                <th>Maximum Percentage</th>
                <th>Grade Point</th>
                <th>Minimum GPA</th>
                <th>Manimum GPA</th>
                <th>GPA Evaluation</th>
              </tr>
            </thead>
            <tbody>
              {assessmentScale.assessmentScaleGradePoints
                ? assessmentScale.assessmentScaleGradePoints.map((level, i) => (
                    <tr className="text-center">
                      <td>{level.grade}</td>
                      <td>{level.percentageMin}</td>
                      <td>{level.percentageMax}</td>
                      <td>{level.gradePoint}</td>
                      <td>{level.gpaMin}</td>
                      <td>{level.gpaMax}</td>
                      <td>{level.gpaEvaluation}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        );
      }
      return levelMarkGradeLayout;
    }
  };
  //for modal open and close
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    name === "updatedScaleName" &&
      value.length > 0 &&
      this.setState({ nameError: false });
    this.setState({
      [name]: value,
    });
  };

  handleCancelUpdate = (e, index) => {
    e.preventDefault();
    let assessmentScales = [...this.props.assessmentScales];
    assessmentScales.forEach((scale, i) => {
      if (index === i) {
        scale.editable = false;
      }
    });
    // this.setState({ assessmentScales });
    this.props.updateAssessmentScaleList(assessmentScales);
  };

  handleSubmitUpdate = (e, id) => {
    e.preventDefault();
    if (
      this.state.updatedScaleName === "" ||
      this.state.updatedScoreName === ""
    ) {
      swal({
        title: MESSAGEHEADER.error,
        text: "Scale name and score name cannot be empty.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      let param = {
        scaleName: this.state.updatedScaleName,
        scoreName: this.state.updatedScoreName,
        id: id,
      };
      axiosPost(URL.updateAssessmentScale, param, (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          // this.getAssessmentScales();
          this.props.getAssessmentScales(this.props.code);
        } else {
          showErroMessage(response);
        }
      });
    }
  };
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          List Assessment Scale
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-newListBox">
          {this.props.assessmentScales ? (
            this.props.assessmentScales.length > 0 ? (
              <table className="table table-striped table-bordered">
                <thead className="tt-group-header text-center">
                  <tr className="text-center">
                    <th>Assessment Scale Type</th>
                    <th>Scale Name</th>
                    <th>Score Name</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.assessmentScales ? (
                    this.props.assessmentScales.length > 0 ? (
                      this.props.assessmentScales.map((scale, i) => (
                        <tr className="text-center">
                          <td>
                            {scale.assessmentScaleTypeCode ===
                            scaleTypeCode.mark
                              ? "Mark"
                              : scale.assessmentScaleTypeCode ===
                                scaleTypeCode.level
                              ? "Level"
                              : scale.assessmentScaleTypeCode ===
                                scaleTypeCode.gradePoint
                              ? "GPA"
                              : ""}
                          </td>
                          <td>
                            {scale.editable ? (
                              <input
                                type="text"
                                id={"scale" + i}
                                value={this.state.updatedScaleName}
                                name="updatedScaleName"
                                onChange={this.handleChange}
                                className={
                                  "form-control " +
                                  (this.state.nameError &&
                                  this.state.updatedScaleName === ""
                                    ? "indicate-error"
                                    : "")
                                }
                              />
                            ) : (
                              scale.scaleName
                            )}
                          </td>

                          <td>
                            {scale.editable ? (
                              <input
                                type="text"
                                id={"score" + i}
                                className={
                                  "form-control " +
                                  (this.state.nameError &&
                                  this.state.updatedScoreName === ""
                                    ? "indicate-error"
                                    : "")
                                }
                                value={this.state.updatedScoreName}
                                name="updatedScoreName"
                                onChange={this.handleChange}
                              />
                            ) : (
                              scale.scoreName
                            )}
                          </td>
                          <td className="text-center">
                            {scale.editable ? (
                              <>
                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={(e) =>
                                    this.handleSubmitUpdate(e, scale.id)
                                  }
                                >
                                  Save
                                </button>
                                <button
                                  className="tt-button tt-button-primary ml-2"
                                  onClick={(e) => this.handleCancelUpdate(e, i)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="tt-button tt-button-primary"
                                  onClick={(e) =>
                                    this.handleViewAssessmentDetailBtnClick(
                                      e,
                                      i
                                    )
                                  }
                                >
                                  View
                                </button>
                                {this.state.permissionDeleteAssessmentScale ? (
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={(e) =>
                                      this.handleAssessmentScaleDelete(scale.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                ) : null}
                                {this.state.permissionEditAssessmentScale ? (
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={(e) =>
                                      this.handleUpdateAssessmentScale(e, i)
                                    }
                                  >
                                    Update
                                  </button>
                                ) : null}
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {this.props.assessmentScaleSpinner ? (
                          <td>
                            <Spinner color="primary"></Spinner>
                            <br></br>Loading Data...
                          </td>
                        ) : (
                          <td>No records found</td>
                        )}
                      </tr>
                    )
                  ) : (
                    "No records found."
                  )}
                </tbody>
              </table>
            ) : this.props.assessmentScaleSpinner ? (
              <div className="text-center">
                <Spinner color="primary"></Spinner>
                <br></br>Loading Data...
              </div>
            ) : (
              <div className="text-center">No records found.</div>
            )
          ) : (
            <div className="tt-newListBox">No records found.</div>
          )}
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggleModal}
            size="lg"
            centered
          >
            <ModalHeader toggle={this.toggleModal} className="tt-group-header">
              <div>
                {this.state.assessmentScale
                  ? this.state.assessmentScale.scaleName
                  : ""}
              </div>
            </ModalHeader>
            <ModalBody>{this.state.levelMarkGradeLayout}</ModalBody>
          </Modal>
          <ModalWindow
            modal={this.state.permissionModal}
            size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              this.setState({
                permissionModal: !this.state.permissionModal,
              });
            }}
            modalHeader={"Assign permission to user"}
            modalBody={
              <GranularPermissionModal
                widgetName={this.props.name}
                moduleName="Recording Setup"
                header="List Assessment Scale"
                activityName="select-assessment-scale"
              />
            }
          ></ModalWindow>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assessmentScales: state.primaryArr.assessmentScaleList,
  assessmentScaleSpinner: state.primaryArr.assessmentScaleSpinner,
});

const mapActionsToProps = {
  getAssessmentScales: getAssessmentScales,
  updateAssessmentScaleList: updateAssessmentScaleList,
};

export default connect(mapStateToProps, mapActionsToProps)(ListAssessmentScale);
