import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Input } from "reactstrap";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import Select from "react-select";
import swal from "sweetalert";
import { cloneDeep } from "lodash";
import "../../assets/scss/ClassRoutine.scss";
import makeAnimated from "react-select/animated";
import moment from "moment";
import "../../assets/scss/ClassRoutine.scss";
import ModalWindow from "../UI/ModalWindow";
import RoutineAliasModal from "./RoutineAliasModal";

const animatedComponents = makeAnimated();

class ClassRoutineFormat extends Component {
  state = {
    aliasModal: false,
    aliasName: [],
    edit: false,
    errors: {},
    finalRoutinee: "",
    routine: [
      {
        timeSlot: [{ startDateTime: "", endDateTime: "" }],
        key: "",
        isBreak: false,
        breakType: "",
        weekDays: [],
      },
    ],
    selectedAliasNameData: {},
    selectedRoutineIdx: "",
    selectedSubjectForAlias: null,
    selectedSubjectsId: [],
    selectedTeachersId: [],
    selectedTimeSlot: [],
    selectedWeekDaysId: "",
    stIs: "",
    subjectOption: "",
    teacherOption: "",
    teachers: [],
    updatedIndex: "",
    updatedRoutine: [],
    updatedRow: "",
    weekDays: [],
  };

  addRoutineField = (idx) => {
    const newRoutine = [...this.state.routine];
    //validation of  data before submitting
    for (const el of newRoutine) {
      if (el.timeSlot) {
        if (
          el.timeSlot[0].startDateTime === "" ||
          el.timeSlot[0].endDateTime === ""
        ) {
          swal("Warning", "Missing start or end time.", "");
          return;
        }
      }

      if (el.isBreak === true) {
        if (el.breakType === "") {
          swal("Warning", "Missing break type", "");
          return;
        }
      }
      for (const wD of el.weekDays) {
        if (
          wD.selectedOptionSubject === undefined ||
          wD.selectedOptionSubject === null
        ) {
          swal(
            "Warning",
            "Subjects are missing in selected weekdays.",
            ""
          );
          return;
        }
      }
    }

    const newRoutineField = newRoutine.concat([
      {
        timeSlot: [{ startDateTime: "", endDateTime: "" }],
        isBreak: false,
        weekDays: [],
        breakType: "",
      },
    ]);

    this.setState({ routine: newRoutineField });
  };

  addUpdatedRoutineField = () => {
    this.setState({
      routine: [
        {
          timeSlot: [{ startDateTime: "", endDateTime: "" }],
          key: "",
          isBreak: false,
          breakType: "",
          weekDays: [],
        },
      ],
    });
  };

  clearState = () => {
    this.setState({
      routine: [
        {
          timeSlot: [{ startDateTime: "", endDateTime: "" }],
          isBreak: false,
          breakType: "",
          weekDays: [
            {
              key: "",
              weekdayId: "",
              subjectId: this.setState({ selectedSubjectsId: "" }),
              peopleId: this.setState({ selectedTeachersId: "" }),
              selectedOptionTeacher: null,
              selectedOptionSubject: null,
            },
          ],
        },
      ],
    });
  };

  closeAliasModal = () => {
    this.setState({ aliasModal: !this.state.aliasModal, edit: false });
  };

  componentDidMount = () => {
    this.getWeekdays();
    this.getSubjects();
    this.getTeachers();
    if (this.props.condition === "update") {
      this.editConfig();
    }
  };

  editConfig = () => {
    this.setState({ updatedRoutine: this.props.routine1, routine: [] });
  };

  getSubjects = () => {
    var subjectOption = this.props.subjects.map(function (sub) {
      return {
        value: sub.id,
        label: sub.name,
        hasAliasName: false,
        aliasName: "",
      };
    });
    this.setState({ subjectOption: subjectOption });
  };

  getTeachers = () => {
    var teacherOption = this.props.teachers.map(function (teach) {
      return { value: teach.peopleId, label: teach.people.name };
    });
    this.setState({ teacherOption: teacherOption });
  };

  getWeekdays = () => {
    axiosPost(
      URL.getWeekDaysByEducationLevel,
      { educationLevelId: this.props.selectedEducationLevelId },
      (response) => {
        if (response.data.status === 200) {
          const data = response.data.data;
          if (data.length > 0)
            this.setState({
              weekDays: data,
            });
        }
      }
    );
  };

  handleAddRoutine = () => {
    var date_format = new Date();
    const month = parseInt(date_format.getMonth()) + 1;
    var requiredDate =
      date_format.getFullYear() + "-" + month + "-" + date_format.getDate();

    const clonedCopy = cloneDeep(this.state.routine);
    let weekDays = [...this.state.weekDays];
    //validation of  data beforde submitting
    for (const el of clonedCopy) {
      if (el.timeSlot) {
        if (
          el.timeSlot[0].startDateTime === "" ||
          el.timeSlot[0].endDateTime === ""
        ) {
          swal("Warning", "Missing start or end date time.", "");
          return;
        }
      }
      if (el.isBreak && el.weekDays.length === 0) {
        if (el.breakType === "") {
          swal("Warning", "Missing break type.", "");
          return;
        }
      } else {
        if(!(el.weekDays.length == weekDays.length)){
          swal(
            "Warning",
            "Assign Subjects to all week days.",
            ""
          );
          return;

        }
      }
    }
    clonedCopy.forEach((el, elIDx) => {
      if (el.isBreak) {
        el.key = `${elIDx},0`;
        el.weekDays = [];
      }

      el.weekDays = el.weekDays.map((wD) => {
        wD.key = `${elIDx},${wD.weekdayId}`;
        return wD;
      });
    });
    const finalRoutine = clonedCopy.map((el, rIdx) => {
      if (el.isBreak) {
        el.weekDays = [];

        el.timeSlot[0].startDateTime =
          requiredDate + " " + el.timeSlot[0].startDateTime + ":00";
        el.timeSlot[0].endDateTime =
          requiredDate + " " + el.timeSlot[0].endDateTime + ":00";

        return el;
      } else {
        el.key = "";
        el.timeSlot[0].startDateTime =
          requiredDate + " " + el.timeSlot[0].startDateTime + ":00";
        el.timeSlot[0].endDateTime =
          requiredDate + " " + el.timeSlot[0].endDateTime + ":00";
        el.breakType = "";
        delete el.weekDays.map((wD) => wD.selectedOptionTeacher);
        delete el.weekDays.map((wD) => wD.selectedOptionSubject);
        weekDays.forEach((wD, wIdx) => {
          const check = el.weekDays.findIndex((eWD) => eWD.weekdayId === wD.id);
          if (check === -1) {
            el.weekDays.push({
              key: rIdx + "," + (wIdx + 1),
              weekdayId: wD.id,
              subjectId: [],
              peopleId: [],
              selectedOptionSubject: [
                {
                  value: "",
                  label: "",
                  hasAliasName: false,
                  aliasName: "",
                },
              ],
              hasAliasName: true,
              selectedOptionTeacher: [
                {
                  value: "",
                  label: "",
                },
              ],
            });
          }
        });
        el.weekDays = el.weekDays.sort((a, b) => a.weekdayId - b.weekdayId);
        return el;
      }
    });

    let data = {
      educationLevelId: this.props.selectedEducationLevelId,
      yearlevelId: this.props.selectedYearLevel,
      classroomId: this.props.selectedClassrooms
        ? this.props.selectedClassrooms
        : null,
      routine: finalRoutine,
    };

      let canInsert = data.routine.map((r) => {
      return r.weekDays.some((w) => {
        return w.subjectId.length > 0;
      });
    });

    if (canInsert[0] === true) {
      axiosPost(URL.insertClassRoutine, data, (response) => {
        if (response.status === 200) {
          swal({ title: "Success", text: "Class Routine successfully added" });
          this.clearState();
        }
      });
      localStorage.setItem("routine", JSON.stringify(data));
    } else {
      swal("Error", "At least one subject must be selected");
    }
  };

  handleAliasDataChange = (e, idx) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let selectedAliasNameData = this.state.selectedAliasNameData;
    selectedAliasNameData.selectedOptionSubject[idx][name] = value;
    this.setState({ selectedAliasNameData });
  };

  handleAliasName = (routineIdx, weekDaysId) => {
    let routine = [...this.state.routine];
    let aliasData = [];
    if (routine[routineIdx].weekDays.length > 0) {
      routine[routineIdx].weekDays.map((el) => {
        if (el.weekdayId === weekDaysId) {
          aliasData = el;
        }
      });
      this.setState({
        selectedAliasNameData: JSON.parse(JSON.stringify(aliasData)),
        aliasModal: !this.state.aliasModal,
        selectedRoutineIdx: routineIdx,
        selectedWeekDaysId: weekDaysId,
      });
    } else {
      this.setState({
        selectedAliasNameData: {},
        aliasModal: !this.state.aliasModal,
      });
    }
  };

  handleAliasSave = () => {
    let selectedAliasNameData = this.state.selectedAliasNameData;
    let error = 0;
    if (selectedAliasNameData.selectedOptionSubject.length > 0) {
      selectedAliasNameData.selectedOptionSubject.forEach((el) => {
        if (el.hasAliasName) {
          if (el.aliasName === "") {
            error++;
          }
        } else {
          el.aliasName = "";
        }
      });
      if (error > 0) {
        swal("Error", "Provide Alias Name for all checked subjects");
      } else {
        let routine = [...this.state.routine];

        routine[this.state.selectedRoutineIdx].weekDays.forEach((el) => {
          if (el.weekdayId == this.state.selectedWeekDaysId) {
            el.selectedOptionSubject =
              selectedAliasNameData.selectedOptionSubject;
          }
        });
        this.setState(
          {
            routine,
            selectedAliasNameData: {},
            selectedRoutineIdx: "",
            selectedWeekDaysId: "",
          },
          function () {
            this.closeAliasModal();
          }
        );
      }
    }
  };

  handleBreakChange = (e, id) => {
    var routine = [...this.state.routine];

    routine.forEach((el, rIdx) => {
      if (rIdx === id) {
        if (el.isBreak) {
          el.breakType = e.target.value;
          el.key = `${id},0`;
        }
      }
    });
    this.setState({ routine });
  };

  handleCheckbox = (e, id) => {
    var routine = [...this.state.routine];
    routine.forEach((el, rIdx) => {
      if (rIdx === id) {
        el.isBreak = e.target.checked;
      }
    });
    this.setState({ routine });
  };

  handleEndTimeSlotChange = (e, idx) => {
    let routine = [...this.state.routine];
    const { name, value } = e.target;
    let msDiff = null;
    const startDateTime = routine[idx].timeSlot.find((obj) =>
      obj.hasOwnProperty("startDateTime")
    );
    const today = moment(new Date()).format("YYYY/MM/DD");

    if (
      startDateTime &&
      startDateTime.startDateTime &&
      startDateTime.startDateTime !== ""
    ) {
      const startMS = new Date(
        `${today} ` + startDateTime.startDateTime
      ).getTime();
      const endMS = new Date(`${today} ` + value).getTime();
      msDiff = endMS - startMS;

      if (msDiff !== null && msDiff > 0) {
        if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
          const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
            slot.hasOwnProperty(name)
          );
          if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = value;
        } else {
          routine[idx].timeSlot.push({
            [name]: value,
          });
        }
        this.setState({ routine });
      } else {
        if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
          const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
            slot.hasOwnProperty(name)
          );
          if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = "";
        } else {
          routine[idx].timeSlot.push({
            [name]: "",
          });
        }
        this.setState({ routine });
        swal("Warning", "Please select valid time.", "");
      }
    } else {
      if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
        const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
          slot.hasOwnProperty(name)
        );
        if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = value;
      } else {
        routine[idx].timeSlot.push({
          [name]: value,
        });
      }
      this.setState({ routine });
    }
  };

  handleStartTimeSlotChange = (e, idx) => {
    let routine = [...this.state.routine];
    const { name, value } = e.target;
    let msDiff = null;
    const endDateTime = routine[idx].timeSlot.find((obj) =>
      obj.hasOwnProperty("endDateTime")
    );
    const today = moment(new Date()).format("YYYY/MM/DD");
    if (
      endDateTime &&
      endDateTime.endDateTime &&
      endDateTime.endDateTime !== ""
    ) {
      const endMS = new Date(`${today} ` + endDateTime.endDateTime).getTime();
      const startMS = new Date(`${today} ` + value).getTime();
      msDiff = startMS - endMS;

      if (msDiff !== null && msDiff < 0) {
        if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
          const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
            slot.hasOwnProperty(name)
          );
          if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = value;
        } else {
          routine[idx].timeSlot.push({
            [name]: value,
          });
        }
        this.setState({ routine });
      } else {
        if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
          const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
            slot.hasOwnProperty(name)
          );
          if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = "";
        } else {
          routine[idx].timeSlot.push({
            [name]: "",
          });
        }
        this.setState({ routine });
        swal("Warning", "Please select valid time.", "");
      }
    } else {
      if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
        const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
          slot.hasOwnProperty(name)
        );
        if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = value;
      } else {
        routine[idx].timeSlot.push({
          [name]: value,
        });
      }
      this.setState({ routine });
    }

    //checking for start and end date time in previous routine
    if (idx !== 0) {
      const endDateTimePrevious = routine[idx - 1].timeSlot.find((obj) =>
        obj.hasOwnProperty("endDateTime")
      );
      const endMS = new Date(
        `${today} ` + endDateTimePrevious.endDateTime
      ).getTime();
      const startMS = new Date(`${today} ` + value).getTime();
      msDiff = startMS - endMS;

      if (msDiff !== null && msDiff > 0) {
        if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
          const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
            slot.hasOwnProperty(name)
          );
          if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = value;
        } else {
          routine[idx].timeSlot.push({
            [name]: value,
          });
        }
        this.setState({ routine });
      } else {
        if (routine[idx].timeSlot.some((obj) => obj.hasOwnProperty(name))) {
          const slotIndex = routine[idx].timeSlot.findIndex((slot) =>
            slot.hasOwnProperty(name)
          );
          if (slotIndex !== -1) routine[idx].timeSlot[slotIndex][name] = "";
        } else {
          routine[idx].timeSlot.push({
            [name]: "",
          });
        }
        this.setState({ routine });
        swal("Warning", "Please select valid time.", "");
      }
    }
  };

  handleSubjectChange = (selectedOption, keyId, idx) => {
    let routine = [...this.state.routine];
    let weekdayId = keyId;
    if (selectedOption !== null) {
      var subjectsId = selectedOption.map((opt) => opt.value);
    }

    this.setState({ selectedSubjectsId: subjectsId }, function () {
      let key = `${idx},${weekdayId}`;
      routine.forEach((el, rIdx) => {
        if (rIdx === idx) {
          const weekDay = el.weekDays.find((day) => day.key === key);
          if (weekDay) {
            let modifiedWeekDay = el.weekDays.findIndex(
              (day) => day.key === key
            );
            el.weekDays[modifiedWeekDay].subjectId =
              this.state.selectedSubjectsId;
            el.weekDays[modifiedWeekDay].selectedOptionSubject = selectedOption;
            el.weekDays[modifiedWeekDay].hasAliasName =
              selectedOption && selectedOption.length > 0 ? true : false;
          } else {
            el.weekDays.push({
              key: key,
              weekdayId: weekdayId,
              subjectId: this.state.selectedSubjectsId,
              peopleId: [],
              selectedOptionSubject: selectedOption,
              hasAliasName:
                selectedOption && selectedOption.length > 0 ? true : false,
            });
          }
        }
      });
      this.setState({ routine });
    });
  };

  handleTeacherChange = (selectedOption, keyId, idx) => {
    let weekdayId = keyId;
    let key = `${idx},${weekdayId}`;

    if (selectedOption !== null) {
      var teachersId = selectedOption.map((opt) => opt.value);
    }

    this.setState({ selectedTeachersId: teachersId }, function () {
      let routine = [...this.state.routine];

      routine.forEach((el, rIdx) => {
        if (rIdx === idx) {
          const weekDay = el.weekDays.find((day) => day.key === key);
          if (weekDay) {
            let modifiedWeekDay = el.weekDays.findIndex(
              (day) => day.key === key
            );

            el.weekDays[modifiedWeekDay].peopleId =
              this.state.selectedTeachersId;
            el.weekDays[modifiedWeekDay].selectedOptionTeacher = selectedOption;
          } else {
            el.weekDays.push({
              key: key,
              weekdayId: weekdayId,
              subjectId: "",
              peopleId: this.state.selectedTeachersId,
              selectedOptionTeacher: selectedOption,
            });
          }
        }
      });
      this.setState({ routine });
    });
  };

  handleUpdateAliasName = (e, rout, row, idx) => {
    let datum = rout;
    this.setState({
      selectedAliasNameData: JSON.parse(JSON.stringify(datum)),
      edit: true,
      aliasModal: !this.state.aliasModal,
      updatedRow: row,
      updatedIndex: idx,
    });
  };

  handleUpdateAliasSave = () => {
    let selectedAliasNameData = this.state.selectedAliasNameData;
    let error = 0;
    if (selectedAliasNameData.selectedSubjectValue.length > 0) {
      selectedAliasNameData.selectedSubjectValue.forEach((el) => {
        if (el.hasAliasName) {
          if (el.aliasName === "") {
            error++;
          }
        } else {
          el.aliasName = "";
        }
      });
      if (error > 0) {
        swal("Error", "Provide Alias Name for all checked subjects");
      } else {
        const arrayToLookFor =
          this.state.updatedRoutine.length === 0
            ? this.props.routine1
            : this.state.updatedRoutine;
        var updatedRoutine = cloneDeep(arrayToLookFor);
        Object.keys(updatedRoutine).map((key, id) => {
          if (key === this.state.updatedRow) {
            return updatedRoutine[key].map((data, idx) => {
              if (idx === this.state.updatedIndex) {
                data.selectedSubjectValue =
                  selectedAliasNameData.selectedSubjectValue;
              }
            });
          }
        });
        this.setState(
          {
            updatedRoutine,
            selectedAliasNameData: {},
            updatedRow: "",
            updatedIndex: "",
          },
          function () {
            this.closeAliasModal();
          }
        );
      }
    }
  };

  handleUpdatedAliasName = (e, idx) => {
    let selectedAliasNameData = this.state.selectedAliasNameData;
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    selectedAliasNameData.selectedSubjectValue[idx][name] = value;
    this.setState({ selectedAliasNameData });
  };

  handleUpdatedBreak = (e, rowId, idx) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;
    let updatedRoutine = cloneDeep(arrayToLookFor);
    var updatedBreakType = e.target.value;

    Object.keys(updatedRoutine).forEach((key, id) => {
      if (key === rowId) {
        return updatedRoutine[key].forEach((data, id) => {
          data.breakType = updatedBreakType;
        });
      }
    });
    this.setState({ updatedRoutine });
  };

  handleUpdatedCheckbox = (e, rowId, idx) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;

    var updatedRoutine = cloneDeep(arrayToLookFor);
    var updatedIsBreak = e.target.checked;
    Object.keys(updatedRoutine).forEach((key, id) => {
      if (key === rowId) {
        if (updatedIsBreak) {
          updatedRoutine[key].map((data, id) => {
            data.isBreak = updatedIsBreak;
            data.key = `${key},0`;
          });
        } else {
          if (updatedRoutine[key].length === 1) {
            this.state.weekDays.slice(1, 7).map((wD, id) => {
              updatedRoutine[key].push({
                ...updatedRoutine[key][0],
                breakType: null,
                indexKey: `${key},${wD.id}`,
                isBreak: null,
                weekday: `${wD.name}`,
                weekdayId: parseInt(`${wD.id}`),
                subjectId: [],
                peopleId: [],
                subjectName: [],
                peopleName: [],
                selectedSubjectValue: [],
                selectedPeopleValue: [],
              });
            });

            updatedRoutine[key][0].key = `${key},1`;
            updatedRoutine[key][0].indexKey = `${key},1`;
            updatedRoutine[key][0].breakType = null;
            updatedRoutine[key][0].isBreak = null;
            updatedRoutine[key][0].weekdayId = 1;
            updatedRoutine[key][0].weekday = "monday";
            updatedRoutine[key][0].subjectId = [];
            updatedRoutine[key][0].peopleId = [];
            updatedRoutine[key][0].subjectName = [];
            updatedRoutine[key][0].peopleName = [];
            updatedRoutine[key][0].selectedSubjectValue = [];
            updatedRoutine[key][0].selectedPeopleValue = [];
          } else {
            updatedRoutine[key].map((data, id) => {
              data.isBreak = updatedIsBreak;
              data.key = `${key},0`;
            });
          }
        }
      }
    });

    this.setState({ updatedRoutine });
  };

  handleUpdatedEndTimeSlot = (e, rowId, idx) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;
    let updatedRoutine = cloneDeep(arrayToLookFor);
    const { value } = e.target;

    //for validation of own start and end time
    let msDif = null;
    const startDateTime = updatedRoutine[rowId][0].startDateTime.split(" ")[1];
    const today = moment(new Date()).format("YYYY-MM-DD");
    const endMS = new Date(`${today} ` + value).getTime();
    const startMS = new Date(`${today} ` + startDateTime).getTime();
    msDif = endMS - startMS;
    let msDiffNext = null;
    if (idx === 0 && Object.keys(updatedRoutine).length > 1) {
      const startDateTimeNext =
        updatedRoutine[parseInt(rowId) + 1][0].startDateTime.split(" ")[1];
      const startMSNext = new Date(`${today} ` + startDateTimeNext).getTime();
      const endMSNow = new Date(`${today} ` + value).getTime();
      msDiffNext = startMSNext - endMSNow;
    }

    let msDifNxt = null;
    if (
      idx !== 0 &&
      parseInt(rowId) !== Object.keys(updatedRoutine).length - 1
    ) {
      const startDateTimeNext =
        updatedRoutine[parseInt(rowId) + 1][0].startDateTime.split(" ")[1];

      const startMSNext = new Date(`${today} ` + startDateTimeNext).getTime();
      const endMSNow = new Date(`${today} ` + value).getTime();
      msDifNxt = startMSNext - endMSNow;
    }

    //throw swal warning if validation condition fails
    if (msDif < 0 || msDiffNext < 0 || (msDifNxt !== null && msDifNxt < 0)) {
      swal("Warning", "Please select valid time.", "");
      return;
    }

    //loop over updated routine and set the property of start time only if they are valid
    Object.keys(updatedRoutine).forEach((key, id) => {
      if (key === rowId) {
        const newUpdatedRoutine = updatedRoutine[key].map((r) => {
          r.endDateTime = today + " " + value;
          return r;
        });
        updatedRoutine[key] =
          rowId !== 0 && msDif !== null && msDif > 0
            ? newUpdatedRoutine
            : updatedRoutine[key];
      }
    });

    this.setState({ updatedRoutine });
  };

  handleUpdateRoutine = () => {
    let updatedRoutine = [];
    const { routine1 } = this.props;
    const arrayToLoop =
      this.state.updatedRoutine.length === 0
        ? routine1
        : this.state.updatedRoutine;

    let ids = [];

    Object.keys(arrayToLoop).forEach((key, id) => {
      arrayToLoop[key].forEach((updateData) => {
        ids.push(updateData.id);
      });
      updatedRoutine.push({
        isBreak:
          arrayToLoop[key][0].isBreak === null ||
          arrayToLoop[key][0].isBreak === undefined
            ? false
            : true,

        key:
          arrayToLoop[key][0].isBreak === null ||
          arrayToLoop[key][0].isBreak === undefined
            ? null
            : `${key},0`,

        breakType:
          arrayToLoop[key][0].breakType === null
            ? null
            : arrayToLoop[key][0].breakType,
        timeSlot: [
          {
            startDateTime: arrayToLoop[key][0].startDateTime + ":00",
            endDateTime: arrayToLoop[key][0].endDateTime + ":00",
          },
        ],

        weekDays:
          arrayToLoop[key][0].isBreak === true
            ? []
            : arrayToLoop[key].map((el, elIdx) => {
                return {
                  weekdayId: el.weekdayId,
                  subjectId:
                    typeof el.subjectId === "number"
                      ? [el.subjectId]
                      : el.subjectId,
                  peopleId:
                    typeof el.peopleId === "number"
                      ? [el.peopleId]
                      : el.peopleId,
                  key: `${key},${elIdx + 1}`,
                  selectedOptionSubject: el.selectedSubjectValue,
                };
              }),
      });
    });
    if (this.state.routine.length > 0) {
      // this.state.routine.forEach((el) => {
      //   if (
      //     el.timeSlot[0].startDateTime === "" ||
      //     el.timeSlot[0].endDateTime === ""
      //   ) {
      //     swal("Error", "Start Time or End Time missing");
      //     return false;
      //   }
      // });
      for (let key in this.state.routine) {
        const el = this.state.routine[key];
        if (
          el.timeSlot[0].startDateTime === "" ||
          el.timeSlot[0].endDateTime === ""
        ) {
          swal("Error", "Start Time or End Time missing");
          return;
        }
      }
      var date_format = new Date();
      const month = parseInt(date_format.getMonth()) + 1;
      var requiredDate =
        date_format.getFullYear() + "-" + month + "-" + date_format.getDate();
      const clonedCopy = cloneDeep(this.state.routine);
      let weekDays = [...this.state.weekDays];
      let keyCount = updatedRoutine.length;

      clonedCopy.forEach((el, elIDx) => {
        if (el.isBreak) {
          el.key = `${keyCount},0`;
          el.weekDays = [];
        }
        el.weekDays = el.weekDays.map((wD) => {
          wD.key = `${keyCount},${wD.weekdayId}`;
          return wD;
        });
        keyCount++;
      });
      const finalRoutine = clonedCopy.map((el, rIdx) => {
        let keyCountForEmpty = updatedRoutine.length;
        if (el.isBreak) {
          el.weekDays = [];

          el.timeSlot[0].startDateTime =
            requiredDate + " " + el.timeSlot[0].startDateTime + ":00";
          el.timeSlot[0].endDateTime =
            requiredDate + " " + el.timeSlot[0].endDateTime + ":00";

          keyCountForEmpty = keyCountForEmpty + 1;
          return el;
        } else {
          el.key = "";
          el.timeSlot[0].startDateTime =
            requiredDate + " " + el.timeSlot[0].startDateTime + ":00";
          el.timeSlot[0].endDateTime =
            requiredDate + " " + el.timeSlot[0].endDateTime + ":00";
          el.breakType = "";
          delete el.weekDays.map((wD) => wD.selectedOptionTeacher);
          delete el.weekDays.map((wD) => wD.selectedOptionSubject);
          weekDays.forEach((wD, wIdx) => {
            const check = el.weekDays.findIndex(
              (eWD) => eWD.weekdayId === wD.id
            );
            if (check === -1) {
              el.weekDays.push({
                key: keyCountForEmpty + "," + (wIdx + 1),
                weekdayId: wD.id,
                subjectId: [],
                peopleId: [],
                selectedOptionSubject: [
                  {
                    value: "",
                    label: "",
                    hasAliasName: false,
                    aliasName: "",
                  },
                ],
                hasAliasName: true,
                selectedOptionTeacher: [
                  {
                    value: "",
                    label: "",
                  },
                ],
              });
            }
          });
          el.weekDays = el.weekDays.sort((a, b) => a.weekdayId - b.weekdayId);
          keyCountForEmpty = keyCountForEmpty + 1;
          return el;
        }
      });
      updatedRoutine = updatedRoutine.concat(finalRoutine);
    }

    let timeError = 0;

    for (let el of updatedRoutine) {
      if (el.isBreak) {
        if (el.breakType === "") {
          swal("Warning", "Missing  break type.", "");
          timeError++;
          return;
        }
      }
    }
    let data = {
      educationLevelId: this.props.educationLevelId,
      yearlevelId: this.props.yearlevelId,
      classroomId: this.props.classroomId,
      routine: updatedRoutine,
      ids: ids,
    };

    if (timeError > 0) {
      swal("Error", "Start time and end time must be entered");
    } else {
      // let canInsert = data.routine.map((r) => {
      //   return r.weekDays.some((w) => {
      //     return w.peopleId.length > 0 && w.subjectId.length > 0;
      //   });
      // });
      let canInsert = data.routine.map((r) => {
        if (r.isBreak) {
          return true;
        } else {
          return r.weekDays.some((w) => {
            return w.peopleId.length > 0 && w.subjectId.length > 0;
          });
        }
      });

      // let err = 0;
      // data.routine.forEach((r) => {
      //   r.weekDays.forEach((w) => {
      //     if (w.peopleId.length === 0 || w.subjectId.length === 0) {
      //       err++;
      //     }
      //   });
      // });
      if (canInsert.every((ci) => ci === true)) {
        axiosPost(URL.updateClassRoutine, data, (response) => {
          if (response.status === 200) {
            swal({ title: "Success", text: response.data.message });
            this.props.getInsertedRoutine();
            this.props.handleClassRoutineUpdateModal();
          }
        });
      } else {
        swal("Error", "At least one subject must be selected");
      }
    }
  };

  handleUpdatedStartTimeSlot = (e, rowId, idx) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;
    let updatedRoutine = cloneDeep(arrayToLookFor);
    const { value } = e.target;

    //for validation of own start and end time

    let msDiff = null;
    const endDateTime = updatedRoutine[rowId][0].endDateTime.split(" ")[1];
    const today = moment(new Date()).format("YYYY-MM-DD");

    const startMS = new Date(`${today} ` + value).getTime();
    const endMS = new Date(`${today} ` + endDateTime).getTime();
    msDiff = startMS - endMS;

    //for validation of endtime and starttime of two routine in sequence
    let msDiffPrevious = null;

    if (idx !== 0) {
      const endDateTimePrevious =
        updatedRoutine[parseInt(rowId) - 1][0].endDateTime.split(" ")[1];
      const endMSPrevious = new Date(
        `${today} ` + endDateTimePrevious
      ).getTime();
      const startMSPrevious = new Date(`${today} ` + value).getTime();
      msDiffPrevious = startMSPrevious - endMSPrevious;
    }

    //throw swal warning if validation condition fails
    if (msDiffPrevious < 0 || msDiff > 0) {
      swal("Warning", "Please select valid time.", "");
      return;
    }

    //loop over updated routine and set the property of start time only if they are valid
    Object.keys(updatedRoutine).forEach((key, id) => {
      if (key === rowId) {
        const newUpdatedRoutine = updatedRoutine[key].map((r) => {
          r.startDateTime = today + " " + value;
          return r;
        });
        updatedRoutine[key] =
          rowId !== 0 && msDiffPrevious !== null && msDiffPrevious > 0
            ? newUpdatedRoutine
            : msDiff !== null && msDiff < 0
            ? newUpdatedRoutine
            : updatedRoutine[key];
      }
    });

    this.setState({ updatedRoutine });
  };

  handleUpdatedSubjectChange = (e, rowId, idx) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;

    var updatedRoutine = cloneDeep(arrayToLookFor);
    var updatedSubjectId = [];
    var updatedSubjectName = [];
    if (e !== null) {
      updatedSubjectId = e.map((opt) => opt.value);
      updatedSubjectName = e.map((opt) => opt.label);
    }
    Object.keys(updatedRoutine).map((key, id) => {
      if (key === rowId) {
        return updatedRoutine[key].map((data, id) => {
          if (id === idx) {
            data.subjectId = updatedSubjectId;
            data.subjectName = updatedSubjectName;
            data.selectedSubjectValue = e ? e : [];
          }
        });
      }
    });
    this.setState({ updatedRoutine });
  };

  handleUpdatedTeacherChange = (e, rowId, idx) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;
    var updatedRoutine = cloneDeep(arrayToLookFor);
    var updatedPeopleId = [],
      updatedPeopleName = [];

    if (e !== null) {
      updatedPeopleId = e.map((opt) => opt.value);
      updatedPeopleName = e.map((opt) => opt.label);
    }

    Object.keys(updatedRoutine).map((key, id) => {
      if (key === rowId) {
        return updatedRoutine[key].map((data, id) => {
          if (id === idx) {
            data.peopleId = updatedPeopleId;
            data.peopleName = updatedPeopleName;
            data.selectedPeopleValue = e ? e : [];
          }
        });
      }
    });
    this.setState({ updatedRoutine });
  };

  removeRoutineField = (index) => {
    let newRoutineArr = [...this.state.routine];
    newRoutineArr.splice(index, 1);
    this.setState({ routine: newRoutineArr });
  };

  removeUpdatedRoutineField = (r, rIdx, id) => {
    const arrayToLookFor =
      this.state.updatedRoutine.length === 0
        ? this.props.routine1
        : this.state.updatedRoutine;
    let updatedRoutine = cloneDeep(arrayToLookFor);
    Object.keys(updatedRoutine).forEach((key, idx) => {
      if (idx === id) {
        delete updatedRoutine[key];
      }
    });
    this.setState({ updatedRoutine });
  };

  renderAliasField = (rout, colId, routineIdx, weekDaysIdx) => {
    var findSelctedOne = rout?.weekDays.find((wD) => colId === wD.weekdayId);
    return findSelctedOne?.hasAliasName ? (
      <button
        className="tt-button tt-button-primary mt-1"
        onClick={(e) => this.handleAliasName(routineIdx, colId)}
      >
        Add Alias Name
      </button>
    ) : null;
  };

  renderUpdateAliasField = (rout, row, idx) => {
    if (rout.selectedSubjectValue.length > 0) {
      return (
        <button
          className="tt-button tt-button-primary mt-1"
          onClick={(e) => this.handleUpdateAliasName(e, rout, row, idx)}
        >
          Add Alias Name
        </button>
      );
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.6vh" }}>
        <div
          style={{ height: "74vh", overflowY: "auto" }}
          // className={this.state.weekDays.length > 5 ? "table-responsive" : ""}
        >
          <table className={"table mb-0 "} style={{ height: "98%" }}>
            <thead className="tt-group-header text-center">
              <tr>
                <th width="80px"></th>
                {this.state.weekDays.map((week) => {
                  return <th key={week.id}>{week.name}</th>;
                })}
                <th></th>
              </tr>
            </thead>

            <tbody>
              {this.props.condition === "create" ? (
                this.state.routine.length > 0 ? (
                  this.state.routine.map((rout, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <input
                            type="time"
                            placeholder="Start Time"
                            className="form-control"
                            name="startDateTime"
                            value={rout.timeSlot[0].startDateTime}
                            onChange={(e) =>
                              this.handleStartTimeSlotChange(e, idx)
                            }
                            style={{ marginTop: "4px" }}
                          />
                          <br></br>
                          <input
                            type="time"
                            className="form-control"
                            name="endDateTime"
                            placeholder="End Time"
                            onChange={(e) =>
                              this.handleEndTimeSlotChange(e, idx)
                            }
                            value={rout.timeSlot[0].endDateTime}
                            style={{ marginTop: "4px" }}
                          />
                          <br></br>
                          <Input
                            id={`breakCheckbox-${idx}`}
                            style={{ margin: "auto", paddingLeft: "5px" }}
                            type="checkbox"
                            name="isBreak"
                            className="classRoutineCheckbox"
                            checked={rout?.isBreak}
                            onChange={(e) => this.handleCheckbox(e, idx)}
                          />
                          <label
                            htmlFor={`breakCheckbox-${idx}`}
                            className="classRoutineLabel"
                          >
                            Add Break
                          </label>
                        </td>
                        {rout?.isBreak ? (
                          <td colSpan={this.state.weekDays.length}>
                            <Input
                              type="input"
                              name="breakType"
                              placeholder="Break Type"
                              value={rout.breakType}
                              style={{
                                display: rout?.isBreak ? "block" : "none",
                                width: "98%",
                              }}
                              onChange={(e) => this.handleBreakChange(e, idx)}
                            />
                          </td>
                        ) : (
                          this.state.weekDays.map((col, id) => {
                            return (
                              <td key={id} width="200px">
                                <Select
                                  value={
                                    rout?.weekDays.find(
                                      (wD) => col.id === wD.weekdayId
                                    )?.selectedOptionSubject || null
                                  }
                                  closeMenuOnSelect={false}
                                  isClearable={true}
                                  components={animatedComponents}
                                  options={this.state.subjectOption}
                                  isMulti
                                  name="subject"
                                  placeholder="Subject"
                                  onChange={(e) =>
                                    this.handleSubjectChange(e, col.id, idx)
                                  }
                                  menuPlacement="auto"
                                  hideSelectedOptions={true}
                                  required={true}
                                ></Select>
                                {this.renderAliasField(rout, col.id, idx, id)}
                                <Select
                                  required={true}
                                  value={
                                    rout?.weekDays.find(
                                      (wD) => col.id === wD.weekdayId
                                    )?.selectedOptionTeacher || null
                                  }
                                  closeMenuOnSelect={false}
                                  isClearable={true}
                                  components={animatedComponents}
                                  isMulti
                                  name="teacher"
                                  placeholder="Teachers"
                                  onChange={(e) =>
                                    this.handleTeacherChange(e, col.id, idx)
                                  }
                                  options={this.state.teacherOption}
                                  menuPlacement="auto"
                                  hideSelectedOptions={true}
                                  className="mt-1"
                                ></Select>
                              </td>
                            );
                          })
                        )}
                        <td width="70px">
                          {this.state.routine.length !== 1 && (
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              className="removeMcqOption mr-2"
                              onClick={(e) => this.removeRoutineField(idx)}
                            ></FontAwesomeIcon>
                          )}
                          {this.state.routine.length - 1 === idx && (
                            <FontAwesomeIcon
                              type="submit"
                              icon={faPlusCircle}
                              className="addMcqOption"
                              onClick={this.addRoutineField}
                              style={{ marginLeft: "1px" }}
                            ></FontAwesomeIcon>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : null
              ) : (
                <>
                  {Object.keys(
                    this.state.updatedRoutine.length === 0
                      ? this.props.routine1
                      : this.state.updatedRoutine
                  ).map((el, id) => {
                    const mapper =
                      this.state.updatedRoutine.length === 0
                        ? this.props.routine1
                        : this.state.updatedRoutine;
                    return (
                      <>
                        {mapper[el].map((r, ridx) => {
                          return (
                            ridx === 0 && (
                              <tr>
                                <td>
                                  <Input
                                    type="time"
                                    style={{ marginTop: "4px" }}
                                    className="form-control"
                                    placeholder="Start Time"
                                    name="startDateTime"
                                    value={r.startDateTime.split(" ")[1]}
                                    onChange={(e) =>
                                      this.handleUpdatedStartTimeSlot(e, el, id)
                                    }
                                  />
                                  <br></br>
                                  <Input
                                    type="time"
                                    className="form-control"
                                    style={{ marginTop: "4px" }}
                                    name="endDateTime"
                                    value={r.endDateTime.split(" ")[1]}
                                    placeholder="End Time"
                                    onChange={(e) =>
                                      this.handleUpdatedEndTimeSlot(e, el, id)
                                    }
                                  />

                                  <Input
                                    style={{
                                      margin: "auto",
                                      paddingLeft: "5px",
                                    }}
                                    type="checkbox"
                                    name="isBreak"
                                    className="classRoutineCheckbox"
                                    checked={r.isBreak}
                                    onChange={(e) =>
                                      this.handleUpdatedCheckbox(e, el, id)
                                    }
                                  />
                                  <label
                                    htmlFor={`breakCheckbox-${id}`}
                                    className="classRoutineLabel"
                                  >
                                    Add Break
                                  </label>
                                </td>

                                {(r?.isBreak === null ||
                                  r?.isBreak === false ||
                                  r?.isBreak === undefined) &&
                                (r?.breakType === null ||
                                  r?.breakType === undefined) ? (
                                  mapper[el].map((rout, idx) => {
                                    return this.state.weekDays.map((wD, id) => {
                                      return wD.id === rout.weekdayId ? (
                                        <td key={idx} width="200px">
                                          <Select
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            components={animatedComponents}
                                            options={this.state.subjectOption}
                                            value={
                                              rout.selectedSubjectValue.length >
                                              0
                                                ? rout.selectedSubjectValue
                                                : []
                                            }
                                            isMulti
                                            name="subject"
                                            placeholder="Subject"
                                            menuPlacement="auto"
                                            hideSelectedOptions={true}
                                            onChange={(e) =>
                                              this.handleUpdatedSubjectChange(
                                                e,
                                                el,
                                                idx
                                              )
                                            }
                                          ></Select>

                                          {this.renderUpdateAliasField(
                                            rout,
                                            el,
                                            idx
                                          )}
                                          <Select
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            components={animatedComponents}
                                            isMulti
                                            name="teacher"
                                            placeholder="Teachers"
                                            value={
                                              rout.selectedPeopleValue.length >
                                              0
                                                ? rout.selectedPeopleValue
                                                : []
                                            }
                                            options={this.state.teacherOption}
                                            menuPlacement="auto"
                                            hideSelectedOptions={true}
                                            onChange={(e) =>
                                              this.handleUpdatedTeacherChange(
                                                e,
                                                el,
                                                idx
                                              )
                                            }
                                            className="mt-1"
                                          ></Select>
                                        </td>
                                      ) : null;
                                    });
                                  })
                                ) : (
                                  <td colSpan={this.state.weekDays.length}>
                                    <Input
                                      type="input"
                                      name="breakType"
                                      placeholder="Break Type"
                                      value={r.breakType}
                                      style={{
                                        display: r?.isBreak ? "block" : "none",
                                        width: "98%",
                                      }}
                                      onChange={(e) =>
                                        this.handleUpdatedBreak(e, el, id)
                                      }
                                    />
                                  </td>
                                )}
                                <td width="70px">
                                  {id !== 0 && (
                                    <FontAwesomeIcon
                                      icon={faMinusCircle}
                                      className="removeMcqOption mr-2"
                                      onClick={() =>
                                        this.removeUpdatedRoutineField(
                                          r,
                                          ridx,
                                          id
                                        )
                                      }
                                    ></FontAwesomeIcon>
                                  )}
                                  {Object.keys(this.state.updatedRoutine)
                                    .length -
                                    1 ===
                                    id && this.state.routine.length === 0 ? (
                                    <FontAwesomeIcon
                                      type="submit"
                                      icon={faPlusCircle}
                                      className="addMcqOption"
                                      onClick={this.addUpdatedRoutineField}
                                      style={{ marginLeft: "1px" }}
                                    ></FontAwesomeIcon>
                                  ) : null}
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </>
                    );
                  })}
                  {this.state.routine.length > 0
                    ? this.state.routine.map((rout, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <input
                                type="time"
                                placeholder="Start Time"
                                className="form-control"
                                name="startDateTime"
                                value={rout.timeSlot[0].startDateTime}
                                onChange={(e) =>
                                  this.handleStartTimeSlotChange(e, idx)
                                }
                                style={{ marginTop: "4px" }}
                              />
                              <br></br>
                              <input
                                type="time"
                                className="form-control"
                                name="endDateTime"
                                placeholder="End Time"
                                onChange={(e) =>
                                  this.handleEndTimeSlotChange(e, idx)
                                }
                                value={rout.timeSlot[0].endDateTime}
                                style={{ marginTop: "4px" }}
                              />
                              <br></br>
                              <Input
                                id={`breakCheckbox-${idx}`}
                                style={{ margin: "auto", paddingLeft: "5px" }}
                                type="checkbox"
                                name="isBreak"
                                className="classRoutineCheckbox"
                                checked={rout?.isBreak}
                                onChange={(e) => this.handleCheckbox(e, idx)}
                              />
                              <label
                                htmlFor={`breakCheckbox-${idx}`}
                                className="classRoutineLabel"
                              >
                                Add Break
                              </label>
                            </td>
                            {rout?.isBreak ? (
                              <td colSpan={this.state.weekDays.length}>
                                <Input
                                  type="input"
                                  name="breakType"
                                  placeholder="Break Type"
                                  value={rout.breakType}
                                  style={{
                                    display: rout?.isBreak ? "block" : "none",
                                    width: "98%",
                                  }}
                                  onChange={(e) =>
                                    this.handleBreakChange(e, idx)
                                  }
                                />
                              </td>
                            ) : (
                              this.state.weekDays.map((col, id) => {
                                return (
                                  <td key={id} width="100px">
                                    <Select
                                      value={
                                        rout?.weekDays.find(
                                          (wD) => col.id === wD.weekdayId
                                        )?.selectedOptionSubject || null
                                      }
                                      closeMenuOnSelect={false}
                                      isClearable={true}
                                      components={animatedComponents}
                                      options={this.state.subjectOption}
                                      isMulti
                                      name="subject"
                                      placeholder="Subject"
                                      onChange={(e) =>
                                        this.handleSubjectChange(e, col.id, idx)
                                      }
                                      menuPlacement="auto"
                                      hideSelectedOptions={true}
                                      required={true}
                                    ></Select>
                                    {this.renderAliasField(
                                      rout,
                                      col.id,
                                      idx,
                                      id
                                    )}

                                    <Select
                                      required={true}
                                      value={
                                        rout?.weekDays.find(
                                          (wD) => col.id === wD.weekdayId
                                        )?.selectedOptionTeacher || null
                                      }
                                      closeMenuOnSelect={false}
                                      isClearable={true}
                                      components={animatedComponents}
                                      isMulti
                                      name="teacher"
                                      placeholder="Teachers"
                                      onChange={(e) =>
                                        this.handleTeacherChange(e, col.id, idx)
                                      }
                                      options={this.state.teacherOption}
                                      menuPlacement="auto"
                                      hideSelectedOptions={true}
                                      className="mt-1"
                                    ></Select>
                                  </td>
                                );
                              })
                            )}
                            <td width="70px">
                              {this.state.routine.length !== 1 && (
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  className="removeMcqOption mr-2"
                                  onClick={(e) => this.removeRoutineField(idx)}
                                ></FontAwesomeIcon>
                              )}
                              {this.state.routine.length - 1 === idx && (
                                <FontAwesomeIcon
                                  type="submit"
                                  icon={faPlusCircle}
                                  className="addMcqOption"
                                  onClick={this.addRoutineField}
                                  style={{ marginLeft: "1px" }}
                                ></FontAwesomeIcon>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col text-right">
            {this.props.condition === "create" ? (
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.handleAddRoutine}
              >
                Save Routine
              </button>
            ) : (
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.handleUpdateRoutine}
              >
                Update Routine
              </button>
            )}
          </div>
        </div>

        <ModalWindow
          modal={this.state?.aliasModal}
          toggleModal={this.closeAliasModal}
          modalHeader={" Subject Alias Name"}
          size="md"
          modalBody={
            <RoutineAliasModal
              selectedAliasNameData={this.state.selectedAliasNameData}
              handleAliasDataChange={this.handleAliasDataChange}
              handleAliasSave={this.handleAliasSave}
              handleUpdatedAliasName={this.handleUpdatedAliasName}
              handleUpdateAliasSave={this.handleUpdateAliasSave}
              edit={this.state.edit}
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}
export default ClassRoutineFormat;
