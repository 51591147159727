import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { compareByName, displayErrorMessage } from "../../utils/Utils";
import DragDropFile from "../../UI/DragDropFile";

class SchoolLMS extends Component {
  state = {
    title: "",
    description: "",
    resource: null,
    yearLevels: [],
    yearLevelId: "",
    subjects: [],
    subjectList: [],
    subjectId: "",
    links: [{ name: "" }],
  };

  resetState = () => {
    this.setState({
      title: "",
      description: "",
      resource: "",
      yearLevelId: "",
      subjectList: [],
      subjectId: "",

      links: [{ name: "" }],
    });
  };

  addLinkRow = () => {
    let links = [...this.state.links];
    links.push({
      name: "",
    });
    this.setState({ links });
  };

  removeLinkRow = (idx) => {
    let links = [...this.state.links];

    links.splice(idx, 1);
    this.setState({ links });
  };

  componentDidMount() {
    this.getYearLevels();
  }

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  getSubjectList = (yearLevelId) => {
    let param = {
      yearLevelId: yearLevelId,
    };
    axiosPost(
      URL.selectDistinctSubjectsByYearLevelCoreForAcademic,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState({ subjectList: response.data.data });
        }
      }
    );
  };

  handleChange = (e, idx) => {
    let { name, value, files } = e.target;

    this.setState({ [name]: files ? files : value });
    if (name === "yearLevelId") {
      this.setState({
        subjectId: "",
        subjectList: [],
      });
      this.getSubjectList(value);
    }
  };

  handleSubmit = () => {
    const {
      yearLevels,
      subjectList,
      title,
      description,
      yearLevelId,
      subjectId,
      resource,
    } = this.state;

    if (this.state.title === "") {
      swal("Error", "Title cannot be empty");
      return false;
    }

    if (this.state.yearLevelId === "") {
      swal("Error", "Please select year level");
      return false;
    }

    if (this.state.subjectId === "") {
      swal("Error", "Please select subject");
      return false;
    }

    if (this.state.resource === null) {
      swal("Error", "Please add resource");
      return false;
    }

    let formData = new FormData();

    let newLinks = [];
    let linkCount = 0;
    newLinks = this.state.links.map((lnk) => {
      if (lnk.name !== "") {
        linkCount = linkCount + 1;
        return lnk.name;
      }
    });

    let jsonData = {
      title: title,
      description: description,
      subjectId: subjectId,
      yearlevelId: yearLevelId,
      links: linkCount > 0 ? newLinks : [],
      schoolLmsTypeId:
        this.props.lms === "curriculum"
          ? "1"
          : this.props.lms === "textbooks"
          ? "2"
          : this.props.lms === "question-bank"
          ? "3"
          : this.props.lms === "av-resource"
          ? "4"
          : this.props.lms === "sign-language"
          ? "5"
          : "",
    };

    if (resource !== null) {
      for (const key of Object.keys(resource)) {
        formData.append("contents", resource[key]);
      }
    }

    formData.append("jsonData", JSON.stringify(jsonData));

    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.insertSchoolLMS,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", response.data.message);
              this.resetState();
              this.setState({
                spinner: false,
              });
              document.getElementById("resourceFile").value = null;
            }
          },
          (err) => {
            displayErrorMessage(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleLinkChange = (e, idx) => {
    let links = [...this.state.links];

    links[idx].name = e.target.value;

    this.setState({
      links: links,
    });
  };

  render() {
    const {
      yearLevels,
      subjectList,
      title,
      description,
      yearLevelId,
      subjectId,
    } = this.state;
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "85.5vh" }}
        >
          <div className="tt-group-header">
            School Learning Management System
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <strong>
                Title: <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-5">
              <input
                type="text"
                name="title"
                className="form-control"
                onChange={this.handleChange}
                value={title}
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <strong>
                Year Level:{" "}
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-5">
              <select
                name="yearLevelId"
                onChange={this.handleChange}
                className="form-control"
                value={yearLevelId}
              >
                <option value="" disabled selected>
                  Select year level
                </option>
                {yearLevels
                // .sort(compareByName)
                .map((y) => (
                  <option value={y.id}>Class {y.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <strong>Subject: </strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="col-md-5">
              <select
                name="subjectId"
                onChange={this.handleChange}
                className="form-control"
                value={subjectId}
              >
                <option value="" disabled selected>
                  Select subject
                </option>
                {subjectList.map((s) => (
                  <option value={s.id}>{s.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <strong>Links: </strong>
            </div>
            <div className="col-md-5">
              {this.state.links.map((lnk, idx) => (
                <div className="row mb-2">
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      onChange={(e) => this.handleLinkChange(e, idx)}
                      className="form-control"
                      value={lnk.name}
                    />
                  </div>
                  <div className="col-md-2">
                    {idx > 0 ? (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="removeMcqOption mr-2"
                        onClick={() => this.removeLinkRow(idx)}
                      />
                    ) : null}
                    {idx === this.state.links.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="addMcqOption"
                        onClick={() => this.addLinkRow()}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <strong>
                Resource:{" "}
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-5">
              <input
                id="resourceFile"
                type="file"
                name="resource"
                className="form-control"
                onChange={this.handleChange}
                multiple
                style={{ padding: "2px" }}
                accept="application/pdf, image/*"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <strong>Description: </strong>
            </div>
            <div className="col-md-5">
              <textarea
                name="description"
                cols="5"
                rows="5"
                className="form-control"
                onChange={this.handleChange}
                value={description}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-right">
              <button
                className="tt-button tt-button-primary float-right mt-2"
                onClick={this.handleSubmit}
              >
                <div>Save</div>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SchoolLMS;
